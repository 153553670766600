
export default {

    namespaced: true,

    state: {
        // Nombre d'écrans de chargement superposés
        counter: 0
    },

    getters: {
        // Faut-il afficher l'écran de chargement ? Oui s'il y en a au moins un
        isDisplayed: state => (state.counter >= 1)
    },

    mutations: {
        // Ecran de chargement : ajout / suppression
        push: state => {state.counter++},
        pop: state => {state.counter--}
    }
};
