<template>

    <div class="form-textarea-field">

        <label v-if="label && label.length" v-html="label">
        </label>

        <div class="field-wrapper">
            <textarea class="field" :rows="rows" @input="e => $emit('input', e.target.value)">{{ value }}</textarea>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'FormTextareaField',

        props: {
            label: {default: ''},
            value: {},
            rows: {default:3}
        }
    };
</script>
