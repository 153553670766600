<template>

    <div class="form-select-field">

        <label v-if="label && label.length" v-html="label">
        </label>

        <micro-popup
            :btnClass="'field has-dropdown noselect' + (hasReset ? ' has-reset has-reset-and-dropdown' : '')"
            @open="open()"
            :fullWidthDropdown="fullWidthDropdown"
            ref="selector"
            >

            <template v-slot:btn>

                <span v-html="currentOption ? currentOption.label : placeholder"></span>

                <div class="reset" v-if="hasReset && currentOption" @click.stop="reset()" title="Effacer">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18px" height="18px" viewBox="0 0 18 18" enable-background="new 0 0 18 18" xml:space="preserve">
                        <path fill="#101010" d="M13.721,5.23L12.77,4.279L9,8.049l-3.77-3.77L4.279,5.23L8.049,9l-3.77,3.77l0.951,0.951L9,9.951l3.77,3.77l0.951-0.951L9.951,9L13.721,5.23z"/>
                    </svg>
                </div>
            </template>

            <template v-slot:content="scope">

                <form-input-field
                    v-if="searchable"
                    v-model="searchQuery"
                    placeholder="Rechercher..."
                    :hasReset="true"
                    :hideResetIfEmpty="true"
                    class="theme-light dropdown-search"
                    ref="search_field"
                    @submit="selectFirstOption(scope)"
                    />

                <div class="list-of-options" :class="{overflow:searchable}">
                    <div v-for="option in filteredOptions" @click="scope.close() && $emit('input', option.value)" v-html="option.label">
                    </div>
                </div>
            </template>
        </micro-popup>
    </div>
</template>

<script>
    import {getActiveSuggestions} from '@/helpers.js';
    import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
    import MicroPopup from '@/components/Lib/Misc/MicroPopup.vue';
    export default {
        name: 'FormSelectField',
        components: {FormInputField, MicroPopup},

        data () {
            return {
                searchQuery: ''
            };
        },

        props: {
            label: {default: ''},
            placeholder: {default: ''},
            options: {default: () => []},
            searchable: {default: false},
            hasReset: {default: false},
            resetValue: {default: ''},
            fullWidthDropdown: {default: false},
            value: {}
        },

        computed: {
            filteredOptions () {
                if ( !this.searchable || this.searchQuery == '') {
                    return [ ...this.options ];
                } else {
                    return getActiveSuggestions(this.options, this.searchQuery, this.options.length, 1);
                }
            },

            currentOption () {
                return this.options.find( option => option.value == this.value );
            }
        },

        methods:{
            // Ouvre le dropdown. Utile depuis l'extérieur.
            openSelector () {
                this.$refs.selector.open();
            },

            // Déclenché quand le dropdown s'ouvre
            open () {
                setTimeout( () => {
                    if (this.searchable) {
                        this.$refs.search_field.focus();
                    }
                }, 250);
            },

            selectFirstOption (scope) {
                if (this.filteredOptions.length > 0) {
                    scope.close();
                    this.$emit('input', this.filteredOptions[0].value);
                }
            },

            // Effacer la sélection
            reset () {
                this.$emit('input', this.resetValue);
            }
        }
    };
</script>
