<template>
    <div class="customer-details list-of-details">

        <tip-icon :active="contactsActive" :counter="customer.contacts.length"
                  @click="openContacts(customer)"
                  @clickIfNotActive="addContact(customer)"
                  >
            <template v-slot:icon>
                <img src="@/assets/img/menu-icon-contacts.svg" alt="" width="18" height="18" />
            </template>
            <template v-slot:tip>
                <div v-for="contact in customer.contacts">
                    <div class="no-wrap" v-html="getFullPrettyNameHtml(contact)">
                    </div>
                </div>
                <div class="info">
                    clic : OUVRIR contacts
                </div>
            </template>
            <template v-slot:tipIfNotActive>
                <div class="info">
                    clic : AJOUTER contact
                </div>
            </template>
        </tip-icon>

        <tip-icon :active="etabsActive" :counter="customer.etabs.length"
                  @click="openEtabs(customer)"
                  @clickIfNotActive="addEtab(customer)"
                  >
            <template v-slot:icon>
                <img src="@/assets/img/menu-icon-etabs.svg" alt="" width="18" height="18" />
            </template>
            <template v-slot:tip>
                <div v-for="etab in customer.etabs" class="no-wrap">
                    {{ etab.name }}
                    <span class="light" v-if="etab.typologies">
                        {{ etab.typologies.join(' + ') }}
                    </span>
                </div>
                <div class="info">
                    clic : OUVRIR points de livraison
                </div>
            </template>
            <template v-slot:tipIfNotActive>
                <div class="info">
                    clic : AJOUTER point de livraison
                </div>
            </template>
        </tip-icon>

        <tip-icon :active="eventsActive" :counter="customer.events.length"
                  @click="openEvents(customer)"
                  @clickIfNotActive="addEvent(customer)"
                  >
            <template v-slot:icon>
                <img src="@/assets/img/menu-icon-events.svg" alt="" width="18" height="18" />
            </template>
            <template v-slot:tip>
                <div v-for="event in customer.events">
                    <span class="no-wrap">
                        {{ event.type }}
                    </span>
                    <br/>
                    <span class="light">
                        {{ event.date | formatDate('FULL') }}
                    </span>
                </div>
                <div class="info">
                    clic : OUVRIR évènements
                </div>
            </template>
            <template v-slot:tipIfNotActive>
                <div class="info">
                    clic : AJOUTER évènement
                </div>
            </template>
        </tip-icon>

        <tip-icon :active="tasksActive" :counter="customer.tasks.length"
                  @click="openTasks(customer)"
                  @clickIfNotActive="addTask(customer)"
                  >
            <template v-slot:icon>
                <img src="@/assets/img/menu-icon-tasks.svg" alt="" width="18" height="18" />
            </template>
            <template v-slot:tip>
                <div v-for="task in customer.tasks">
                    <span class="no-wrap">
                        {{ task.type }}
                    </span>
                    <br/>
                    <span class="light">
                        {{ task.date | formatDate('FULL') }}
                    </span>
                </div>
                <div class="info">
                    clic : OUVRIR tâches
                </div>
            </template>
            <template v-slot:tipIfNotActive>
                <div class="info">
                    clic : AJOUTER tâche
                </div>
            </template>
        </tip-icon>

        <tip-icon :active="false" :counter="0"
                  @click=""
                  @clickIfNotActive=""
                  >
            <template v-slot:icon>
                <img src="@/assets/img/menu-icon-mails.svg" alt="" width="18" height="18" />
            </template>
        </tip-icon>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import {formatDate} from '@/helpers.js';
import {
    getFullPrettyNameHtml, customerToContactFields, customerToContactPageFilters, customerToEtabPageFilters, customerToEtabFields,
    customerToEventPageFilters, customerToEventFields, customerToTaskPageFilters, customerToTaskFields
} from '@/helpersForCrm.js';
import TipIcon from '@/components/Global/TipIcon.vue';

export default {
    name: 'CustomerDetails',
    components: {TipIcon},
    filters: {formatDate},

    props: ['customer'],

    computed: {
        ...mapState('customers', ['customers']),
        ...mapState('users', ['users']),
        ...mapState('settings', ['typologiesOptions']),

        contactsActive () {
            return this.customer.contacts.length > 0;
        },
        etabsActive () {
            return this.customer.etabs.length > 0;
        },
        eventsActive () {
            return this.customer.events.length > 0;
        },
        tasksActive () {
            return this.customer.tasks.length > 0;
        },
        mailsActive () {
            return false;
        }
    },

    methods: {
        ...mapActions('pages', ['addPageContacts', 'addPageEtabs', 'addPageEvents', 'addPageTasks']),

        getFullPrettyNameHtml (contact) {
            return getFullPrettyNameHtml(contact);
        },

        openContacts (customer) {
            this.addPageContacts({
                filters: customerToContactPageFilters(customer)
            });
        },

        openEtabs (customer) {
            this.addPageEtabs({
                filters: customerToEtabPageFilters(customer)
            });
        },

        openEvents (customer) {
            this.addPageEvents({
                filters: customerToEventPageFilters(customer)
            });
        },

        openTasks (customer) {
            const usersIds = this.users.map(u => u.id);
            this.addPageTasks({
                filters: customerToTaskPageFilters(customer, usersIds)
            });
        },

        addContact (customer) {
            const presetFields = customerToContactFields(customer, this.customers);
            this.$parent.$refs.contact_popup.addOrEditContact(-1, presetFields);
        },

        addEtab(customer) {
            const presetFields = customerToEtabFields(customer, this.customers, this.typologiesOptions.map(to => to.value));
            this.$parent.$refs.etab_popup.addOrEditEtab(-1, presetFields);
        },

        addEvent(customer) {
            const presetFields = customerToEventFields(customer, this.customers);
            this.$parent.$refs.event_popup.addOrEditEvent(-1, presetFields);
        },

        addTask(customer) {
            const presetFields = customerToTaskFields(customer, this.customers);
            this.$parent.$refs.task_popup.addOrEditTask(-1, presetFields);
        }
    }
};
</script>
