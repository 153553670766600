
/*
 * Liste intégrale des contacts
 * utiliser ce store pour lire / modifier / supprimer tout contact
 */

import {callAPI} from '@/helpers.js';

export default {

    namespaced: true,

    state: {
        contacts: [],
        highlightedId:-1,

        // Etiquettes liées aux contacts
        tags: [],

        // Fonctions distinctes
        jobs: []
    },

    getters: {

        contactsByCustomerId (state) {
            const contactsByCustomerId = {};
            state.contacts.forEach( contact => {
                if (contactsByCustomerId[contact.customerId] === undefined) {
                    contactsByCustomerId[contact.customerId] = [];
                }
                contactsByCustomerId[contact.customerId].push(contact);
            });
            return contactsByCustomerId;
        },

        tagsById (state) {
            const tagsById = {};
            state.tags.forEach( t => {
                tagsById[ t.id ] = t.tag;
            });
            return tagsById;
        },

        tagsOptions (state) {
            return state.tags.map( tag => {
                return {
                    value: tag.id,
                    label: tag.tag
                };
            });
        },

        jobsOptions (state) {
            return state.jobs.map( job => {
                return {
                    value: job,
                    label: job
                };
            });
        }
    },

    mutations: {
        setContacts (state, contacts) {
            state.contacts = [ ...contacts ];
        },

        // Met à jour ou ajoute un contact au store
        // A appeler après toute mise à jour sur le serveur
        setContact (state, contact) {
            const index = state.contacts.findIndex(e => e.id == contact.id);
            if (index >= 0) {
                state.contacts.splice(index, 1, { ...contact });
            } else {
                state.contacts.push({ ...contact });
            }
        },

        // Retire un contact du store
        unsetContact (state, contactId) {
            const index = state.contacts.findIndex(e => e.id == contactId);
            if (index >= 0) {
                state.contacts.splice(index, 1);
            }
        },

        // Met en surbrillance une ligne
        setHighlightedId (state, id) {
            state.highlightedId = id;
        },
        // Désactive la surbrillance
        resetHighlightedId (state) {
            state.highlightedId = -1;
        },

        setJobs (state, jobs) {
            state.jobs = [ ...jobs ];
        },

        setTags (state, tags) {
            state.tags = [ ...tags ];
        },

        // Retire un tag de l'ensemble des contacts
        removeTagToAllContacts (state, tagId) {
            for(let contactIndex in state.contacts) {
                const contact = { ...state.contacts[contactIndex] };
                const index = contact.tagsIds.findIndex(id => id == tagId);
                if (index >= 0) {
                    contact.tagsIds.splice(index, 1);
                    state.contacts[contactIndex] = { ...contact };
                }
            }
        }
    },

    actions: {
        // Hydration complète des contacts, à appeler au chargement de l'app
        loadFromDatabase (context) {
            callAPI(context, 'Contacts/getAll', {}, {noOverlay: true})
            .then( response => {
                if (response.status === true) {
                    context.commit('setContacts', response.contacts);
                }
            });
        },

        // Retourne un contact hydraté ou un contact vierge si n'existe pas
        get (context, contactId) {
            const index = context.state.contacts.findIndex(e => e.id == contactId);
            let contact;
            if (index >= 0) {
                contact = context.state.contacts[index];
            } else {
                contact = {
                    id: -1,
                    customerId: -1,
                    etabId: 0,
                    tagsIds: [],
                    displayInTrokool: 0,
                    civility: '',
                    lastname: '',
                    firstname: '',
                    title: '',
                    company: '',
                    job:'',
                    email: '',
                    tel1: '',
                    tel2: '',
                    tel3: '',
                    address1: '',
                    address2: '',
                    address3: '',
                    city: '',
                    comments: ''
                };
            }
            return { ...contact };
        },

        // Création ou mise à jour d'un contact
        // Sauvegarde en DB puis mise à jour du store et mise à jour de la liste des métiers
        save (context, contact) {
            return new Promise( (resolve, reject) => {
                callAPI(context, 'Contacts/save', {contact: { ...contact }}, {}).then( response => {
                    if (response.status === true) {

                        context.commit('setContact', response.contact);
                        context.dispatch('loadJobsFromDatabase');

                        // Mise en surbrillance du contact et du client lié
                        context.commit('setHighlightedId', response.contact.id);
                        context.commit('customers/setHighlightedId', response.contact.customerId, {root:true});
                        setTimeout(() => {
                            context.commit('resetHighlightedId');
                            context.commit('customers/resetHighlightedId', null, {root:true});
                        }, 500);

                        resolve();
                    } else {
                        reject(response.errors);
                    }
                });
            });
        },


        // Suppression d'un contact sur le serveur puis dans le store
        delete (context, contactId) {
            return new Promise( (resolve, reject) => {
                callAPI(context, 'Contacts/delete', {contactId}, {})
                .then( response => {
                    if (response.status === true) {
                        context.commit('unsetContact', contactId);
                        context.dispatch('loadJobsFromDatabase');
                        resolve();
                    }
                });
            });
        },


        // Hydratation ou mise à jour de la liste des métiers
        loadJobsFromDatabase (context) {
            callAPI(context, 'Main/getJobsSuggestions', {}, {noOverlay: true})
            .then( response => {
                if (response.status === true) {
                    context.commit('setJobs', response.suggestions);
                    context.commit('pages/fixContactsPagesFilters', response.suggestions, {root:true});
                }
            });
        },


        // Hydratation ou mise à jour de la liste des étiquettes
        loadTagsFromDatabase (context) {
            callAPI(context, 'Contacts/getTags', {}, {noOverlay: true})
            .then( response => {
                if (response.status === true) {
                    context.commit('setTags', response.tags);
                }
            });
        },

        // Création d'un tag en DB
        addTag (context, tag) {
            return new Promise( (resolve, reject) => {
                callAPI(context, 'Contacts/addTag', {tag})
                .then( response => {
                    if (response.status === true) {
                        context.dispatch('loadTagsFromDatabase');
                        resolve();
                    }
                });
            });
        },

        // Renomme un tag en DB
        renameTag (context, updatedTag) {
            callAPI(context, 'Contacts/renameTag', {tag: updatedTag})
            .then( response => {
                if (response.status === true) {
                    context.dispatch('loadTagsFromDatabase');
                }
            });
        },

        // Supprime un tag en DB + le retire de tous les contacts
        deleteTag (context, tagId) {
            callAPI(context, 'Contacts/deleteTag', {tagId})
            .then( response => {
                if (response.status === true) {

                    // Mise à jour de la liste des tags
                    context.dispatch('loadTagsFromDatabase');

                    // Mise à jour de la liste des contacts
                    context.commit('removeTagToAllContacts', tagId);
                }
            });
        }
    }
};
