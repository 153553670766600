<template>
    <header id="the-header">

        <a class="logo d-flex justify-content-center align-items-center" @click.prevent="reloadPage()">
            <img class="desktop" src="@/assets/img/leztroy-crm-logo-mini.png" alt="" width="200" height="55" />
            <img class="mobile" src="@/assets/img/leztroy-crm-logo-mobile.png" alt="" width="46" height="46" />
        </a>

        <div class="d-flex justify-content-between">
            <main-menu />
            <session-info />
        </div>
    </header>
</template>

<script>
    import MainMenu from '@/components/Header/MainMenu.vue';
    import SessionInfo from '@/components/Header/SessionInfo.vue';
    export default {
        name: "TheHeader",
        components: {MainMenu, SessionInfo},
        methods: {
            reloadPage () {
                location.reload();
            }
        }
    };
</script>
