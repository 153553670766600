
export default {

    namespaced: true,

    state: {
        popups: []
    },

    mutations: {

        // Ajout d'une alert-box
        // Possibilité de passer le message (string) comme args
        alert (state, args = {}) {
            if (typeof args === 'string') {
                args = {message: args};
            }
            args = Object.assign({
                message : "...",
                width: 450,
                callback: () => {}
            }, args);

            state.popups.push({ ...args });
        },

        // Supression d'une alert-box
        close (state, index) {
            state.popups.splice(index, 1);
        }
    }
};
