<template>
    <div class="etab-contacts list-of-details" @click.stop="">

        <tip-icon :active="contactsActive" :counter="contacts.length"
                  @click="openContacts"
                  >
            <template v-slot:icon>
                <img src="@/assets/img/menu-icon-contacts.svg" alt="" width="18" height="18" />
            </template>
            <template v-slot:tip>
                <div v-for="contact in contacts" class="no-wrap">
                    <div class="no-wrap" v-html="getFullPrettyNameHtml(contact)">
                    </div>
                </div>
                <div class="info">
                    clic : OUVRIR contacts
                </div>
            </template>
        </tip-icon>

    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {getFullPrettyNameHtml} from '@/helpersForCrm.js';
import TipIcon from '@/components/Global/TipIcon.vue';
export default {
    name: 'EtabContacts',
    components: {TipIcon},

    props: ['contacts', 'etab'],

    computed: {
        ...mapGetters('customers', ['customerById']),

        contactsActive () {
            return this.contacts.length > 0;
        }
    },

    methods: {

        ...mapActions('pages', ['addPageContacts']),

        getFullPrettyNameHtml (contact) {
            return getFullPrettyNameHtml(contact);
        },

        openContacts () {

            if (this.contacts.length === 0) {
                return;
            }

            const customer = this.customerById[ this.etab.customerId ];
            const filters = {
                statuses: [customer.status],
                customer: customer.name,
                etab: this.etab.name
            };

            this.addPageContacts({ filters });
        }
    }
};
</script>
