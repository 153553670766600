<template>
    <div>
        <popup v-for="(popup, index) in promptPopups"
			   class="prompt-popup"
			   :width="popup.width"
               :buttonsOnLeft="[{label:'Annuler', event:'close', classes:'icon cancel'}]"
               :buttonsOnRight="[{label:'Enregistrer', event:'save', classes:'green icon ok'}]"
               @close="closePopupMutation(index)"
               @save="returnNewValue(index)"
			   >

            <form-input-field
                :value="popup.value"
                @input="newValue => setFieldValueMutation({index, newValue})"
                @submit="returnNewValue(index)"
                :type="popup.type"
                :label="popup.question"
                />

		</popup>
    </div>
</template>


<script>
    import jQuery from "jquery";
    import {mapState, mapMutations} from 'vuex';
    import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
    import Popup from '@/components/Lib/Misc/Popup.vue';
    export default {
        name: 'PromptPopups',
        components: {Popup, FormInputField},

        data () {
            return {
                // Permet de détecter l'ouverture d'une popup, afin de mettre le focus sur le champ
                previousPromptsCounter: 0
            };
        },

        computed: {
            ...mapState('prompt', {promptPopups: 'popups'})
        },

        methods: {
            ...mapMutations('prompt', {setFieldValueMutation: 'setValue', closePopupMutation: 'close'}),

            // Déclenche le callback avec la valeur saisie, et ferme la popup
            returnNewValue (index) {
                const popup = this.promptPopups[index];
                popup.callback(popup.value);
                this.closePopupMutation(index);
            }
        },

        watch: {
            // Permet de détecter l'ouverture d'une popup, afin de mettre le focus sur le champ
            promptPopups () {
                const newCounter = this.promptPopups.length;

                // S'il y a plus de popups qu'avant : focus sur le champ input de la dernière popup ajoutée
                if (newCounter > this.previousPromptsCounter) {
                    setTimeout(() => {
                        const $field = jQuery('.prompt-popup:last .form-input-field input.field');
                        $field.focus();
                    }, 200);
                }
                this.previousPromptsCounter = newCounter;
            }
        }
    };
</script>
