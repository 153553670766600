
/*
 * Liste intégrale des points de livraison
 * utiliser ce store pour lire / modifier / supprimer tout point de livraison
 */

import {callAPI, strcmp} from '@/helpers.js';

export default {

    namespaced: true,

    state: {
        etabs: [],
        highlightedId:-1
    },

    getters: {
        etabById (state) {
            const etabById = {};
            state.etabs.forEach( etab => {
                etabById[ etab.id ] = etab;
            });
            return etabById;
        },

        etabsByCustomerId (state) {
            const etabsByCustomerId = {};
            state.etabs.forEach( etab => {
                if (etabsByCustomerId[etab.customerId] === undefined) {
                    etabsByCustomerId[etab.customerId] = [];
                }
                etabsByCustomerId[etab.customerId].push(etab);
            });
            return etabsByCustomerId;
        }
    },

    mutations: {
        setEtabs (state, etabs) {
            state.etabs = [ ...etabs ];
        },

        // Met à jour ou ajoute un étab au store
        // A appeler après toute mise à jour sur le serveur
        setEtab (state, etab) {
            const index = state.etabs.findIndex(e => e.id == etab.id);
            if (index >= 0) {
                state.etabs.splice(index, 1, { ...etab });
            } else {
                state.etabs.push({ ...etab });
            }
        },

        // Retire un étab du store
        unsetEtab (state, etabId) {
            const index = state.etabs.findIndex(e => e.id == etabId);
            if (index >= 0) {
                state.etabs.splice(index, 1);
            }
        },

        // Met en surbrillance une ligne
        setHighlightedId (state, id) {
            state.highlightedId = id;
        },
        // Désactive la surbrillance
        resetHighlightedId (state) {
            state.highlightedId = -1;
        }
    },

    actions: {
        // Hydration complète, à appeler au chargement de l'app
        loadFromDatabase (context) {
            callAPI(context, 'Etabs/getAll', {}, {noOverlay: true})
            .then( response => {
                if (response.status === true) {
                    context.commit('setEtabs', response.etabs);
                }
            });
        },

        // Retourne un étab hydraté ou un étab vierge si n'existe pas
        get (context, etabId) {
            const index = context.state.etabs.findIndex(e => e.id == etabId);
            let etab;
            if (index >= 0) {
                etab = context.state.etabs[index];
            } else {
                etab = {
                    id: -1,
                    customerId: -1,
                    name: '',
                    trokoolEtabId: -1,
                    address: '',
                    city: '',
                    typologies: []
                };
            }
            return { ...etab };
        },

        // Création ou mise à jour d'un étab
        // Sauvegarde en DB puis mise à jour du store
        save (context, etab) {
            return new Promise( (resolve, reject) => {
                callAPI(context, 'Etabs/save', {etab: { ...etab }}, {}).then( response => {
                    if (response.status === true) {
                        context.commit('setEtab', response.etab);

                        // Mise en surbrillance pendant quelques secondes
                        context.commit('setHighlightedId', response.etab.id);
                        context.commit('customers/setHighlightedId', response.etab.customerId, {root:true});
                        setTimeout(() => {
                            context.commit('resetHighlightedId');
                            context.commit('customers/resetHighlightedId', null, {root:true});
                        }, 500);

                        resolve();
                    } else {
                        reject(response.errors);
                    }
                });
            });
        },


        // Suppression d'un étab sur le serveur puis dans le store
        delete (context, etabId) {
            return new Promise( (resolve, reject) => {
                callAPI(context, 'Etabs/delete', {etabId}, {})
                .then( response => {
                    if (response.status === true) {
                        context.commit('unsetEtab', etabId);
                        resolve();
                    }
                });
            });
        }
    }
};
