import {filterItems} from '@/helpers.js';


// Décode un téléphone au format "0033633543549§Commentaire"
// En sortie : {stdTel, comment, prettyTel, prettyType}
export const decodeTel = function (internalTel) {

    const [stdTel, comment = ''] = internalTel ? internalTel.split('§') : [''];
    const fourDigits = stdTel.substr(0, 4);
    const fiveDigits = stdTel.substr(0, 5);
    const sixDigits = stdTel.substr(0, 6);
    let prettyTel = stdTel;
    let prettyType = 'ERREUR';

    // Détection du pays, du type (mobile/fixe) et de la base à afficher
    if (stdTel.length === 13) {
        if ( ['00336', '00337'].includes(fiveDigits) ) {
            prettyType = 'MOBILE';
            prettyTel = '0' + stdTel.substr(4);
        }
        else if (fourDigits == '0033') {
            prettyType = 'FIXE';
            prettyTel = '0' + stdTel.substr(4);
        }
        else if ( ['004178', '004179'].includes(sixDigits) ) {
            prettyType = 'MOB. CH';
        }
        else if (fourDigits == '0041') {
            prettyType = 'FIXE CH';
        }
    }
    else if (stdTel.length === 0) {
        prettyType = '';
    }

    // Ajout d'espaces pour la lisibilité du numéro
    if (prettyTel.length === 10) {
        prettyTel = [
            prettyTel.substr(0,2), prettyTel.substr(2,2), prettyTel.substr(4,2), prettyTel.substr(6,2), prettyTel.substr(8,2)
        ].join(' ');
    }
    else if (prettyTel.length === 13) {
        prettyTel = [
            prettyTel.substr(0,4), prettyTel.substr(4,2), prettyTel.substr(6,3), prettyTel.substr(9,2), prettyTel.substr(11,2)
        ].join(' ');
    }

    return {stdTel, comment, prettyTel, prettyType};
};


// Nettoie et formatte un téléphone saisi par l'utilisateur
// En sortie : 0033633543549%commentaire
export const cleanAndEncodeTel = function (tel, comment) {
    let stdTel = tel.trim();

    // Sépare le préfixe internationnal et le numéro local
    let prefix = '0033';
    if (stdTel.substr(0,1) == '+') {
        stdTel = '00' + stdTel.substr(1);
    }
    if (stdTel.substr(0,2) == '00') {
        prefix = stdTel.substr(0,4);
        stdTel = stdTel.substr(4);
    }

    // Conserve uniquement les chiffres
    stdTel = stdTel.replace(/[^0-9]/gi, '');

    // Retire le zéro initial éventuel
    if (stdTel.substr(0,1) === '0') {
        stdTel = stdTel.substr(1);
    }

    // Si le résultat est cohérent, on assemble le tout. Sinon on conserve tel quel le téléphone initial
    if (stdTel.length === 9) {
        stdTel = prefix + stdTel;
    } else {
        stdTel = tel.trim();
    }

    return [stdTel, comment.trim()].join('§');
};


export const getPrettyNameHtml = function (contact, tag = 'span') {
    return getPrettyName(contact, true, tag);
};

// Formatte un nom complet (civilité, nom, prénom, titre) pour l'affichage
export const getPrettyName = function (contact, html = false, tag = 'span') {

    // Civilité, nom, prénom
    let name = '';
    if (contact.lastname || contact.firstname) {
        name += html ? '<' + tag + ' class="name">' : '';
        name += [contact.civility, contact.firstname, contact.lastname].filter( x => x != '' ).join(' ');
        name += html ? '</' + tag + '> ' : ' ';
    }
    // Titre pour correspondance
    if (contact.title) {
        name += html ? '<' + tag + ' class="title">' : '';
        name += contact.title;
        name += html ? '</' + tag + '>' : ' ';
    }
    // Si aucun champ renseigné, on utilise la société
    if (name == '') {
        name = contact.company;
    }
    return name;
};


// Formatte un nom complet, incluant le nom de la société et le métier
export const getFullPrettyNameHtml = function (contact) {
    let name = getPrettyNameHtml(contact);
    if (contact.company) {
        name = '<span class="company">' + contact.company + '</span>' + name;
    }
    if (contact.job) {
        name += '<span class="job">' + contact.job + '</span>';
    }
    return name;
};


// Prépare l'adresse complète HTML pour un contact
export const getFullAddressHtml = function (contact) {
    let fullAddress = '';
    if (contact.address1 != '') {
        fullAddress += '<span class="light">' + contact.address1 + '</span><br/> ';
    }
    if (contact.address2 != '') {
        fullAddress += '<span class="light">' + contact.address2 + '</span><br/> ';
    }
    if (contact.address3 != '') {
        fullAddress += '<span class="light">' + contact.address3 + '</span><br/> ';
    }
    if (contact.city != '') {
        fullAddress += '<span class="strong">' + contact.city + '</span>';
    }
    return fullAddress;
};


// Prépare les filtres à activer sur une page contact pour voir un client
export const customerToContactPageFilters = function (customer) {
    return {
        statuses: [customer.status],
        customer: customer.name
    };
};

// Prépare les champs à pré-remplir dans une popup de création d'un contact, d'après les filtres actifs d'une page contact
export const contactPageFiltersToContactFields = function (filters, customers) {
    const fields = {};

    // Si filtrage par nom de client, recherche du 1er client portant ce nom
    if (filters.customer != '') {
        const items = filterItems({
            items: customers,
            filtersValues: {name: filters.customer},
            filtersToApply: [{filterKey: 'name', itemKey: 'name'}]
        });
        if (items) {
            fields.customerId = items[0].id;
        }
    }
    return fields;
};

// Prépare les champs à pré-remplir dans une popup de création d'un contact, d'après un client ou prospect
export const customerToContactFields = function (customer, customers) {
    const contactPageFilters = customerToContactPageFilters(customer);
    return contactPageFiltersToContactFields(contactPageFilters, customers);
};

// Prépare les filtres à activer sur une page étab pour voir ceux d'un client
export const customerToEtabPageFilters = function (customer) {
    return {
        statuses: [customer.status],
        customer: customer.name
    };
};

// Prépare les filtres à activer sur une page évent pour voir ceux d'un client
export const customerToEventPageFilters = function (customer) {
    return {
        statuses: [customer.status],
        customer: customer.name
    };
};

// Prépare les filtres à activer sur une page taches pour voir celles d'un client
export const customerToTaskPageFilters = function (customer, allUsersIds = false) {
    const filters = {
        statuses: [customer.status],
        customer: customer.name,
    };
    if (allUsersIds !== false) {
        filters.users = [ ...allUsersIds ];
    }
    return filters;
};

// Prépare les champs à pré-remplir dans une popup de création d'un contact, d'après les filtres actifs d'une page contact
export const etabPageFiltersToEtabFields = function (filters, customers, typologies) {
    const fields = {};

    // Si filtrage par nom de client, recherche du 1er client portant ce nom
    if (filters.customer) {
        const items = filterItems({
            items: customers,
            filtersValues: {name: filters.customer},
            filtersToApply: [{filterKey: 'name', itemKey: 'name'}]
        });
        if (items) {
            fields.customerId = items[0].id;
        }
    }

    // Si filtrage par des typologies, on les pré-coche
    if (filters.typologies && filters.typologies.length < typologies.length) {
        fields.typologies = [ ...filters.typologies ];
    }

    return fields;
};

// Prépare les champs à pré-remplir dans une popup de création d'un event, d'après les filtres actifs d'une page event
export const eventPageFiltersToEventFields = function (filters, customers) {
    const fields = {};

    // Si filtrage par nom de client, recherche du 1er client portant ce nom
    if (filters.customer != '') {
        const items = filterItems({
            items: customers,
            filtersValues: {name: filters.customer},
            filtersToApply: [{filterKey: 'name', itemKey: 'name'}]
        });
        if (items) {
            fields.customerId = items[0].id;
        }
    }
    return fields;
};

// Prépare les champs à pré-remplir dans une popup de création d'une tache, d'après les filtres actifs d'une page tache
export const taskPageFiltersToTaskFields = function (filters, customers) {
    const fields = {};

    // Si filtrage par nom de client, recherche du 1er client portant ce nom
    if (filters.customer != '') {
        const items = filterItems({
            items: customers,
            filtersValues: {name: filters.customer},
            filtersToApply: [{filterKey: 'name', itemKey: 'name'}]
        });
        if (items) {
            fields.customerId = items[0].id;
        }
    }
    return fields;
};

// Prépare les champs à pré-remplir dans une popup de création d'un contact, d'après un client ou prospect
export const customerToEtabFields = function (customer, customers, typologies) {
    const etabPageFilters = customerToEtabPageFilters(customer);
    return etabPageFiltersToEtabFields(etabPageFilters, customers, typologies);
};

// Prépare les champs à pré-remplir dans une popup de création d'un event, d'après un client ou prospect
export const customerToEventFields = function (customer, customers) {
    const eventPageFilters = customerToEventPageFilters(customer);
    return eventPageFiltersToEventFields(eventPageFilters, customers);
};

// Prépare les champs à pré-remplir dans une popup de création d'une tache, d'après un client ou prospect
export const customerToTaskFields = function (customer, customers) {
    const taskPageFilters = customerToTaskPageFilters(customer);
    return taskPageFiltersToTaskFields(taskPageFilters, customers);
};


// Met à jour un filtre après changement de la liste des valeurs possibles pour ce filtre
export const updateFilterValues = function (currentValues, newValues) {
    let updated = false;
    const toDelete = currentValues.filter(t => !newValues.includes(t));
    if (toDelete) {
        currentValues = currentValues.filter(t => !toDelete.includes(t));
        updated = true;
    }

    const missing = newValues.filter(t => !currentValues.includes(t));
    if (missing.length === 1) {
        currentValues = [ ...currentValues, ...missing ];
        updated = true;
    }
    return updated ? currentValues : false;
};
