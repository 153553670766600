<template>
    <div class="page mails-page">

        <div class="page-toolbar">
            <div class="txt">

                Page emails (en construction)
            </div>
        </div>

        <div class="page-body">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Mails'
    };
</script>
