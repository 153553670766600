/*
 * Liste intégrale des pièces jointes
 */

 import {callAPI} from '@/helpers.js';

 export default {

     namespaced: true,

     state: {
         attachments: []
     },

     getters: {
         attachmentsById (state) {
             const attachmentsById = {};
             state.attachments.forEach( attachment => {
                 attachmentsById[ attachment.id ] = attachment;
             });
             return attachmentsById;
         }
     },

     mutations: {
         addAttachments (state, attachments) {
             state.attachments = [ ...state.attachments, ...attachments ];
         },

         setAttachments (state, attachments) {
             state.attachments = [ ...attachments ];
         },

         deleteAttachment (state, fileId) {
             const index = state.attachments.findIndex(a => a.id == fileId);
             if (index >= 0) {
                 state.attachments.splice(index, 1);
             }
         },

         renameAttachment (state, file) {
             const index = state.attachments.findIndex(a => a.id == file.id);
             if (index >= 0) {
                 state.attachments.splice(index, 1, { ...file });
             }
         }
     },

     actions: {

         // Hydration complète, à appeler au chargement de l'app
         loadFromDatabase (context) {
             callAPI(context, 'Attachments/getAll', {}, {noOverlay: true})
             .then( response => {
                 if (response.status === true) {
                     context.commit('setAttachments', response.attachments);
                 }
             });
         },


         // Upload de fichiers sur le serveur, puis mise à jour du store local
         uploadFiles (context, files) {
             return new Promise( (resolve, reject) => {

                 if (files.length === 0) {
                     reject("Aucun fichier sélectionné");
                 }

                 // Envoi sur le serveur
                 callAPI(context, 'Attachments/uploadFiles', { ...files })
                 .then( response => {
                     if (response.status && !response.error) {

                         // Si réussite, ajout au store
                         context.commit('addAttachments', response.attachments);

                         // Et retourne les ID des nouveaux
                         resolve( response.attachments.map(a => a.id) );
                     } else {
                         // Si échec
                         reject(response.error);
                     }
                 });
             });
         },


         // Suppression d'un fichier sur le serveur, puis dans le store
         deleteFile (context, fileId) {
             return new Promise( (resolve, reject) => {
                 callAPI(context, 'Attachments/deleteFile', { fileId })
                 .then( response => {
                     if (response.status === true) {
                         context.commit('deleteAttachment', fileId);
                         resolve();
                     } else {
                         reject();
                     }
                 });
             });
         },


         renameFile (context, updatedFile) {
             callAPI(context, 'Attachments/renameFile', { fileId: updatedFile.id, name: updatedFile.name })
             .then( response => {
                 if (response.status === true) {
                     context.commit('renameAttachment', response.file);
                 }
             });
         }
     }

 };
