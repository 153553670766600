import axios from 'axios';


// Date du jour yyyy-mm-dd
export const getTodayDate = function () {
    return dateToYmd(new Date());
};


// Conversion d'un objet date en string yyyy-mm-dd
export const dateToYmd = function (d) {
    return d.toISOString().split('T')[0];
};


// Formattage d'une date
export const formatDate = function (date, format) {
    const d = new Date(date);
    let output = '';
    switch(format) {
        case 'FULL':
            output = d.toLocaleDateString('fr-FR', {weekday:'short', day:'numeric', month:'short', year:'numeric'});
        break;
    }
    return output;
};


// Calcule le nombre de jours entre aujourd'hui et une date limite
export const daysBefore = function (date) {
    const today = new Date();
    const limit = new Date(date);
    const delta = Math.ceil((limit - today) / (24 * 60 * 60 * 1000));
    return delta < 0 ? 'En retard' : delta == 0 ? "Aujourd'hui" : ('J - ' + delta);
};


// Lecture des paramètres GET de l'URL courante
export const parseGetArgs = function () {
    const args = {};
    location.search.substr(1).split("&").forEach(function(item) {
        const [key, value = ''] = item.split("=");
        args[key] = value;
    });
    return args;
};


// Commit depuis une action d'un module du store vers un autre module du store
export const rootStoreCommit = function (store, commitPath, args = null) {
    store.commit(commitPath, args, {root: true});
};


// Filtre une liste de suggestions en fonction d'une saisie utilisateur
export const getActiveSuggestions = function (suggestions, rawQueryOrKeywords, maxSuggestions = 10, minKeywordLength = 1) {

    if (suggestions.length === 0) {
        return [];
    }

    // Mots-clés : s'ils ne sont pas fournis, on les détecte à partir de la requête brute
    let keywords;
    if (rawQueryOrKeywords.constructor === Array) {
        keywords = [ ...rawQueryOrKeywords ];
    } else {
        keywords = [];
        rawQueryOrKeywords.split(' ').forEach((keyword) => {
            keyword = keyword.trim();
            if (keyword.length >= minKeywordLength) {
                keywords.push(
                    keyword.toLowerCase()
                );
            }
        });
    }

    // Test de chaque suggestion
    const activeSuggestions = [];
    for(let suggestion of suggestions) {
        let keepSuggestions = [];
        for(let keyword of keywords) {

            const suggestionStr = (typeof suggestion === 'object' ? suggestion.label.split('<em>')[0] : suggestion);
            const suggestionWords = suggestionStr.toLowerCase().split(' ').map(word => word.trim());
            let keepSuggestion = false;
            for(let word of suggestionWords) {
                if (word.slice(0, keyword.length) == keyword) {
                    keepSuggestion = true;
                    break;
                }
            }

            keepSuggestions.push(keepSuggestion);
        }
        if (keepSuggestions.length > 0 && keepSuggestions.reduce( (acc, keep) => acc && keep, true)) {
            activeSuggestions.push(suggestion);
            if (activeSuggestions.length >= maxSuggestions) {
                break;
            }
        }
    }

    return activeSuggestions.slice(0, maxSuggestions);
};


// Teste si une string (ou une liste de string) correspond à une requête
export const isMatchingQuery = function (str, rawQueryOrKeywords) {
    const arrayOfStr = Array.isArray(str) ? str : [str];
    const results = getActiveSuggestions(arrayOfStr, rawQueryOrKeywords, 1, 1);
    return results.length > 0;
};


// Filtrage multi-critère
export const filterItems = function ({items, filtersValues, filtersToApply}) {
    let filteredItems = [ ...items ];

    filtersToApply.forEach( (filterToApply) => {

        const filterValue = filtersValues[ filterToApply.filterKey ];

        // Filtrage par rapport à une liste
        if (filterValue.constructor === Array) {
            if (filterToApply.itemSubKey === undefined) {
                filteredItems = filteredItems.filter(item => {
                    // Si l'attribut de l'item à tester est lui même une liste, on vérifie que l'intersection est non nulle
                    if (item[ filterToApply.itemKey ].constructor === Array) {
                        return item[ filterToApply.itemKey ].filter(v => filterValue.includes(v)).length > 0;
                    } else{
                        return [ ...filterValue ].map(v => '' + v).includes(item[ filterToApply.itemKey ]);
                    }
                });
            } else {
                filteredItems = filteredItems.filter(item => {
                    const itemValue = item[ filterToApply.itemKey ][ filterToApply.itemSubKey ];

                    // Cas particulier : pour un contact non lié à un client, le filtrage sur les champs du client est toujours accepté
                    if (itemValue === false && filterValue.length === 0) {
                        return true;
                    }

                    // Si l'attribut de l'item à tester est lui même une liste, on vérifie que l'intersection est non nulle
                    if (itemValue.constructor === Array) {
                        return itemValue.filter(v => filterValue.includes(v)).length > 0;
                    } else {
                        return filterValue.includes(itemValue);
                    }
                });
            }
        }
        // Filtrage par rapport à un mot-clé
        else if (filterValue.length > 0) {

            const searchInsideWordsAndIgnoreSpaces = (filterToApply.searchInsideWordsAndIgnoreSpaces === true);
            const isDate = (filterToApply.isDate === true);

            // Cas particulier : recherche du mot clé fusionné, n'importe où dans les données fusionnées
            // utile pour un numéro de téléphone, recherché avec ou sans espaces
            if (searchInsideWordsAndIgnoreSpaces) {
                const mergedKeywords = filterValue.replace(/\s/g, '').toLowerCase();

                filteredItems = filteredItems.filter((item) => {
                    let data = item[ filterToApply.itemKey ];
                    if (filterToApply.itemSubKey !== undefined) {
                        data = data[ filterToApply.itemSubKey ];
                    }

                    if (Array.isArray(data)) {
                        data = data.join('§');
                    }

                    data = data.replace(/\s/g, '').toLowerCase();
                    return (data.indexOf(mergedKeywords) >= 0);
                });
            }
            // Cas particulier : recherche d'une date
            else if (isDate) {

                let filterDate = '';
                const dateSegments = filterValue.replace(/\s/g, '').split('/');

                if (dateSegments.length === 1 && dateSegments[0].length === 4) {
                    filterDate = dateSegments[0] + '-';
                }
                else if (dateSegments.length === 2 && dateSegments[0].length === 2 && dateSegments[1].length === 4) {
                    filterDate = dateSegments[1] + '-' + dateSegments[0] + '-';
                }

                filteredItems = filteredItems.filter((item) => {
                    return item.date.indexOf(filterDate) === 0;
                });
            }
            // Cas standard : recherche de chaque mot clé, au début des mots uniquement
            else {
                if (filterToApply.itemSubKey === undefined) {
                    filteredItems = filteredItems.filter(item => isMatchingQuery(item[ filterToApply.itemKey ], filterValue));
                } else {
                    filteredItems = filteredItems.filter(item => isMatchingQuery(item[ filterToApply.itemKey ][ filterToApply.itemSubKey ], filterValue));
                }
            }
        }
    });

    return filteredItems;
};


// Fonction de comparaison de 2 chaines de caractère en ignorant la casse
export const strcmp = function (str1, str2) {
    return str1.trim().toUpperCase().localeCompare(str2.trim().toUpperCase());
};


// Requête API avec overlay et mise à jour de session automatiques
// Utilisable depuis une action Vuex ou depuis un component
// options : {noOverlay}
// callback : utiliser .then uniquement (catch jamais déclenché)
export const callAPI = function (store, endPoint, datas = {}, options = {}) {

    const showOverlay = (options.noOverlay !== true);

    // Ecran de chargement
    if (showOverlay) {
        rootStoreCommit(store, 'overlay/push');
    }

    const axiosArgs = {withCredentials: true};

    // Si une valeur est un objet, on la linéarize, sauf dans le cas d'un fichier
    const maybeSerialize = function (item) {
        if (typeof item == 'object' && !(item instanceof File || item instanceof FileList)) {
            item = JSON.stringify(item);
        }
        return item;
    };

    // Conversion des datas pour que PHP récupère un objet POST correct
    // (pour corriger une incompatibilité entre Axios et les frameworks PHP)
    const formData = new FormData();
    for (let key in datas) {

        // Cas des tableaux
        if (Array.isArray( datas[key] )) {
            for (let item of datas[key]) {
                formData.append(key + '[]', maybeSerialize(item));
            }
        }
        // Autres cas (scalaires ou objets)
        else {
            formData.append(key, maybeSerialize( datas[key] ));
        }
        // Si le paramètre est un fichier, on adapte l'entête de la requête
        if (datas[key] instanceof File) {
            axiosArgs.headers = {'Content-Type': 'multipart/form-data'};
        }
    }

    // Axios pour communication avec le serveur
    // withCredentials:true permet d'inclure les cookies dans les requêtes
    const apiBaseUrl = store.rootGetters ? store.rootGetters.apiBaseUrl : store.getters.apiBaseUrl ;
    return axios.post(apiBaseUrl + endPoint, formData, {withCredentials: true})
    .then(
        response => {

            // Fin d'écran de chargement
            if (showOverlay) {
                rootStoreCommit(store, 'overlay/pop');
            }

            // Mise à jour de la session utilisateur
            if (response.data.session) {
                response.data.session.error = '';
                rootStoreCommit(store, 'session/set', response.data.session);
            }

            // En cas d'erreur fatale prévue par le serveur
            if (response.data.fatalError) {
                alert(response.data.fatalError);
            }

            // Retourne uniquement les datas + le statut, pas les infos sur la session
            // Interceptable avec .then
            response.data.response.status = response.data.status;
            return response.data.response;
        }
    )
    // Erreur côté serveur : le retour n'est pas JSON
    .catch(
        response => {
            if (showOverlay) {
                rootStoreCommit(store, 'overlay/pop');
            }
            if (response.indexOf('Network Error') == -1) {
                alert(response);
            }
        }
    );
};


// Force récursivement des attributs d'un objet
export const forceSettings = function (settingsToForce, settings) {
    const updatedSettings = { ...settings };

    if (settingsToForce) {
        Object.keys(settingsToForce).forEach( key => {
            const value = settingsToForce[key];
            if (typeof value !== 'object') {
                updatedSettings[key] = value;
            }
            else if (updatedSettings[key] !== undefined) {
                Object.keys(value).forEach( subkey => {
                    const subvalue = settingsToForce[key][subkey];
                    updatedSettings[key][subkey] = subvalue;
                });
            }
        });
    }
    return updatedSettings;
};

// Scroll pour rendre un élément visible
export const scrollToDOMelement = function ($wrapper, selector) {
    setTimeout(() => {
        const $target = $wrapper.querySelector(selector);
        if ($target) {
            $target.scrollIntoView({
                block :'center', behavior: 'smooth'
            });
        }
    }, 100);
};

// Génère sur le serveur puis ouvre un fichier CSV
export const generateCSV = function (store, columns, items, filename) {

    // Filtrage des items : on ne conserve que les clés nécessaires pour économiser de la bande passante
    const filteredItems = [];
    const keys = columns.map(c => c.itemKey);
    items.forEach( item => {
        const filteredItem = {};
        keys.forEach( key => {
            filteredItem[ key ] = item[ key ];
        });
        filteredItems.push(filteredItem);
    });

    // Etape 1 : génération sur le serveur
    callAPI(store, 'Main/generateCSV', {columns, items: filteredItems})
    .then( response => {
        if (response.status) {

            // Etape 2 : lance le téléchargement
            const apiBaseUrl = store.rootGetters ? store.rootGetters.apiBaseUrl : store.getters.apiBaseUrl ;
            const url = apiBaseUrl + 'Main/getLastCSVforMe/' + filename;

            store.commit('alert/alert', {
                message : "L'extraction est prête !<br/><br/><a class='form-button green' href='" + url + "' target='_blank'>TÉLÉCHARGER (fichier CSV)</a>",
                width: 420
            }, {root:true});
        }
    });
};
