<template>
    <div class="session-info d-flex align-items-center">

        <span class="name desktop-only">
            {{ session.userName }}
        </span>

        <span class="logout control black" @click="logout()">
            <img src="@/assets/img/icon-logout.svg" width="24" height="24" />
        </span>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    export default {
        name: "SessionInfo",

        computed: {
    		...mapState('session', ['session'])
        },

        methods: {
    		...mapActions('session', ['logout'])
    	}
    };
</script>
