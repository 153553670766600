<template>
	<div id="app">

		<transition mode="out-in" appear>

			<login v-if=" !session.isLogged" />

			<the-crm v-else />

		</transition>

		<transition>
            <loading-overlay v-if="displayOverlay" />
        </transition>

		<confirm-popups />
		<alert-popups />
		<prompt-popups />

	</div>
</template>

<script>
	import {mapState, mapGetters, mapActions} from 'vuex';
	import jQuery from "jquery";
	import Login from '@/components/Login.vue';
	import TheCrm from '@/components/TheCrm.vue';
	import ConfirmPopups from '@/components/Lib/Misc/ConfirmPopups.vue';
	import AlertPopups from '@/components/Lib/Misc/AlertPopups.vue';
	import PromptPopups from '@/components/Lib/Misc/PromptPopups.vue';
	import LoadingOverlay from '@/components/Lib/Misc/LoadingOverlay.vue';
	export default {
		name: 'App',
		components: {Login, LoadingOverlay, ConfirmPopups, AlertPopups, PromptPopups, TheCrm},

		computed: {
			...mapState('session', ['session']),
			...mapGetters('overlay', {displayOverlay: 'isDisplayed'}),
		},

		methods: {
			...mapActions('session', {checkSession: 'refresh'}),

			// Mise à jour de classes CSS sur BODY en fonction de la largeur d'écran
			updateBodyResponsiveClasses () {
				const $ = jQuery;
				const width = $(window).width();
				const thresholds = [400, 500, 640, 768, 960, 1024, 1280];
				for(let maxWidth of thresholds) {
					if (width <= maxWidth) {
						$('body').addClass('w' + maxWidth);
					} else {
						$('body').removeClass('w' + maxWidth);
					}
				}
			},

			// Fermeture de toutes les micropopups ouvertes
			closeAllMicroPopups () {
				const $ = jQuery;
				$('.micro-popup .close-btn').trigger('click');
			}
		},

		created () {
			const $ = jQuery;

			// Vérification de la session à interval régulier
			this.checkSession();
			setInterval(() => {this.checkSession()}, 10000);

			// Mise à jour de classes CSS sur BODY en fonction de la largeur d'écran
			$(window).on('resize', this.updateBodyResponsiveClasses).trigger('resize');

			// Si touche ESCAPE non interceptée par Vue, ferme les micropopups ouvertes
			$(document).on('keyup', (e) => {
                if (e.keyCode === 27 || e.which === 27) {
                    this.closeAllMicroPopups();
                }
            });

			// Si un clic est détecté en dehors d'une micropopup, on les ferme toutes
			$(document).on('click', (e) => {
				const isOutsideMicroPopup = $(e.target).closest('.micro-popup').length === 0;
				if (isOutsideMicroPopup) {
					this.closeAllMicroPopups();
				}
			});
		},

		watch : {
			session () {
				const $ = jQuery;
				if (this.session.isLogged) {
					$('body').addClass('logged');
				} else {
					$('body').removeClass('logged');
				}
			}
		}
	}
</script>
