<template>
    <div :class="{active, 'no-counter': counter === false}"
         @click.stop="active ? onClick() : onClickNotActive()"
         @mouseover="showOverview = true"
         @mouseleave="showOverview = false"
         >

        <slot name="icon"></slot>

        <span class="counter" v-if="counter !== false">
            {{ counter }}
        </span>

        <div class="overview" v-if="showOverview">
            <slot v-if="active" name="tip"></slot>
            <slot v-else name="tipIfNotActive"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TipIcon',

        props: {
            active: {default: false},
            counter: {default: false},
            iconSrc: {default:''}
        },

        data () {
            return {
                showOverview: false
            };
        },

        methods: {
            onClick () {
                this.$emit('click');
            },
            onClickNotActive () {
                this.$emit('clickIfNotActive');
            }
        }
    };
</script>
