/*
 * Liste intégrale des taches
 * utiliser ce store pour lire / modifier / supprimer toute tache
 */

import {callAPI, getTodayDate} from '@/helpers.js';

export default {

    namespaced: true,

    state: {
        tasks: [],
        highlightedId:-1,

        // Types de taches distinctes
        types: []
    },

    getters: {
        typesOptions (state) {
            return state.types.map( type => {
                return {
                    value: type,
                    label: type
                };
            });
        }
    },

    mutations: {
        setTasks (state, tasks) {
            state.tasks = [ ...tasks ];
        },

        // Met à jour ou ajoute une tache au store
        // A appeler après toute mise à jour sur le serveur
        setTask (state, task) {
            const index = state.tasks.findIndex(e => e.id == task.id);
            if (index >= 0) {
                state.tasks.splice(index, 1, { ...task });
            } else {
                state.tasks.push({ ...task });
            }
        },

        // Retire une tache du store
        unsetTask (state, taskId) {
            const index = state.tasks.findIndex(e => e.id == taskId);
            if (index >= 0) {
                state.tasks.splice(index, 1);
            }
        },

        setTypes (state, types) {
            state.types = [ ...types ];
        },

        // Met en surbrillance une ligne
        setHighlightedId (state, id) {
            state.highlightedId = id;
        },
        // Désactive la surbrillance
        resetHighlightedId (state) {
            state.highlightedId = -1;
        }
    },

    actions: {
        // Hydration complète, à appeler au chargement de l'app
        loadFromDatabase (context) {
            callAPI(context, 'Tasks/getAll', {}, {noOverlay: true})
            .then( response => {
                if (response.status === true) {
                    context.commit('setTasks', response.tasks);
                }
            });
        },

        // Hydration ou mise à jour de la liste des types de taches distinctes
        loadTypesFromDatabase (context) {
            callAPI(context, 'Main/getTasksTypesSuggestions', {}, {noOverlay: true})
            .then( response => {
                if (response.status === true) {
                    context.commit('setTypes', response.suggestions);
                    context.commit('pages/fixTasksPagesFilters', response.suggestions, {root:true});
                }
            });
        },

        // Retourne une tache hydratée ou une tache vierge si n'existe pas
        get (context, taskId) {
            const index = context.state.tasks.findIndex(e => e.id == taskId);
            let task;
            if (index >= 0) {
                task = context.state.tasks[index];
            } else {
                task = {
                    id: -1,
                    customerId: -1,
                    type: '',
                    date: getTodayDate(),
                    description: '',
                    usersIds: [context.rootState.session.session.userId]
                };
            }
            return { ...task };
        },

        // Création ou mise à jour d'une tache
        // Sauvegarde en DB puis mise à jour du store
        save (context, task) {
            return new Promise( (resolve, reject) => {
                callAPI(context, 'Tasks/save', {task: { ...task }}, {}).then( response => {
                    if (response.status === true) {
                        context.commit('setTask', response.task);

                        // Mise à jour des types de tâches
                        context.dispatch('loadTypesFromDatabase');

                        // Mise en surbrillance pendant quelques secondes
                        context.commit('setHighlightedId', response.task.id);
                        context.commit('customers/setHighlightedId', response.task.customerId, {root:true});
                        setTimeout(() => {
                            context.commit('resetHighlightedId');
                            context.commit('customers/resetHighlightedId', null, {root:true});
                        }, 500);

                        resolve();
                    } else {
                        reject(response.errors);
                    }
                });
            });
        },


        // Suppression d'une tache sur le serveur puis dans le store
        delete (context, taskId) {
            return new Promise( (resolve, reject) => {
                callAPI(context, 'Tasks/delete', {taskId}, {})
                .then( response => {
                    if (response.status === true) {
                        context.commit('unsetTask', taskId);

                        // Mise à jour des types de tâches
                        context.dispatch('loadTypesFromDatabase');

                        resolve();
                    }
                });
            });
        }
    }
};
