<template>
    <main id="the-body">

        <div v-if="currentPageIndex === undefined">

            <empty-page />

        </div>
        <div v-else>

            <component
                v-for="(page, index) in pages"
                v-if="index == currentPageIndex"
                :is="page.type.toLowerCase()"
                :pageIndex="currentPageIndex"
                :key="index"
                />
        </div>
    </main>
</template>

<script>
    import {mapState} from 'vuex';
    import EmptyPage from '@/components/Pages/EmptyPage.vue';
    import Customers from '@/components/Pages/Customers/Customers.vue';
    import Contacts from '@/components/Pages/Contacts/Contacts.vue';
    import Etabs from '@/components/Pages/Etabs/Etabs.vue';
    import Events from '@/components/Pages/Events/Events.vue';
    import Tasks from '@/components/Pages/Tasks/Tasks.vue';
    import Mails from '@/components/Pages/Mails.vue';
    import Search from '@/components/Pages/Search/Search.vue';
    import Settings from '@/components/Pages/Settings.vue';
    export default {
        name: "TheBody",
        components: {
            EmptyPage, Customers, Contacts, Etabs, Events, Tasks, Mails, Search, Settings
        },

        computed:{
            ...mapState('pages', ['pages', 'currentPageIndex'])
        }
    };
</script>
