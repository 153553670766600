<template>
    <div id="the-crm">
        <the-header />
        <the-body />
    </div>
</template>

<script>
    import jQuery from "jquery";
    import {mapState, mapActions} from 'vuex';
    import TheHeader from '@/components/TheHeader.vue';
    import TheBody from '@/components/TheBody.vue';
    export default {
        name: "TheCrm",
        components: {TheHeader, TheBody},

        data () {
            return {
                pagesLoaded: false,
                intervalTables: false
            };
        },

        computed: {
            ...mapState('pages', ['pages', 'currentPageIndex'])
        },

        created () {
            // Charge les paramètres généraux du logiciel
            this.loadSettingsFromDatabase();
            this.loadUsersFromDatabase();

            // Charge la liste complète des clients, des points de livraison, contacts, événements
            this.loadCustomersFromDatabase();
            this.loadAttachmentsFromDatabase();
            this.loadEtabsFromDatabase();
            this.loadEventsFromDatabase();
            this.loadEventsTypesFromDatabase();
            this.loadTasksFromDatabase();
            this.loadTasksTypesFromDatabase();
            this.loadContactsFromDatabase();
            this.loadContactsJobsFromDatabase();
            this.loadContactsTagsFromDatabase();

            // Charge l'état initial des onglets et des pages, mémorisé pour l'utilisateur courant lors de se dernière connexion
            this.loadCurrentPagesFromDatabase().then( () => {
                this.pagesLoaded = true;
            });

            // Dimensionnement des tableaux de données du CRM
            this.intervalTables = setInterval(() => {this.updateTablesHeight();}, 400);
        },

        destroyed () {
            clearInterval(this.intervalTables);
            this.intervalTables = false;
        },

        watch: {
            // Sauvegarde de l'état actuel des pages ouvertes à chaque ouverture / fermeture / changement d'ordre
            pages () {
                if (this.pagesLoaded) this.saveCurrentPagesToDatabase();
            },
            currentPageIndex () {
                if (this.pagesLoaded) this.saveCurrentPagesToDatabase();
            }
        },

        methods: {
            ...mapActions('pages', {saveCurrentPagesToDatabase: 'saveToDatabase', loadCurrentPagesFromDatabase: 'loadFromDatabase'}),
            ...mapActions('settings', {loadSettingsFromDatabase: 'loadFromDatabase'}),
            ...mapActions('users', {loadUsersFromDatabase: 'loadFromDatabase'}),
            ...mapActions('attachments', {loadAttachmentsFromDatabase: 'loadFromDatabase'}),
            ...mapActions('customers', {loadCustomersFromDatabase: 'loadFromDatabase'}),
            ...mapActions('etabs', {loadEtabsFromDatabase: 'loadFromDatabase'}),
            ...mapActions('events', {
                loadEventsFromDatabase: 'loadFromDatabase',
                loadEventsTypesFromDatabase: 'loadTypesFromDatabase'
            }),
            ...mapActions('tasks', {
                loadTasksFromDatabase: 'loadFromDatabase',
                loadTasksTypesFromDatabase: 'loadTypesFromDatabase'
            }),
            ...mapActions('contacts', {
                loadContactsFromDatabase: 'loadFromDatabase',
                loadContactsJobsFromDatabase: 'loadJobsFromDatabase',
                loadContactsTagsFromDatabase: 'loadTagsFromDatabase'
            }),

            // Dimensionnement des tableaux de données du CRM et certaines pages spécifiques pour qu'ils remplissent l'espace disponible
            updateTablesHeight () {
                const $ = jQuery;
                $('.page-overflow-wrapper').each(function () {
                    const $wrapper = $(this);
                    const h = $(window).height() - $wrapper.offset().top;
                    $wrapper.css({height: h + 'px'});
                });
            }
        }
    };
</script>
