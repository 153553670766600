export default {
    data () {
        return {
            // Lignes cochées. Attention, des lignes non visibles peuvent être inclues, utiliser plutôt this.realCheckedItems
            checkedIds: []
        };
    },

    computed: {
        // Liste des clients cochés, restreinte à ceux qui sont visibles après filtrage
        realCheckedItems () {
            return this.filteredAndSortedItems.filter(
                customer => this.checkedIds.includes(customer.id)
            );
        }
    },

    methods: {
        // Coche / décoche une ligne
        switchCheck (lineId) {
            const index = this.checkedIds.findIndex( id => id == lineId );
            if (index !== -1) {
                this.checkedIds.splice(index, 1);
            } else {
                this.checkedIds.push(lineId);
            }
        },

        // Coche / décoche toutes les lignes visibles
        switchCheckAll () {
            const [firstItem, ] = this.filteredAndSortedItems;
            if (firstItem) {
                if (this.checkedIds.includes(firstItem.id)) {
                    this.checkedIds = [];
                } else {
                    this.checkedIds = this.filteredAndSortedItems.map(customer => customer.id);
                }
            }
        }
    }
};
