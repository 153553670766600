<template>
    <div class="contact-etabs list-of-details" @click.stop="">

        <tip-icon :active="etabsActive" :counter="etabs.length"
                  @click="openEtabs"
                  >
            <template v-slot:icon>
                <img src="@/assets/img/menu-icon-etabs.svg" alt="" width="18" height="18" />
            </template>
            <template v-slot:tip>
                <div v-for="etab in etabs" class="no-wrap">
                    {{ etab.name }}
                    <span class="light" v-if="etab.typologies">
                        {{ etab.typologies.join(' + ') }}
                    </span>
                </div>
                <div class="info">
                    clic : OUVRIR points de livraison
                </div>
            </template>
        </tip-icon>

    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {customerToEtabPageFilters} from '@/helpersForCrm.js';
import TipIcon from '@/components/Global/TipIcon.vue';
export default {
    name: 'ContactEtabs',
    components: {TipIcon},

    props: ['etabs'],

    computed: {
        ...mapGetters('customers', ['customerById']),

        etabsActive () {
            return this.etabs.length > 0;
        }
    },

    methods: {

        ...mapActions('pages', ['addPageEtabs']),

        openEtabs () {

            if (this.etabs.length === 0) {
                return;
            }

            const firstEtab = this.etabs[0];
            const customer = this.customerById[ firstEtab.customerId ];
            let filters;

            if (this.etabs.length === 1) {
                const etab = firstEtab;
                filters = {
        			statuses: [customer.status],
        			customer: customer.name,
        			name: etab.name,
        			address: etab.city
        		}
            } else {
                filters = customerToEtabPageFilters(customer);
            }
            this.addPageEtabs({ filters });
        }
    }
};
</script>
