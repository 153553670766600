<template>

    <div class="customer-popup">

        <popup v-if="opened"
               :width="960"
               :buttonsOnLeft="fields.id > 0 ? [{label:'Supprimer', event:'delete', classes:'icon orange delete'}] : []"
               :buttonsOnRight="[{label:'Annuler', event:'close', classes:'icon cancel'}, {label:'Enregistrer', event:'save', classes:'green icon ok'}]"
               @close="opened = false"
               @save="saveCustomer()"
               @delete="deleteCustomer(true)"
               >

            <div class="flex-fields break-768">

                <form-select-field
                    v-model="fields.status"
                    label="Statut"
                    :fullWidthDropdown="true"
                    :options="customersStatusesOptions"
                    class="flex-1"
                    />

                <form-input-field
                    v-model="fields.name"
                    label="Nom du client"
                    :hasReset="true"
                    :hideResetIfEmpty="true"
                    class="flex-2"
                    />

                <form-select-field
                    v-model="fields.workshopId"
                    label="Atelier"
                    :options="workshopsOptions"
                    :fullWidthDropdown="true"
                    class="flex-1"
                    />
            </div>

            <div class="flex-fields break-768">
                <div class="flex-1"></div>
                <form-select-field
                   v-model="fields.parentId"
                   label="Client parent"
                   :options="customersOptions"
                   :searchable="true"
                   :hasReset="true"
                   :fullWidthDropdown="true"
                   :resetValue="0"
                   class="flex-2"
                   />
                <div class="flex-1"></div>
            </div>

            <div class="flex-fields break-768">

                <form-input-field
                    v-model="fields.effectifs"
                    label="Effectifs"
                    type="number"
                    :selectContentOnClick="true"
                    class="flex-1"
                    />

                <form-input-field
                    v-model="fields.provider"
                    label="Prestataire d'origine"
                    suggestionsEndPoint="Main/getProvidersSuggestions"
                    :alwaysShowAllSuggestions="true"
                    :hasReset="true"
                    :hideResetIfEmpty="true"
                    class="flex-2"
                    />

                <form-input-field
                    v-model="fields.city"
                    label="Code postal + Ville"
                    :uppercase="true"
                    suggestionsEndPoint="Main/getCitySuggestions"
                    :hasReset="true"
                    :hideResetIfEmpty="true"
                    class="flex-3"
                    />
            </div>

            <div class="flex-fields">
                <form-textarea-field v-model="fields.comments" label="Commentaires" />
            </div>

            <div class="errors" v-if="errors.length > 0">
                <ul>
                    <li v-for="error in errors">
                        {{ error }}
                    </li>
                </ul>
            </div>

        </popup>
    </div>
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
    import Popup from '@/components/Lib/Misc/Popup.vue';
    import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
    import FormSelectField from '@/components/Lib/Forms/FormSelectField.vue';
    import FormTextareaField from '@/components/Lib/Forms/FormTextareaField.vue';
    export default {
        name: 'CustomerPopup',
        components: {Popup, FormInputField, FormSelectField, FormTextareaField},

        data () {
            return {
                opened: false,
                fields: {},
                errors: []
            };
        },

        computed:{
            ...mapState('customers', ['customers']),
            ...mapState('settings', ['customersStatusesOptions']),
            ...mapGetters('customers', ['customersOptions']),
            ...mapGetters('settings', ['workshopsOptions'])
        },

        methods: {
            ...mapActions('customers', {getCustomer: 'get', saveCustomerAction: 'save', isDeletableAction: 'isDeletable', deleteCustomerAction: 'delete'}),
            ...mapMutations('confirm', ['confirm']),
            ...mapMutations('alert', ['alert']),

            // Popup de création / modification d'un client
            addOrEditCustomer (customerId) {
                this.getCustomer(customerId).then( customer => {
                    this.fields = customer;
                    this.errors = [];
                    this.opened = true;
                });
            },

            // Création / modification d'un client : sauvegarde sur le serveur et mise à jour du store
            saveCustomer() {
                this.errors = [];
                this.saveCustomerAction(this.fields)
                .then( () => {this.opened = false;})
                .catch( errors => {this.errors = [ ...errors ];});
            },

            // Suppression d'un client
            deleteCustomer(checkBefore) {

                // Si demandé, vérifie si supprimable puis demande de confirmation
                if (checkBefore) {
                    const statusTxt = this.fields.status.indexOf('CLIENT') !== -1 ? 'client' : 'prospect';

                    this.isDeletableAction(this.fields.id).then(() => {
                        this.confirm({
                            question: "Supprimer ce " + statusTxt + " et tous les événements, tâches et emails associés ?",
                            btnYesLabel: "OUI, supprimer",
                            btnYesClasses: 'icon orange delete',
                            btnYesCallback: () => {
                                this.deleteCustomer(false);
                            }
                        });
                    }).catch(() => {
                        this.alert({
                            message: "Pour supprimer ce " + statusTxt + ", veuillez supprimer au préalable tous ses contacts et établissements. <br/><br/>Ou bien archivez-le en changeant son statut."
                        });
                    });
                }
                else {
                    // Suppression effective
                    this.deleteCustomerAction(this.fields.id).then( () => {
                        this.opened = false;
                    });
                }
            }
        }
    };
</script>
