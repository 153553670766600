<template>
    <div class="page settings-page">

        <div class="page-toolbar">
            <div class="txt">
                Il n'y a rien à configurer pour l'instant...
            </div>
        </div>

        <div class="page-body">

            <div class="page-overflow-wrapper">
                &nbsp;
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Settings'
    };
</script>
