/*
 * Liste des utilisateurs du logiciel
 */

import {callAPI} from '@/helpers.js';

export default {

    namespaced: true,

    state: {
        users: []
    },

    getters: {
        usersOptions (state) {
            return state.users.map( user => {
                return {
                    value: user.id,
                    label: user.name
                };
            });
        },

        usersById (state) {
            const usersById = {};
            state.users.forEach( user => {
                usersById[ user.id ] = user;
            });
            return usersById;
        }
    },

    mutations: {
        setUsers (state, users) {
            state.users = [ ...users ];
        }
    },

    actions: {
        // Hydration complète, à appeler au chargement de l'app
        loadFromDatabase (context) {
            callAPI(context, 'Users/getAll', {}, {noOverlay: true})
            .then( response => {
                if (response.status === true) {
                    context.commit('setUsers', response.users);
                }
            });
        }
    }
};
