<template>
    <div class="page events-page">

        <events-toolbar
            :pageIndex = "pageIndex"
            :items = "items"
            :filteredAndSortedItems = "filteredAndSortedItems"
            :realCheckedItems = "realCheckedItems"
            @generateCSV="generateCSV()"
            />

        <div class="page-body">
            <div class="crm-table page-overflow-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th class="check-all" @click="switchCheckAll()">
                                <img src="@/assets/img/checkbox-all.svg" width="22" height="22" />
                            </th>
                            <th style="width:132px;">
                                <crm-table-filter type='select' label="Atelier" filterKey="workshops" :pageIndex="pageIndex" :options="workshopsOptions" />
                            </th>
                            <th style="width:120px;">
                                <crm-table-filter type='select' label="Statut" filterKey="statuses" :pageIndex="pageIndex" :options="statusesOptions" />
                            </th>
                            <th style="width:220px;">
                                <crm-table-filter label="Client / Prospect" filterKey="customer" :pageIndex="pageIndex" />
                            </th>
                            <th style="width:172px;">
                                <crm-table-filter label="Date" filterKey="date" :pageIndex="pageIndex" searchPlaceholder="aaaa ou mm/aaaa" />
                            </th>
                            <th style="width:190px;">
                                <crm-table-filter type='select' label="Type d'évènement" filterKey="type" :pageIndex="pageIndex" :options="eventsTypesOptions" />
                            </th>
                            <th style="width:100px;">
                                <crm-table-filter label="Fichiers" filterKey="attachment" :pageIndex="pageIndex" />
                            </th>
                            <th>
                                <crm-table-filter label="Description" filterKey="description" :pageIndex="pageIndex" />
                            </th>
                            <th style="width:120px;">
                                <crm-table-filter type='select' label="Collaborateurs" filterKey="users" :pageIndex="pageIndex" :options="usersOptions" position="RIGHT" />
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="event in filteredAndSortedItems"
                            :class="{highlight: event.id == highlightedId}"
                            @click="$refs.event_popup.addOrEditEvent(event.id)"
                            >
                            <td class="check" @click.stop="switchCheck(event.id)">
                                <img v-if="checkedIds.includes(event.id)" src="@/assets/img/checkbox-checked.svg" width="24" height="24" />
                                <img v-else src="@/assets/img/checkbox-unchecked.svg" width="24" height="24" />
                            </td>
                            <td>
                                {{ workshopNameById[ event.customer.workshopId ] }}
                            </td>
                            <td>
                                {{ customerStatusTxtById[ event.customer.status ] }}
                            </td>
                            <td class="strong allow-overflow relative icon-on-right">
                                {{ event.customer.name }}

                                <div class="list-of-details">
                                    <tip-icon :active="true" @click="openCustomer(event.customer)">
                                        <template v-slot:icon>
                                            <img src="@/assets/img/icon-external-link.svg" alt="" width="14" height="14" />
                                        </template>
                                        <template v-slot:tip>
                                            <div class="info">
                                                clic : OUVRIR {{ event.customer.status.substr(-6) == 'CLIENT' ? 'client' : 'prospect'}}
                                            </div>
                                        </template>
                                    </tip-icon>
                                </div>
                            </td>
                            <td class="main">
                                {{ event.date | formatDate('FULL') }}
                            </td>
                            <td class="main">
                                {{ event.type }}
                            </td>
                            <td class="allow-overflow">
                                <div class="list-of-details">
                                    <tip-icon :active="event.attachmentsIds.length > 0"
                                              :counter="event.attachmentsIds.length"
                                              @click="$refs.event_popup.addOrEditEvent(event.id)"
                                              @clickIfNotActive="$refs.event_popup.addOrEditEvent(event.id)"
                                              >
                                        <template v-slot:icon>
                                            <img src="@/assets/img/menu-icon-attachment.svg" alt="" width="18" height="18" />
                                        </template>
                                        <template v-slot:tip>
                                            <div v-for="attachmentId of event.attachmentsIds">
                                                <div class="no-wrap">
                                                    {{ attachmentsById[attachmentId].ext }} : {{ attachmentsById[attachmentId].name }}
                                                </div>
                                            </div>
                                        </template>
                                    </tip-icon>
                                </div>
                            </td>
                            <td>
                                {{ event.description }}
                            </td>
                            <td class="allow-overflow tip-content-on-right">
                                <div class="list-of-details justify-content-center">
                                    <tip-icon :active="true" :counter="event.usersIds.length" @click="$refs.event_popup.addOrEditEvent(event.id)">
                                        <template v-slot:icon>
                                            <img src="@/assets/img/icon-users.svg" alt="" width="18" height="18" />
                                        </template>
                                        <template v-slot:tip>
                                            <div v-for="userId of event.usersIds">
                                                <div class="no-wrap">
                                                    {{ usersById[ userId ].name }}
                                                </div>
                                            </div>
                                        </template>
                                    </tip-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <event-popup ref="event_popup" />
    </div>
</template>

<script>
    import {mapState, mapGetters, mapActions} from 'vuex';
    import {filterItems, strcmp, scrollToDOMelement, formatDate, generateCSV} from '@/helpers.js';
    import CrmTableCheckboxesMixin from '@/mixins/crmTableCheckboxesMixin.js';
    import TipIcon from '@/components/Global/TipIcon.vue';
    import CrmTableFilter from '@/components/Global/CrmTableFilter.vue';
    import EventPopup from '@/components/Pages/Events/EventPopup.vue';
    import EventsToolbar from '@/components/Pages/Events/EventsToolbar.vue';
    import attachmentsMixin from '@/mixins/attachmentsMixin.js';
    export default {
        name: 'Events',
        components: {EventPopup, EventsToolbar, CrmTableFilter, TipIcon},
        mixins: [CrmTableCheckboxesMixin, attachmentsMixin],
        filters: {formatDate},

        props: ['pageIndex'],

        computed: {
            ...mapState('settings', {statusesOptions: 'customersStatusesOptions'}),
            ...mapState('pages', ['pages']),
            ...mapState('events', {items: 'events', highlightedId:'highlightedId'}),
            ...mapGetters('settings', ['trokoolEtabById', 'workshopsOptions', 'workshopNameById', 'customerStatusTxtById']),
            ...mapGetters('customers', ['customerById']),
            ...mapGetters('users', ['usersOptions', 'usersById']),
            ...mapGetters('events', {eventsTypesOptions:'typesOptions'}),
            ...mapGetters('attachments', ['attachmentsById']),

            settings () {
                return this.pages[this.pageIndex].settings;
            },

            filteredAndSortedItems () {
                // Pour forcer mise à jour de la valeur computed
                this.pages;

                // Enrichissement de champs croisés
                let items = this.items.map(e => {
                    const event = { ...e };

                    // client associé
                    event.customer = this.customerById[event.customerId];

                    // fichiers joints
                    event.attachments = this.hydrateAttachments(event.attachmentsIds, this.attachmentsById);
                    event.attachmentsNames = event.attachments.map(a => a.name);

                    return event;
                });

                // Filtrage multi-critères
                const filtersToApply = [
                    {filterKey: 'statuses', itemKey: 'customer', itemSubKey: 'status'},
                    {filterKey: 'workshops', itemKey: 'customer', itemSubKey: 'workshopId'},
                    {filterKey: 'customer', itemKey: 'customer', itemSubKey: 'name'},
                    {filterKey: 'date', itemKey: 'date', isDate:true},
                    {filterKey: 'users', itemKey: 'usersIds'},
                    {filterKey: 'attachment', itemKey: 'attachmentsNames', searchInsideWordsAndIgnoreSpaces:true},
                    {filterKey: 'type', itemKey: 'type'},
                    {filterKey: 'description', itemKey: 'description'}
                ];
                items = filterItems({
                    items: items,
                    filtersValues: this.settings.filters,
                    filtersToApply: filtersToApply
                });

                // Tri par date DESC, puis id DESC
                if (this.settings.sortBy == 'date') {
                    items.sort( (e1, e2) => {
                        const cmpByDate = -strcmp(e1.date, e2.date);
                        return cmpByDate != 0 ? cmpByDate : (e1.id < e2.id ? -1 : 1);
                    });
                }
                // Tri par client ASC, puis date DESC, puis id DESC
                else if (this.settings.sortBy == 'customer') {
                    items.sort( (e1, e2) => {
                        const cmpByCustomer = strcmp(e1.customer.name, e2.customer.name);
                        const cmpByDate = -strcmp(e1.date, e2.date);
                        return cmpByCustomer != 0 ? cmpByCustomer : (cmpByDate != 0 ? cmpByDate : (e1.id < e2.id ? -1 : 1));
                    });
                }

                return items;
            }
        },

        methods: {
            ...mapActions('pages', ['addPageCustomers']),

            openCustomer (customer) {
                this.addPageCustomers({
                    filters: {
                        statuses: [customer.status],
                        name: customer.name
                    }
                });
            },

            // Extraction Excel
            generateCSV () {
                let columns = [
                    {label:'N°', itemKey:'id'},
                    {label:'Statut client', itemKey:'statusTxt'},
                    {label:'Atelier client', itemKey:'workshopTxt'},
                    {label:'Nom du client', itemKey:'customerName'},
                    {label:'Date', itemKey:'date'},
                    {label:"Type d'évènement", itemKey:'type'},
                    {label:"Fichiers joints", itemKey:'attachmentsNamesTxt'},
                    {label:'Description', itemKey:'description'},
                    ...this.usersOptions.map(u => {return {label:u.label, itemKey:'user_' + u.value};})
                ];

                const items = [ ...this.realCheckedItems ].map( item => {
                    item.statusTxt = this.customerStatusTxtById[ item.customer.status ];
                    item.workshopTxt = this.workshopNameById[ item.customer.workshopId ];
                    item.customerName = item.customer.name;
                    item.attachmentsNamesTxt = item.attachmentsNames ? item.attachmentsNames.join(', ') : '';
                    this.usersOptions.map(u => {
                        item['user_' + u.value] = item.usersIds.includes(u.value) ? 'X' : '';
                    });
                    return item;
                });

                generateCSV(this.$store, columns, items, 'CRM_evenements');
            }
        },

        watch: {
            highlightedId (newHighlightedId) {
                scrollToDOMelement(this.$el, 'tr.highlight');
            }
        }
    };
</script>
