<template>
    <div class="page etabs-page">

        <etabs-toolbar
            :pageIndex = "pageIndex"
            :items = "items"
            :filteredAndSortedItems = "filteredAndSortedItems"
            :realCheckedItems = "realCheckedItems"
            @generateCSV="generateCSV()"
            />

        <div class="page-body">
            <div class="crm-table page-overflow-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th class="check-all" @click="switchCheckAll()">
                                <img src="@/assets/img/checkbox-all.svg" width="22" height="22" />
                            </th>
                            <th style="width:132px;">
                                <crm-table-filter type='select' label="Atelier" filterKey="workshops" :pageIndex="pageIndex" :options="workshopsOptions" />
                            </th>
                            <th style="width:120px;">
                                <crm-table-filter type='select' label="Statut" filterKey="statuses" :pageIndex="pageIndex" :options="statusesOptions" />
                            </th>
                            <th>
                                <crm-table-filter label="Client / Prospect" filterKey="customer" :pageIndex="pageIndex" />
                            </th>
                            <th>
                                <crm-table-filter label="Point de livraison" filterKey="name" :pageIndex="pageIndex" />
                            </th>
                            <th>
                                <crm-table-filter label="Adresse" filterKey="address" :pageIndex="pageIndex" />
                            </th>
                            <th style="width:200px;">
                                <crm-table-filter type='select' label="Typologies" filterKey="typologies" :pageIndex="pageIndex" :options="typologiesOptions" />
                            </th>
                            <th style="width:82px;">
                                Contacts
                            </th>
                            <th>
                                <crm-table-filter label="Etablissement dans Trokool" filterKey="trokoolEtab" :pageIndex="pageIndex" />
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="etab in filteredAndSortedItems"
                            :class="{highlight: etab.id == highlightedId}"
                            @click="$refs.etab_popup.addOrEditEtab(etab.id)"
                            >
                            <td class="check" @click.stop="switchCheck(etab.id)">
                                <img v-if="checkedIds.includes(etab.id)" src="@/assets/img/checkbox-checked.svg" width="24" height="24" />
                                <img v-else src="@/assets/img/checkbox-unchecked.svg" width="24" height="24" />
                            </td>
                            <td>
                                {{ workshopNameById[ etab.customer.workshopId ] }}
                            </td>
                            <td>
                                {{ customerStatusTxtById[ etab.customer.status ] }}
                            </td>
                            <td class="strong allow-overflow relative icon-on-right">
                                {{ etab.customer.name }}

                                <div class="list-of-details">
                                    <tip-icon :active="true" @click="openCustomer(etab.customer)">
                                        <template v-slot:icon>
                                            <img src="@/assets/img/icon-external-link.svg" alt="" width="14" height="14" />
                                        </template>
                                        <template v-slot:tip>
                                            <div class="info">
                                                clic : OUVRIR {{ etab.customer.status.substr(-6) == 'CLIENT' ? 'client' : 'prospect'}}
                                            </div>
                                        </template>
                                    </tip-icon>
                                </div>
                            </td>
                            <td class="main">
                                {{ etab.name }}
                            </td>
                            <td class="allow-overflow relative icon-on-right">
                                <div v-html="etab.fullAddress">
                                </div>
                                <div class="list-of-details">
                                    <tip-icon :active="true" @click="openMap(etab)">
                                        <template v-slot:icon>
                                            <img src="@/assets/img/icon-address.svg" alt="" width="18" height="18" />
                                        </template>
                                        <template v-slot:tip>
                                            <div class="info">
                                                clic : OUVRIR GOOGLE MAPS
                                            </div>
                                        </template>
                                    </tip-icon>
                                </div>
                            </td>
                            <td>
                                <etab-typologies :selectedTypologies="etab.typologies" />
                            </td>
                            <td class="allow-overflow">
                                <etab-contacts
                                    :contacts="etab.contacts"
                                    :etab="etab"
                                    class="justify-content-center"
                                    />
                            </td>
                            <td v-html="etab.trokoolEtabHtml">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <etab-popup ref="etab_popup" />
    </div>
</template>

<script>
    import {mapState, mapGetters, mapActions} from 'vuex';
    import {filterItems, strcmp, scrollToDOMelement, generateCSV} from '@/helpers.js';
    import {getPrettyName} from '@/helpersForCrm.js';
    import CrmTableCheckboxesMixin from '@/mixins/crmTableCheckboxesMixin.js';
    import TipIcon from '@/components/Global/TipIcon.vue';
    import CrmTableFilter from '@/components/Global/CrmTableFilter.vue';
    import EtabTypologies from '@/components/Pages/Etabs/EtabTypologies.vue';
    import EtabContacts from '@/components/Pages/Etabs/EtabContacts.vue';
    import EtabPopup from '@/components/Pages/Etabs/EtabPopup.vue';
    import EtabsToolbar from '@/components/Pages/Etabs/EtabsToolbar.vue';
    export default {
        name: 'Etabs',
        components: {EtabsToolbar, CrmTableFilter, EtabTypologies, EtabContacts, EtabPopup, TipIcon},
        mixins: [CrmTableCheckboxesMixin],

        props: ['pageIndex'],

        computed: {
            ...mapState('settings', {typologiesOptions:'typologiesOptions', statusesOptions: 'customersStatusesOptions'}),
            ...mapState('pages', ['pages']),
            ...mapState('etabs', {items: 'etabs', highlightedId:'highlightedId'}),
            ...mapGetters('settings', ['trokoolEtabById', 'workshopsOptions', 'workshopNameById', 'customerStatusTxtById']),
            ...mapGetters('pages', ['isActiveFilter']),
            ...mapGetters('customers', ['customerById']),
            ...mapGetters('contacts', ['contactsByCustomerId']),

            settings () {
                return this.pages[this.pageIndex].settings;
            },

            filteredAndSortedItems () {
                // Pour forcer mise à jour de la valeur computed
                this.pages;

                // Enrichissement de champs croisés
                let items = this.items.map(e => {
                    const etab = { ...e };

                    // Client lié
                    etab.customer = this.customerById[etab.customerId];

                    // Contacts liés : ceux du client, sauf ceux attribués spécifiquement à un autre étab
                    etab.contacts = this.contactsByCustomerId[etab.customerId];
                    if (etab.contacts === undefined) {
                        etab.contacts = [];
                    }
                    etab.contacts = etab.contacts.filter(c => c.etabId == 0 || c.etabId == etab.id);
                    etab.contactsForFiltering = etab.contacts.map(c => getPrettyName(c)).join(' ');

                    // Adresse complète
                    etab.fullAddress = etab.address + (etab.address ? '<br/> ' : '') + etab.city

                    // Etab associé dans Trokool
                    etab.trokoolEtab = etab.trokoolEtabId > 0 ? this.trokoolEtabById[ etab.trokoolEtabId ] : false;
                    if (etab.trokoolEtab) {
                        etab.trokoolEtabHtml = etab.trokoolEtab.trokoolName + ' / ' + etab.trokoolEtab.reference + '<br/> ' + etab.trokoolEtab.name;
                    } else {
                        etab.trokoolEtabHtml = '';
                    }
                    return etab;
                });

                // Filtrage multi-critères
                const filtersToApply = [
                    {filterKey: 'statuses', itemKey: 'customer', itemSubKey: 'status'},
                    {filterKey: 'workshops', itemKey: 'customer', itemSubKey: 'workshopId'},
                    {filterKey: 'customer', itemKey: 'customer', itemSubKey: 'name'},
                    {filterKey: 'name', itemKey: 'name'},
                    {filterKey: 'address', itemKey: 'fullAddress'},
                    {filterKey: 'trokoolEtab', itemKey: 'trokoolEtabHtml'}
                ];
                if (this.isActiveFilter(this.pageIndex, 'typologies')) {
                    filtersToApply.push({filterKey: 'typologies', itemKey: 'typologies'});
                }
                items = filterItems({
                    items: items,
                    filtersValues: this.settings.filters,
                    filtersToApply: filtersToApply
                });

                // Tri par nom
                if (this.settings.sortBy == 'name') {
                    items.sort( (e1, e2) => strcmp(e1.name, e2.name) );
                }
                // Tri par client, puis nom
                if (this.settings.sortBy == 'customer') {
                    items.sort( (e1, e2) => {
                        const cmpByCustomerName = strcmp(e1.customer.name, e2.customer.name);
                        return cmpByCustomerName != 0 ? cmpByCustomerName : strcmp(e1.name, e2.name);
                    });
                }

                return items;
            }
        },

        methods: {
            ...mapActions('pages', ['addPageCustomers']),

            openCustomer (customer) {
                this.addPageCustomers({
                    filters: {
                        statuses: [customer.status],
                        name: customer.name
                    }
                });
            },

            openMap (etab) {
                const address = [etab.address, etab.city]
                    .map(x => x.trim())
                    .filter(x => x.length > 0)
                    .join(' ');
                const url = 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address);
                window.open(url);
            },

            // Extraction Excel
            generateCSV () {
                let columns = [
                    {label:'N°', itemKey:'id'},
                    {label:'Statut client', itemKey:'statusTxt'},
                    {label:'Atelier client', itemKey:'workshopTxt'},
                    {label:'Nom du client', itemKey:'customerName'},
                    {label:'Nom du point de livraison', itemKey:'name'},
                    {label:'Adresse', itemKey:'addressTxt'},
                    ...this.typologiesOptions.map( typo => {
                        return {label:typo.label, itemKey:typo.value};
                    }),
                    {label:'Etab. Trokool', itemKey:'trokoolEtabTxt'}
                ];

                const items = [ ...this.realCheckedItems ].map( item => {
                    item.statusTxt = this.customerStatusTxtById[ item.customer.status ];
                    item.workshopTxt = this.workshopNameById[ item.customer.workshopId ];
                    item.customerName = item.customer.name;
                    item.addressTxt = [item.address, item.city].join(' ').trim();
                    this.typologiesOptions.forEach( typo => {
                        item[typo.value] = item.typologies.includes(typo.value) ? typo.value : '';
                    });
                    item.trokoolEtabTxt = item.trokoolEtab ? (item.trokoolEtab.trokoolName + ' / ' + item.trokoolEtab.reference) : '';
                    return item;
                });

                generateCSV(this.$store, columns, items, 'CRM_points_livr');
            }
        },

        watch: {
            highlightedId (newHighlightedId) {
                scrollToDOMelement(this.$el, 'tr.highlight');
            }
        }
    };
</script>
