<template>
    <div class="contact-tags-preview list-of-details justify-content-center">

        <div :class="{active: contact.tagsIds.length > 0}"
             @mouseover="contact.tagsIds.length > 0 ? showOverview = true : false"
             @mouseleave="showOverview = false"
             >
            <img src="@/assets/img/icon-tag.svg" alt="" width="18" height="18" />
            <span class="counter">
                {{ contact.tagsIds.length }}
            </span>
            <div class="overview" v-if="showOverview">
                <div v-for="tagId in contact.tagsIds" class="no-wrap">
                    {{ contactsTagsById[ tagId ] }}
                </div>
                <div class="info">
                    Cliquer pour MODIFIER
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    name: 'ContactTagsPreview',

    props: ['contact'],

    data () {
        return {
            showOverview: false
        };
    },

    computed: {
        ...mapGetters('contacts', {contactsTagsById: 'tagsById'})
    }
};
</script>
