<template>
    <div class="tasks-toolbar page-toolbar">

        <div class="link bg-green" @click="add()">
            <img src="@/assets/img/menu-icon-plus.svg" />
            Nouvelle tâche
        </div>

        <div>
            <form-select-field
                :value="settings.sortBy"
                @input="(newValue) => updatePageSetting({pageIndex, key:'sortBy', value:newValue})"
                class="theme-light"
                :options="[{value:'date', label:'Trier par date'}, {value:'customer', label:'Trier par client'}]"
                />
        </div>

        <div class="group">
            <div class="txt">
                Lignes : {{ filteredAndSortedItems.length }} / {{ items.length }}
            </div>
        </div>

        <div v-if="realCheckedItems.length > 0" class="group">
            <div class="txt">
                Pour la sélection ({{ realCheckedItems.length }}):
            </div>
            <div class="link" @click="alert('Bientôt disponible !')">
                <img src="@/assets/img/icon-send.svg" width="18" height="18" />
                Envoyer un email
            </div>
            <div class="link" @click="$emit('generateCSV')">
                <img src="@/assets/img/icon-xls.svg" width="18" height="18" />
                Extraction Excel
            </div>
        </div>

    </div>
</template>

<script>
    import {mapState, mapMutations} from 'vuex';
    import {taskPageFiltersToTaskFields} from '@/helpersForCrm.js';
    import FormSelectField from '@/components/Lib/Forms/FormSelectField.vue';
    export default {
        name: 'TasksToolbar',
        components: {FormSelectField},

        props: ['pageIndex', 'items', 'filteredAndSortedItems', 'realCheckedItems'],

        computed:{
            ...mapState('pages', ['pages']),
            ...mapState('customers', ['customers']),

            settings () {
                return this.pages[this.pageIndex].settings;
            }
        },

        methods: {
            ...mapMutations('alert', ['alert']),
            ...mapMutations('pages', ['updatePageSetting']),

            add() {
                const presetFields = taskPageFiltersToTaskFields(this.settings.filters, this.customers);
                this.$parent.$refs.task_popup.addOrEditTask(-1, presetFields);
            }
        }
    };
</script>
