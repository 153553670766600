<template>
    <div class="customers-toolbar page-toolbar">

        <div v-if="settings.filters.statuses.includes('ACTIVE_CLIENT') || settings.filters.statuses.includes('ARCHIVED_CLIENT')"
              class="link bg-green"
              @click="$parent.$refs.customer_popup.addOrEditCustomer(-1)"
              >
            <img src="@/assets/img/menu-icon-plus.svg" />
            Nouveau client
        </div>

        <div v-if="settings.filters.statuses.includes('ACTIVE_PROSPECT') || settings.filters.statuses.includes('ARCHIVED_PROSPECT')"
              class="link bg-green"
              @click="$parent.$refs.customer_popup.addOrEditCustomer(-2)"
              >
            <img src="@/assets/img/menu-icon-plus.svg" />
            Nouveau prospect
        </div>

        <div>
            <form-select-field
                :value="settings.sortBy"
                @input="(newValue) => updatePageSetting({pageIndex, key:'sortBy', value:newValue})"
                class="theme-light"
                :options="[{value:'name', label:'Trier par nom'}, {value:'effectif', label:'Trier par effectif'}]"
                />
        </div>

        <div class="group">
            <div class="txt">
                Lignes : {{ filteredAndSortedItems.length }} / {{ items.length }}
            </div>
        </div>

        <div v-if="realCheckedItems.length > 0" class="group">
            <div class="txt">
                Pour la sélection ({{ realCheckedItems.length }}):
            </div>
            <div class="link" @click="alert('Bientôt disponible !')">
                <img src="@/assets/img/icon-send.svg" width="18" height="18" />
                Envoyer un email
            </div>
            <div class="link" @click="$emit('generateCSV')">
                <img src="@/assets/img/icon-xls.svg" width="18" height="18" />
                Extraction Excel
            </div>
        </div>

    </div>

</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex';
import FormSelectField from '@/components/Lib/Forms/FormSelectField.vue';
export default {
    name: 'CustomersToolbar',
    components: {FormSelectField},

    props: ['pageIndex', 'items', 'filteredAndSortedItems', 'realCheckedItems'],

    computed:{
        ...mapState('pages', ['pages']),

        settings () {
            return this.pages[this.pageIndex].settings;
        }
    },

    methods: {
        ...mapMutations('alert', ['alert']),
        ...mapMutations('pages', ['updatePageSetting'])
    }
};
</script>
