import Vue from 'vue';
import App from './components/App.vue';
import store from './store/store.js';

import './assets/css/common.css';
import './assets/css/loading-overlay.css';
import './assets/css/micro-popup.css';
import './assets/css/popup.css';
import './assets/css/popup-tel.css';
import './assets/css/popup-contact.css';
import './assets/css/confirm.css';
import './assets/css/alert.css';
import './assets/css/prompt.css';
import './assets/css/crm-table.css';
import './assets/css/etab-typologies.css';
import './assets/css/tip-icon.css';
import './assets/css/upload-area.css';
import './assets/css/forms.css';
import './assets/css/login.css';
import './assets/css/header.css';
import './assets/css/pages.css';
import './assets/css/page-search.css';


Vue.config.productionTip = false

new Vue({
    store,
    render: h => h(App)
}).$mount('#app')
