<template>

    <div class="event-popup">

        <popup v-if="opened"
               :width="1024"
               :buttonsOnLeft="fields.id > 0 ? [{label:'Supprimer', event:'delete', classes:'icon orange delete'}] : []"
               :buttonsOnRight="[{label:'Annuler', event:'close', classes:'icon cancel'}, {label:'Enregistrer', event:'save', classes:'green icon ok'}]"
               @close="opened = false"
               @save="saveEvent()"
               @delete="deleteEvent(true)"
               >

            <div class="flex-fields break-960">

                <form-select-field
                   v-model="fields.customerId"
                   label="Client ou Prospect"
                   :options="customersOptions"
                   :searchable="true"
                   :hasReset="true"
                   :fullWidthDropdown="true"
                   :resetValue="-1"
                   class="flex-1"
                   />

                <form-input-field
                   label="Type d'évènement"
                   v-model="fields.type"
                   suggestionsEndPoint="Main/getEventsTypesSuggestions"
                   :alwaysShowAllSuggestions="true"
                   :hasReset="true"
                   :hideResetIfEmpty="true"
                   class="flex-1"
                   />

                <form-date-field
                   label="Date de l'évènement"
                   v-model="fields.date"
                   class="flex-1"
                   />
            </div>

            <div class="flex-fields">

                <form-textarea-field
                    v-model="fields.description"
                    label="Description"
                    rows="8"
                    class="flex-4"
                    />

                <form-files-field
                    v-model="fields.attachmentsIds"
                    label="Fichiers"
                    class="flex-1"
                    />
            </div>

            <div class="flex-fields">
                <form-multi-select-field
                   v-model="fields.usersIds"
                   label="Collaborateurs présents lors de cet événement"
                   placeholderIfNone="Aucun"
                   placeholderIfAll="Tous"
                   orientation="HORIZONTAL"
                   :options="usersOptions"
                   class="flex-1"
                   />
            </div>

            <div class="errors" v-if="errors.length > 0">
                <ul>
                    <li v-for="error in errors">
                        {{ error }}
                    </li>
                </ul>
            </div>

        </popup>
    </div>
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
    import {forceSettings} from '@/helpers.js';
    import Popup from '@/components/Lib/Misc/Popup.vue';
    import FormSelectField from '@/components/Lib/Forms/FormSelectField.vue';
    import FormDateField from '@/components/Lib/Forms/FormDateField.vue';
    import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
    import FormMultiSelectField from '@/components/Lib/Forms/FormMultiSelectField.vue';
    import FormTextareaField from '@/components/Lib/Forms/FormTextareaField.vue';
    import FormFilesField from '@/components/Lib/Forms/FormFilesField.vue';
    export default {
        name: 'EventPopup',
        components: {
            Popup, FormInputField, FormDateField, FormSelectField, FormTextareaField, FormMultiSelectField, FormFilesField
        },

        data () {
            return {
                opened: false,
                fields: {},
                errors: []
            };
        },

        computed:{
            ...mapGetters('customers', ['customersOptions']),
            ...mapGetters('users', ['usersOptions'])
        },

        methods: {
            ...mapMutations('confirm', ['confirm']),
            ...mapActions('events', {getEvent: 'get', saveEventAction: 'save', deleteEventAction: 'delete'}),

            // Si création, presetFields permet de forcer l'état initial de certains champs
            addOrEditEvent (eventId, presetFields = {}) {

                this.getEvent(eventId).then( event => {
                    this.fields = event;

                    // Si nouvel event, force certains champs
                    const isNewEvent = !(this.fields.id > 0);
                    if (isNewEvent && presetFields) {
                        this.fields = forceSettings(presetFields, this.fields);
                    }

                    this.errors = [];
                    this.opened = true;
                });
            },

            saveEvent () {
                this.errors = [];
                this.saveEventAction(this.fields)
                .then( () => {this.opened = false;})
                .catch( errors => {this.errors = [ ...errors ];});
            },

            // Suppression d'un event
            deleteEvent(checkBefore) {

                // Si demandé, confirmation préalable
                if (checkBefore) {
                    this.confirm({
                        question: "Supprimer cet évènement ?",
                        btnYesLabel: "OUI, supprimer",
                        btnYesClasses: 'icon orange delete',
                        btnYesCallback: () => {
                            this.deleteEvent(false);
                        }
                    });
                } else {
                    // Suppression effective
                    this.deleteEventAction(this.fields.id).then( () => {
                        this.opened = false;
                    });
                }
            }
        }
    };
</script>
