<template>
    <div class="page empty-page">

        <div class="page-toolbar">
        </div>

        <div class="page-body">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EmptyPage'
    };
</script>
