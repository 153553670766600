<template>

    <div class="form-tel-field">

        <label v-if="label && label.length" v-html="label">
        </label>

        <div class="field-wrapper d-table fixed">

            <div class="tel-infos v-middle" v-if="telInfos.prettyType">

                <div class="d-flex flex-column">

                    <div class="tel-type">
                        {{ telInfos.prettyType }}
                    </div>

                    <div class="tel-actions d-flex justify-content-around"
                         v-if="telInfos.prettyType && telInfos.prettyType.substr(0,3).toUpperCase() != 'ERR' "
                         >
                        <a :href="'tel:' + telInfos.stdTel">
                            appel
                        </a>
                        <a :href="'sms:' + telInfos.stdTel" v-if="telInfos.prettyType.substr(0,3).toUpperCase() == 'MOB'">
                            SMS
                        </a>
                    </div>
                </div>
            </div>

            <form-input-field
                class="tel-number"
                placeholder="Numéro..."
                :value="telInfos.prettyTel"
                @input="(v) => {telFieldValue = v}"
                @blur="updateModel()"
                />

            <form-input-field
                class="tel-comment"
                placeholder="Commentaire..."
                :value="telInfos.comment"
                @input="(v) => {commentFieldValue = v}"
                @blur="updateModel()"
                />
        </div>
    </div>
</template>

<script>
    import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
    import {decodeTel, cleanAndEncodeTel} from '@/helpersForCrm.js';
    export default {
        name: 'FormTelField',
        components: {FormInputField},

        props: {
            value: {},
            label: {default: ''}
        },

        data () {
            return {
                telFieldValue: '',
                commentFieldValue: ''
            };
        },

        created () {
            // Etat initial des variables internes
            this.telFieldValue = this.telInfos.prettyTel;
            this.commentFieldValue = this.telInfos.comment;
        },

        computed: {
            telInfos () {
                return decodeTel(this.value);
            }
        },

        methods: {
            updateModel () {
                this.$emit('input', cleanAndEncodeTel(this.telFieldValue, this.commentFieldValue) );
            }
        }
    };
</script>
