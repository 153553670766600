/*
 * Gère les pages ouvertes, leurs paramètres respectifs et la page active
 */

import {callAPI, isMatchingQuery, forceSettings} from '@/helpers.js';
import {updateFilterValues} from '@/helpersForCrm.js';

export default {

    namespaced: true,

    state: {
        pages: [],
        currentPageIndex: undefined
    },

    getters: {
        // Est-ce qu'un filtre est actif pour une page ?
        isActiveFilter: (state) => (pageIndex, filterKey) => {
            if (state.pages[pageIndex].settings.activeFilters === undefined) {
                return false;
            } else {
                return state.pages[pageIndex].settings.activeFilters.includes(filterKey);
            }
        },

        // Génère le résumé textuel d'un filtre actif, pour les colonnes des crm-table, et pour les onglets du menu principal
        getPageFilterTxt: (state) => (pageIndex, filterKey) => {
            return (value) => {
                const pageType = state.pages[pageIndex].type;
                const valueTxtArray = [];

                // Par défaut, le résumé correspond auxmots-clés saisis pour le filtre
                let valueTxt = value;

                // Cas particuliers: les filtres sous forme de listes
                switch (pageType + '_' + filterKey) {

                    // Filtre STATUT d'un client
                    case 'CUSTOMERS_statuses':
                    case 'CONTACTS_statuses':
                    case 'ETABS_statuses':
                    case 'EVENTS_statuses':
                    case 'TASKS_statuses':
                        if (value.length === 0) {
                            valueTxt = 'Aucun';
                            break;
                        }
                        const values = value.map(v => v.value);
                        const isClients = values.includes('ACTIVE_CLIENT');
                        const isArchivedClients = values.includes('ARCHIVED_CLIENT');
                        const isProspects = values.includes('ACTIVE_PROSPECT');
                        const isArchivedProspects = values.includes('ARCHIVED_PROSPECT');

                        if (isClients && isArchivedClients) {
                            valueTxtArray.push('Tous clients');
                        } else if (isClients) {
                            valueTxtArray.push('Clients');
                        } else if (isArchivedClients) {
                            valueTxtArray.push('Clients archivés');
                        }
                        if (isProspects && isArchivedProspects) {
                            valueTxtArray.push('Tous prospects');
                        } else if (isProspects) {
                            valueTxtArray.push('Prospects');
                        } else if (isArchivedProspects) {
                            valueTxtArray.push('Prospects archivés');
                        }

                        valueTxt = valueTxtArray.join(' + ');
                    break;

                    // Filtre ATELIER d'un client, étab, contact, event ou tache
                    case 'CONTACTS_workshops':
                    case 'CUSTOMERS_workshops':
                    case 'ETABS_workshops':
                    case 'EVENTS_workshops':
                    case 'TASKS_workshops':
                        if (value.length === 0) {
                            valueTxt = 'Aucun';
                            break;
                        }
                        const stats = value.reduce(
                            (accumulator, workshop) => {
                                if (isMatchingQuery(workshop.label, 'Atelier')) {
                                    accumulator.ateliers.push(workshop.label);
                                } else {
                                    accumulator.cuisines.push(workshop.label);
                                }
                                return accumulator;
                            },
                            {ateliers: [], cuisines: []}
                        );

                        if (stats.ateliers.length === 1) {
                            valueTxtArray.push(stats.ateliers[0]);
                        } else if (stats.ateliers.length > 1) {
                            valueTxtArray.push(stats.ateliers.length + ' ateliers');
                        }
                        if (stats.cuisines.length === 1) {
                            valueTxtArray.push(stats.cuisines[0]);
                        } else if (stats.cuisines.length > 1) {
                            valueTxtArray.push(stats.cuisines.length + ' cuisines');
                        }
                        valueTxt = valueTxtArray.join(' + ');
                    break;

                    // Types d'événements ou de taches
                    case 'EVENTS_type':
                    case 'TASKS_type':
                        if (value.length === 0) {
                            valueTxt = 'Aucun';
                        }
                        else if (value.length === 1) {
                            valueTxt = value.map( v => v.label ).join('');
                        }
                        else {
                            valueTxt = value.length + ' types';
                        }
                    break;

                    // Collaborateurs
                    case 'EVENTS_users':
                    case 'TASKS_users':
                        if (value.length === 0) {
                            valueTxt = 'Aucun';
                        }
                        else if (value.length === 1) {
                            valueTxt = value.map( u => u.label ).join('');
                        }
                        else {
                            valueTxt = value.length + ' pers.';
                        }
                    break;

                    // Fonctions des contacts
                    case 'CONTACTS_jobs':
                        if (value.length === 0) {
                            valueTxt = 'Aucune';
                        }
                        else if (value.length === 1) {
                            valueTxt = value.map( v => v.label ).join('');
                        }
                        else {
                            valueTxt = value.length + ' fonctions';
                        }
                    break;

                    // Etiquettes des contacts
                    case 'CONTACTS_tags':
                        if (value.length === 0) {
                            valueTxt = 'Aucune';
                        }
                        else if (value.length === 1) {
                            valueTxt = value.map( v => v.label ).join('');
                        }
                        else {
                            valueTxt = value.length + ' étiquettes';
                        }
                    break;

                    // Afficher dans Trokool ?
                    case 'CONTACTS_displayInTrokool':
                        const selectedOptions = value.map(v => v.value);
                        if (selectedOptions.length === 0) {
                            valueTxt = 'Aucun';
                        }
                        else if (selectedOptions.length === 1) {
                            const filterValue = selectedOptions[0];
                            valueTxt = (filterValue === 0 || filterValue === '0') ? "Pas dans Trokool" : "Dans Trokool";
                        }
                        else {
                            valueTxt = 'Tous';
                        }
                    break;

                    // Filtre par typologies
                    case 'CUSTOMERS_typologies':
                    case 'ETABS_typologies':
                        valueTxt = value.map(t => t.value).join('+');
                    break;
                }
                return valueTxt;
            };
        }
    },

    mutations: {

        reset (state) {
            state.pages = [];
            state.currentPageIndex = undefined;
        },

        // Défini la page courante. -1 si aucune
        setCurrentPageIndex (state, index) {
            if ( !(state.pages.length > 0 && index >= 0 && index <= state.pages.length)) {
                index = undefined;
            }
            state.currentPageIndex = index;
        },

        // Ajoute une page au format {type: String, settings: Object, isCurrent: Bool}
        // Retourne l'index de la nouvelle page
        addPage (state, newPage) {
            const newPageIndex = state.pages.length;
            state.pages.push({
                type: newPage.type,
                settings: newPage.settings
            });
            if (newPage.isCurrent) {
                state.currentPageIndex = newPageIndex;
            }
            return newPageIndex;
        },

        // Supprime une page et met à jour la page courante en conséquence
        deletePage (state, index) {
            state.pages.splice(index, 1);

            if (state.pages[state.currentPageIndex] === undefined) {
                if (state.pages.length > 0) {
                    state.currentPageIndex = state.pages.length - 1;
                } else {
                    state.currentPageIndex = undefined;
                }
            }
            else if (state.currentPageIndex > index) {
                state.currentPageIndex--;
            }
        },

        // Met à jour un paramètre d'une page
        updatePageSetting(state, {pageIndex, key, value}) {
            state.pages[pageIndex].settings[key] = value.constructor === Array ? [ ...value ] : value;
            state.pages = [ ...state.pages ];
        },

        // Met à jour un sous-paramètre d'une page
        updatePageSubSetting(state, {pageIndex, key, subkey, value}) {
            state.pages[pageIndex].settings[key][subkey] = value.constructor === Array ? [ ...value ] : value;
            state.pages = [ ...state.pages ];
        },

        // Mise à jour de la liste des filtres actifs
        updatePageActiveFilter(state, {pageIndex, filterKey, active}) {
            if (state.pages[pageIndex].settings.activeFilters === undefined) {
                state.pages[pageIndex].settings.activeFilters = [];
            }
            const index = state.pages[pageIndex].settings.activeFilters.findIndex(key => key == filterKey);
            if (index === -1 && active) {
                state.pages[pageIndex].settings.activeFilters.push(filterKey);
            } else if (index !== -1 && !active) {
                state.pages[pageIndex].settings.activeFilters.splice(index, 1);
            }
            state.pages = [ ...state.pages ];
        },

        // A appeler après la mise à jour de la liste des métiers possibles pour un contact
        fixContactsPagesFilters(state, newValues) {
            for(let pageIndex in state.pages) {
                const page = state.pages[pageIndex];
                if (page.type == 'CONTACTS') {
                    const currentValuesUpdated = updateFilterValues(page.settings.filters.jobs, newValues);
                    if (currentValuesUpdated !== false) {
                        state.pages[pageIndex].settings.filters.jobs = [ ...currentValuesUpdated ];
                        state.pages = [ ...state.pages ];
                    }
                }
            }
        },

        // A appeler après la mise à jour de la liste des types de tâches possibles
        fixTasksPagesFilters(state, newValues) {
            for(let pageIndex in state.pages) {
                const page = state.pages[pageIndex];
                if (page.type == 'TASKS') {
                    const currentValuesUpdated = updateFilterValues(page.settings.filters.type, newValues);
                    if (currentValuesUpdated !== false) {
                        state.pages[pageIndex].settings.filters.type = [ ...currentValuesUpdated ];
                        state.pages = [ ...state.pages ];
                    }
                }
            }
        },

        // A appeler après la mise à jour de la liste des types d'events possibles
        fixEventsPagesFilters(state, newValues) {
            for(let pageIndex in state.pages) {
                const page = state.pages[pageIndex];
                if (page.type == 'EVENTS') {
                    const currentValuesUpdated = updateFilterValues(page.settings.filters.type, newValues);
                    if (currentValuesUpdated !== false) {
                        state.pages[pageIndex].settings.filters.type = [ ...currentValuesUpdated ];
                        state.pages = [ ...state.pages ];
                    }
                }
            }
        }
    },

    actions: {

        // Sauvegarde de l'état actuel des pages ouvertes, pour l'utilisateur courant
        saveToDatabase (context) {
            callAPI(context, 'Main/saveCurrentUserPages', {
                pages: [ ...context.state.pages ],
                currentPageIndex: context.state.currentPageIndex
            }, {
                noOverlay: true
            });
        },

        // Charge les pages ouvertes pour l'utilisateur courant
        loadFromDatabase (context) {
            return new Promise((resolve, reject) => {
                callAPI(context, 'Main/getCurrentUserPages', {}, {noOverlay: true})
                .then(response => {
                    if (response.status === true) {
                        context.commit('reset');
                        if (response.pages) {
                            for(let page of response.pages) {
                                context.commit('addPage', JSON.parse(page));
                            }
                            context.commit('setCurrentPageIndex', response.currentPageIndex);
                        }
                        resolve();
                    }
                });
            });
        },

        // Ajoute et sélectionne une page de type "Clients" avec les paramètres par défaut
        addPageCustomers (context, settingsToForce = {}) {
            const workshops = [ ...context.rootState.settings.workshops ];
            const typologiesOptions = [ ...context.rootState.settings.typologiesOptions ];
            const settings = {
                sortBy: 'name',
                filters: {
                    statuses: ['ACTIVE_CLIENT'],
                    workshops: workshops.map(w => w.id),
                    name: '',
                    city: '',
                    typologies: typologiesOptions.map(t => t.value),
                    comments: '',
                    provider: ''
                }
            };
            const newPageIndex = context.commit('addPage', {
                isCurrent: true,
                type: 'CUSTOMERS',
                settings: forceSettings(settingsToForce, settings)
            });
            return newPageIndex;
        },

        // Ajoute et sélectionne une page de type "Prospects" avec les paramètres par défaut
        addPageProspects (context, settingsToForce = {}) {
            const settings = {
                filters: {
                    statuses: ['ACTIVE_PROSPECT']
                }
            };
            return context.dispatch('addPageCustomers', forceSettings(settingsToForce, settings));
        },

        // Ajoute et sélectionne une page de type "Contacts" avec les paramètres par défaut
        addPageContacts (context, settingsToForce = {}) {
            const statusesOptions = [ ...context.rootState.settings.customersStatusesOptions ];
            const workshops = [ ...context.rootState.settings.workshops ];
            const jobs = [ ...context.rootState.contacts.jobs ];
            const tags = [ ...context.rootState.contacts.tags ];
            const settings = {
                sortBy: 'name',
                filters: {
                    statuses: statusesOptions.map(s => s.value),
                    workshops: workshops.map(w => w.id),
                    customer: '',
                    etab: '',
                    name: '',
                    jobs: jobs,
                    tags: tags.map( t => t.id ),
                    contactDatas: '',
                    comments: '',
                    displayInTrokool: [0, 1]
                }
            };
            const newPageIndex = context.commit('addPage', {
                isCurrent: true,
                type: 'CONTACTS',
                settings: forceSettings(settingsToForce, settings)
            });
            return newPageIndex;
        },

        // Ajoute et sélectionne une page de type "Lieux de livraisons" avec les paramètres par défaut
        addPageEtabs (context, settingsToForce = {}) {
            const workshops = [ ...context.rootState.settings.workshops ];
            const typologiesOptions = [ ...context.rootState.settings.typologiesOptions ];
            const settings = {
                sortBy: 'name',
                filters: {
                    statuses: ['ACTIVE_CLIENT'],
                    workshops: workshops.map(w => w.id),
                    customer: '',
                    name: '',
                    address: '',
                    typologies: typologiesOptions.map(t => t.value),
                    trokoolEtab: ''
                }
            };
            const newPageIndex = context.commit('addPage', {
                isCurrent: true,
                type: 'ETABS',
                settings: forceSettings(settingsToForce, settings)
            });
            return newPageIndex;
        },

        // Ajoute et sélectionne une page de type "Evenements" avec les paramètres par défaut
        addPageEvents (context, settingsToForce = {}) {
            const statusesOptions = [ ...context.rootState.settings.customersStatusesOptions ];
            const workshops = [ ...context.rootState.settings.workshops ];
            const types = [ ...context.rootState.events.types ];
            const users = [ ...context.rootState.users.users ];
            const settings = {
                sortBy: 'date',
                filters: {
                    statuses: statusesOptions.map(s => s.value),
                    workshops: workshops.map(w => w.id),
                    customer: '',
                    date:'',
                    type:types,
                    users:users.map(u => u.id),
                    attachment:'',
                    description: ''
                }
            };
            const newPageIndex = context.commit('addPage', {
                isCurrent: true,
                type: 'EVENTS',
                settings: forceSettings(settingsToForce, settings)
            });
            return newPageIndex;
        },

        // Ajoute et sélectionne une page de type "Tâches" avec les paramètres par défaut
        addPageTasks (context, settingsToForce = {}) {
            const statusesOptions = [ ...context.rootState.settings.customersStatusesOptions ];
            const workshops = [ ...context.rootState.settings.workshops ];
            const types = [ ...context.rootState.tasks.types ];
            const session = { ...context.rootState.session.session };
            const settings = {
                sortBy: 'date',
                filters: {
                    statuses: statusesOptions.map(s => s.value),
                    workshops: workshops.map(w => w.id),
                    customer: '',
                    date:'',
                    type:types,
                    users:session.userId,
                    description: ''
                }
            };
            const newPageIndex = context.commit('addPage', {
                isCurrent: true,
                type: 'TASKS',
                settings: forceSettings(settingsToForce, settings)
            });
            return newPageIndex;
        },

        // Ajoute et sélectionne une page de type "Emails" avec les paramètres par défaut
        addPageMails (context) {
            const newPageIndex = context.commit('addPage', {
                isCurrent: true,
                type: 'MAILS',
                settings: {}
            });
            return newPageIndex;
        },

        // Ajoute et sélectionne une page de type "Recherche" avec les paramètres par défaut
        addPageSearch (context) {
            const newPageIndex = context.commit('addPage', {
                isCurrent: true,
                type: 'SEARCH',
                settings: {
                    query:''
                }
            });
            return newPageIndex;
        },

        // Ajoute et sélectionne une page de type "Configuration" avec les paramètres par défaut
        addPageSettings (context) {
            const settingsPageIndex = context.state.pages.findIndex(page => page.type == 'SETTINGS');
            let newPageIndex;

            if (settingsPageIndex >= 0) {
                newPageIndex = settingsPageIndex;
                context.commit('setCurrentPageIndex', settingsPageIndex);
            }
            else {
                newPageIndex = context.commit('addPage', {
                    isCurrent: true,
                    type: 'SETTINGS',
                    settings: {}
                });
            }
            return newPageIndex;
        }
    }
};
