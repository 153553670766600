<template>
    <div class="contact-details" @click.stop="">

        <div class="list-of-details">

            <tip-icon :active="telsActive" :counter="telsCounter" @click="telsPopupVisible = true">
                <template v-slot:icon>
                    <img src="@/assets/img/icon-phone.svg" alt="" width="18" height="18" />
                </template>
                <template v-slot:tip>
                    <div v-for="tel in activeTelsInfos" class="no-wrap">
                        <div class="line-1 no-wrap">
                            {{ tel.prettyTel }}
                        </div>
                        <div class="line-2">
                            {{ tel.prettyType }}
                        </div>
                        <div class="line-3" v-if="tel.comment != ''">
                            {{ tel.comment }}
                        </div>
                    </div>
                    <div class="info">
                        clic : APPEL ou SMS
                    </div>
                </template>
            </tip-icon>

            <tip-icon :active="emailActive" @click="mailto(contact.email)">
                <template v-slot:icon>
                    <img src="@/assets/img/icon-email.svg" alt="" width="18" height="18" />
                </template>
                <template v-slot:tip>
                    <div class="no-wrap">
                        {{ contact.email }}
                    </div>
                    <div class="info">
                        clic : ECRIRE
                    </div>
                </template>
            </tip-icon>

            <tip-icon :active="addressActive" @click="openMap(contact)">
                <template v-slot:icon>
                    <img src="@/assets/img/icon-address.svg" alt="" width="18" height="18" />
                </template>
                <template v-slot:tip>
                    <div class="no-wrap" v-html="contact.fullAddress">
                    </div>
                    <div class="info">
                        clic : OUVRIR GOOGLE MAPS
                    </div>
                </template>
            </tip-icon>
        </div>

        <popup v-if="telsPopupVisible"
               :width="480"
               :buttonsOnRight="[{label:'Fermer', event:'close', classes:''}]"
               @close="telsPopupVisible = false"
               class="popup-tel"
               >

           <div v-for="tel in activeTelsInfos" class="tel-item">

               <div class="d-flex align-items-center">
                   <div class="tel-number">
                       {{ tel.prettyTel }}
                   </div>
                   <a :href="'tel:' + tel.stdTel" class="form-button small green">
                       APPEL
                   </a>
                   <a :href="'sms:' + tel.stdTel" v-if="tel.prettyType.substr(0,3).toUpperCase() == 'MOB'" class="form-button small green">
                       SMS
                   </a>
               </div>

               <div class="tel-comments">
                   {{ tel.prettyType }}
                   <span v-if="tel.comment.trim().length > 0">
                       {{ ' : ' + tel.comment }}
                   </span>
               </div>
           </div>
        </popup>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';
import {decodeTel} from '@/helpersForCrm.js';
import TipIcon from '@/components/Global/TipIcon.vue';
import Popup from '@/components/Lib/Misc/Popup.vue';
export default {
    name: 'ContactDetails',
    components: {TipIcon, Popup},

    props: ['contact'],

    data () {
        return {
            telsPopupVisible: false
        };
    },

    computed: {
        telsCounter () {
            const nbTels =
                (this.contact.tel1.length > 1 ? 1 : 0) +
                (this.contact.tel2.length > 1 ? 1 : 0) +
                (this.contact.tel3.length > 1 ? 1 : 0);
            return nbTels;
        },
        telsActive () {
            return this.telsCounter > 0;
        },
        activeTelsInfos () {
            const tels = [
                decodeTel(this.contact.tel1),
                decodeTel(this.contact.tel2),
                decodeTel(this.contact.tel3)
            ].filter(t => t.stdTel != '');
            return tels;
        },

        emailActive () {
            return this.contact.email.length > 3;
        },

        addressActive () {
            return this.contact.fullAddress.length > 0;
        }
    },

    methods: {
        ...mapMutations('alert', ['alert']),

        // Ouvre Google Maps dans un nouvel onglet
        openMap (contact) {
            const address = [contact.address1, contact.address2, contact.address3, contact.city]
                .map(x => x.trim())
                .filter(x => x.length > 0)
                .join(' ');
            const url = 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address);
            window.open(url);
        },

        // Ouvre Outlook pour rédiger un email
        mailto (email) {
            window.open('mailto:' + email);
        }
    }
};
</script>
