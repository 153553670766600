<template>

    <div class="task-popup">

        <popup v-if="opened"
               :width="1024"
               :buttonsOnLeft="fields.id > 0 ? [{label:'Supprimer', event:'delete', classes:'icon orange delete'}] : []"
               :buttonsOnRight="[{label:'Annuler', event:'close', classes:'icon cancel'}, {label:'Enregistrer', event:'save', classes:'green icon ok'}]"
               @close="opened = false"
               @save="saveTask()"
               @delete="deleteTask(true)"
               >

            <div class="flex-fields">

                <form-select-field
                   label="Dépendance"
                   :fullWidthDropdown="true"
                   :options="linkedToCustomerOptions"
                   :value="isLinkedToCustomer"
                   @input="(v) => {setIsLinkedToCustomer(v);}"
                   class="flex-2"
                   />

                <div class="flex-2">
                    <form-select-field
                       v-show="isLinkedToCustomer == 1"
                       v-model="fields.customerId"
                       label="Client ou Prospect"
                       :options="customersOptions"
                       :searchable="true"
                       :hasReset="true"
                       :fullWidthDropdown="true"
                       :resetValue="-1"
                       ref="customerSelector"
                       class="flex-2"
                       />
                </div>

                <div class="flex-1"></div>
            </div>

            <div class="flex-fields break-960">

                <form-input-field
                   label="Type de tâche"
                   v-model="fields.type"
                   suggestionsEndPoint="Main/getTasksTypesSuggestions"
                   :alwaysShowAllSuggestions="true"
                   :hasReset="true"
                   :hideResetIfEmpty="true"
                   class="flex-1"
                   />

                <form-date-field
                   label="Date limite de réalisation"
                   v-model="fields.date"
                   class="flex-1"
                   />

                <div class="flex-1 d-flex flex-column justify-content-end">
                    <div>
                        <span class="date-shortcut" @click="setDate(0)">aujourd'hui</span>
                        <span class="date-shortcut" @click="setDate(1)">demain</span>
                        <span class="date-shortcut" @click="setDate(2)">dans 2 jours</span>
                    </div>
                    <div>
                        <span class="date-shortcut" @click="setDate(7)">dans 1 semaine</span>
                        <span class="date-shortcut" @click="setDate(14)">dans 2 semaine</span>
                        <span class="date-shortcut" @click="setDate(30)">dans 1 mois</span>
                    </div>
                </div>
            </div>

            <div class="flex-fields">
                <form-textarea-field
                    v-model="fields.description"
                    label="Description"
                    rows="5"
                    class="flex-1"
                    />
            </div>

            <div class="flex-fields">
                <form-multi-select-field
                   v-model="fields.usersIds"
                   label="Collaborateurs concernés par cette tâche"
                   placeholderIfNone="Aucun"
                   placeholderIfAll="Tous"
                   orientation="HORIZONTAL"
                   :options="usersOptions"
                   class="flex-1"
                   />
            </div>

            <div class="errors" v-if="errors.length > 0">
                <ul>
                    <li v-for="error in errors" v-html="error">
                    </li>
                </ul>
            </div>

        </popup>
    </div>
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
    import {forceSettings, dateToYmd} from '@/helpers.js';
    import Popup from '@/components/Lib/Misc/Popup.vue';
    import FormSelectField from '@/components/Lib/Forms/FormSelectField.vue';
    import FormDateField from '@/components/Lib/Forms/FormDateField.vue';
    import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
    import FormMultiSelectField from '@/components/Lib/Forms/FormMultiSelectField.vue';
    import FormTextareaField from '@/components/Lib/Forms/FormTextareaField.vue';
    import FormFilesField from '@/components/Lib/Forms/FormFilesField.vue';
    export default {
        name: 'TaskPopup',
        components: {
            Popup, FormInputField, FormDateField, FormSelectField, FormTextareaField, FormMultiSelectField, FormFilesField
        },

        data () {
            return {
                opened: false,
                fields: {},
                isLinkedToCustomer: true,
                linkedToCustomerOptions: [
                    {value:1, label:"Tâche liée à un client ou un prospect"},
                    {value:0, label:"Autre tâche"}
                ],
                errors: []
            };
        },

        computed:{
            ...mapGetters('customers', ['customersOptions']),
            ...mapGetters('users', ['usersOptions'])
        },

        methods: {
            ...mapMutations('confirm', ['confirm']),
            ...mapActions('tasks', {getTask: 'get', saveTaskAction: 'save', deleteTaskAction: 'delete'}),

            // Si création, presetFields permet de forcer l'état initial de certains champs
            addOrEditTask (taskId, presetFields = {}) {

                this.getTask(taskId).then( task => {
                    this.fields = task;

                    // Si nouvel task, force certains champs
                    const isNewTask = !(this.fields.id > 0);
                    if (isNewTask && presetFields) {
                        this.fields = forceSettings(presetFields, this.fields);
                    }

                    // Type de contact : "Lié à un client ou prospect" si customerId existe, ou si nouveau contact
                    this.isLinkedToCustomer = isNewTask ? 1 : (this.fields.customerId > 0 ? 1 : 0);

                    this.errors = [];
                    this.opened = true;
                });
            },

            // Event : changement de la Dépendance ou non avec un client
            setIsLinkedToCustomer (newValue) {

                // Mise à jour du sélecteur lui-même
                this.isLinkedToCustomer = newValue;

                // Si "autre tâche" : reset du lien avec le client ou prospect
                if (newValue == 0) {
                    this.fields.customerId = -1;
                }
                // Si "Lié à client ou prospect" : ouverture du sélecteur en question
                else {
                    setTimeout( () => {
                        this.$refs.customerSelector.openSelector();
                    }, 100);
                }
            },

            // Raccourci pour fixer une date
            setDate (deltaDays) {
                const d = new Date();
                d.setDate(d.getDate() + deltaDays);
                this.fields.date = dateToYmd(d);
            },

            saveTask () {
                if (this.isLinkedToCustomer && this.fields.customerId == -1) {
                    this.errors = ["Sélectionnez le client/prospect en rapport avec cette tâche <u>OU</u> Dépendance = \"Autre tâche\"."];
                    return;
                }

                this.errors = [];
                this.saveTaskAction(this.fields)
                .then( () => {this.opened = false;})
                .catch( errors => {this.errors = [ ...errors ];});
            },

            // Suppression d'un task
            deleteTask(checkBefore) {

                // Si demandé, confirmation préalable
                if (checkBefore) {
                    this.confirm({
                        question: "Supprimer cette tâche ?",
                        btnYesLabel: "OUI, supprimer",
                        btnYesClasses: 'icon orange delete',
                        btnYesCallback: () => {
                            this.deleteTask(false);
                        }
                    });
                } else {
                    // Suppression effective
                    this.deleteTaskAction(this.fields.id).then( () => {
                        this.opened = false;
                    });
                }
            }
        }
    };
</script>
