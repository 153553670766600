<template>

    <div class="etab-popup">

        <popup v-if="opened"
               :width="960"
               :buttonsOnLeft="fields.id > 0 ? [{label:'Supprimer', event:'delete', classes:'icon orange delete'}] : []"
               :buttonsOnRight="[{label:'Annuler', event:'close', classes:'icon cancel'}, {label:'Enregistrer', event:'save', classes:'green icon ok'}]"
               @close="opened = false"
               @save="saveEtab()"
               @delete="deleteEtab(true)"
               >

            <div class="flex-fields break-768">

                <form-select-field
                   v-model="fields.customerId"
                   label="Client ou Prospect"
                   :options="customersOptions"
                   :searchable="true"
                   :hasReset="true"
                   :fullWidthDropdown="true"
                   :resetValue="-1"
                   class="flex-3"
                   />

                <div class="flex-4"></div>
            </div>


            <div class="flex-fields break-768">

                <form-input-field
                    v-model="fields.name"
                    label="Nom du point de livraison"
                    :hasReset="true"
                    :hideResetIfEmpty="true"
                    class="flex-3"
                    />

                <form-select-field
                    v-model="fields.trokoolEtabId"
                    label="Etablissement dans Trokool"
                    :options="trokoolsEtabsOptions"
                    :searchable="true"
                    :hasReset="true"
                    :resetValue="-1"
                    :fullWidthDropdown="true"
                    class="flex-4"
                    />
            </div>

            <div class="flex-fields break-768">

                <form-input-field
                    v-model="fields.address"
                    label="Adresse"
                    :hasReset="true"
                    :hideResetIfEmpty="true"
                    class="flex-4"
                    />

                <form-input-field
                    v-model="fields.city"
                    label="Code postal + Ville"
                    :uppercase="true"
                    suggestionsEndPoint="Main/getCitySuggestions"
                    :hasReset="true"
                    :hideResetIfEmpty="true"
                    class="flex-3"
                    />
            </div>

            <div class="flex-fields">
                <form-multi-select-field
                   v-model="fields.typologies"
                   label="Typologies"
                   placeholderIfNone="Aucune"
                   placeholderIfAll="Toutes"
                   orientation="HORIZONTAL"
                   :options="typologiesOptions"
                   class="flex-1"
                   />
            </div>

            <div class="errors" v-if="errors.length > 0">
                <ul>
                    <li v-for="error in errors">
                        {{ error }}
                    </li>
                </ul>
            </div>

        </popup>
    </div>
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
    import {forceSettings} from '@/helpers.js';
    import Popup from '@/components/Lib/Misc/Popup.vue';
    import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
    import FormSelectField from '@/components/Lib/Forms/FormSelectField.vue';
    import FormMultiSelectField from '@/components/Lib/Forms/FormMultiSelectField.vue';
    export default {
        name: 'EtabPopup',
        components: {Popup, FormInputField, FormSelectField, FormMultiSelectField},

        data () {
            return {
                opened: false,
                fields: {},
                errors: []
            };
        },

        computed:{
            ...mapState('settings', ['typologiesOptions']),
            ...mapGetters('settings', ['trokoolsEtabsOptions']),
            ...mapGetters('customers', ['customersOptions'])
        },

        methods: {
            ...mapMutations('confirm', ['confirm']),
            ...mapMutations('alert', ['alert']),
            ...mapActions('etabs', {getEtab: 'get', saveEtabAction: 'save', deleteEtabAction: 'delete'}),

            // Si création, presetFields permet de forcer l'état initial de certains champs
            addOrEditEtab (etabId, presetFields = {}) {
                this.getEtab(etabId).then( etab => {
                    this.fields = etab;

                    // Si nouvel étab, force certains champs
                    const isNewEtab = !(this.fields.id > 0);
                    if (isNewEtab && presetFields) {
                        this.fields = forceSettings(presetFields, this.fields);
                    }

                    this.errors = [];
                    this.opened = true;
                });
            },

            saveEtab () {
                this.errors = [];
                this.saveEtabAction(this.fields)
                .then( () => {this.opened = false;})
                .catch( errors => {this.errors = [ ...errors ];});
            },

            // Suppression d'un étab
            deleteEtab(checkBefore) {

                // Si demandé, confirmation préalable
                if (checkBefore) {
                    this.confirm({
                        question: "Supprimer ce point de livraison ?",
                        btnYesLabel: "OUI, supprimer",
                        btnYesClasses: 'icon orange delete',
                        btnYesCallback: () => {
                            this.deleteEtab(false);
                        }
                    });
                } else {
                    // Suppression effective
                    this.deleteEtabAction(this.fields.id).then( () => {
                        this.opened = false;
                    });
                }
            }
        }
    };
</script>
