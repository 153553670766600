<template>
    <div class="micro-popup"
         :class="{opened, 'full-width-dropdown':fullWidthDropdown}"
         tabindex="0"
         ref="micropopup"
         @keyup.esc.stop="close()"
         @keyup.enter.stop="close()"
         >

        <div class="btn" :class="btnClass" @click.stop="switchOpening()">
            <slot name="btn"></slot>
        </div>

        <transition>
            <div v-if="opened"
                 class="content-wrapper d-flex"
                 :class="{'flex-column': orientation == 'VERTICAL', 'horizontal': orientation != 'VERTICAL', 'position-right': position == 'RIGHT'}"
                 >

                <div class="content">
                    <slot name="content" :close="close"></slot>
                </div>

                <div class="close" @click.stop="close()">

                    <div class="check-all-btn"
                         v-if="hasCheckAll"
                         @click.stop="$emit('switchCheckAll')"
                         >
                         <img src="@/assets/img/checkbox-all-black.svg" width="20" height="20" />
                    </div>

                    <div class="close-btn">
                        Fermer
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import jQuery from "jquery";
export default {
    name: 'MicroPopup',
    props: {
        btnClass: {default:''},
        hasCheckAll: {default:false},
        orientation: {default:'VERTICAL'},
        position: {default:'LEFT'},
        fullWidthDropdown: {default:false}
    },
    data () {
        return {
            opened: false
        };
    },
    methods: {
        open () {
            // Avant d'ouvrir la micro-popup, on ferme les autres éventuellement ouvertes
            if ( !this.opened) {
                const $ = jQuery;
                $('.micro-popup.opened .close-btn').trigger('click');
            }
            this.opened = true;
            this.$emit('open');
            return true;
        },
        close () {
            this.opened = false;
            this.$emit('close');
            return true;
        },
        switchOpening () {
            return this.opened ? this.close() : this.open();
        }
    },
    mounted () {
        this.$refs.micropopup.focus();
    }
};
</script>
