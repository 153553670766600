
import {mapGetters} from 'vuex';

export default {

    computed: {
        ...mapGetters(['apiBaseUrl'])
    },

    methods: {

        // Prépare une liste de fichiers à partir de leur ID
        hydrateAttachments (filesIds, attachmentsById) {
            if ( !filesIds) {
                return [];
            }
            return filesIds.map( (fileId) => {

                // Si le fichier n'est pas encore dans le store :
                let file = {
                    id: fileId,
                    path: '',
                    name: '<fichier supprimé>'
                };

                // Si le fichier est dans le store
                if (attachmentsById[fileId] !== undefined) {
                    file = attachmentsById[fileId];
                }

                // Construction de l'URL du fichier
                file.url = this.apiBaseUrl + 'Attachments/Download/?path=' + file.path;

                // Extension
                const extIndex = file.path.lastIndexOf('.');
                file.ext = file.path.substr(extIndex + 1).toUpperCase();

                return file;
            });
        }
    }
};
