
/*
 * Données fixes et globales, hydratées une seule fois au chargement de l'appli
 */

import {callAPI, strcmp, dateToYmd} from '@/helpers.js';

export default {

    namespaced: true,

    state: {
        // Nombre de jours avant que les tâches soient urgentes
        warningDays: 7,

        // Liste des ateliers et cuisines
        workshops: [],

        // Liste des Trokools
        trokools: [],

        // Liste des Etablissements des Trokools
        trokoolsEtabs: [],

        // Statuts possibles pour les clients
        customersStatusesOptions: [
            {value:'ACTIVE_CLIENT', label:'Client'},
            {value:'ARCHIVED_CLIENT', label:'Client archivé'},
            {value:'ACTIVE_PROSPECT', label:'Prospect'},
            {value:'ARCHIVED_PROSPECT', label:'Prospect archivé'}
        ],

        // Liste des typologies
        typologiesOptions: [
            {value:'BB', label:'Crêche BB'},
            {value:'MG', label:'Crêche MG'},
            {value:'MAT', label:'Maternelle'},
            {value:'PRI', label:'Primaire'},
            {value:'AD', label:'Adulte'},
            {value:'POR', label:'Portage'}
        ]
    },

    getters: {

        // Retourne la date à partir de laquelle les taches deviennent urgentes (yyyy-mm-dd)
        warningDate (state) {
            const d = new Date();
            d.setDate(d.getDate() + state.warningDays);
            return dateToYmd(d);
        },

        // Liste des ateliers et cuisines sous forme de liste pour un dropdown
        workshopsOptions (state) {
            return state.workshops.map( workshop => {
                return {
                    value: workshop.id,
                    label: workshop.name
                };
            });
        },

        // Liste associative (ID => Nom) des ateliers
        workshopNameById (state) {
            const workshopNameById = {};
            state.workshops.forEach( workshop => {
                workshopNameById[ workshop.id ] = workshop.name;
            });
            return workshopNameById;
        },

        // Liste associative (ID => txt) des status des clients
        customerStatusTxtById (state) {
            const statusTxtById = {};
            state.customersStatusesOptions.forEach( statusOption => {
                statusTxtById[ statusOption.value ] = statusOption.label;
            });
            return statusTxtById;
        },

        // Liste associative (slug => name) des Trokools
        trokoolNameBySlug (state) {
            const trokoolNameBySlug = {};
            state.trokools.forEach( trokool => {
                trokoolNameBySlug[ trokool.slug ] = trokool.name;
            });
            return trokoolNameBySlug;
        },

        // Liste des étabs des Trokools pour un dropdown
        trokoolsEtabsOptions (state, getters) {
            const options = state.trokoolsEtabs.map(etab => {
                return {
                    value: etab.id,
                    label: etab.name + '<em>' + etab.reference + '</em>' + '<em>' + getters.trokoolNameBySlug[ etab.trokoolSlug ] + '</em>'
                };
            });
            options.sort((o1, o2) => strcmp(o1.label, o2.label));
            return options;
        },

        // Liste associative (ID => trokoolEtab)
        trokoolEtabById (state, getters) {
            const trokoolEtabById = {};
            state.trokoolsEtabs.forEach( item => {
                const trokoolEtab = { ...item };
                trokoolEtab.trokoolName = getters.trokoolNameBySlug[ trokoolEtab.trokoolSlug ];
                trokoolEtabById[ trokoolEtab.id ] = trokoolEtab;
            });
            return trokoolEtabById;
        }
    },

    mutations: {
        setWorkshops (state, workshops) {
            state.workshops = [ ...workshops ];
        },
        setTrokools (state, trokools) {
            state.trokools = [ ...trokools ];
        },
        setTrokoolsEtabs (state, trokoolsEtabs) {
            state.trokoolsEtabs = [ ...trokoolsEtabs ];
        }
    },

    actions: {

        // Chargement de tous les settings fixes depuis la DB
        loadFromDatabase (context) {

            // Les ateliers
            callAPI(context, 'Main/getWorkshops', {}, {noOverlay: true}).then( response => {
                if (response.status === true) {
                    context.commit('setWorkshops', response.workshops);
                }
            });

            // Les Trokools
            callAPI(context, 'Main/getTrokools', {}, {noOverlay: true}).then( response => {
                if (response.status === true) {
                    context.commit('setTrokools', response.trokools);
                }
            });

            // Les étabs des Trokools
            const getTrokoolsEtabs = () => {
                callAPI(context, 'Etabs/getTrokoolsEtabs', {}, {noOverlay: true}).then( response => {
                    if (response.status === true) {
                        context.commit('setTrokoolsEtabs', response.etabs);
                    }
                });
            };
            getTrokoolsEtabs();

            // Synchro des établissements des Trokools
            callAPI(context, 'Etabs/syncTrokoolEtabs', {}, {noOverlay: true}).then( response => {
                if (response.status === true) {
                    // Si ok, rafraichissement de la liste des étabs des Trokools
                    getTrokoolsEtabs();
                }
            });
        }
    }
};
