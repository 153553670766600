<template>
    <div class="form-files-field">

        <label v-if="label && label.length" v-html="label">
        </label>

        <ul class="files" v-if="currentAttachments">
            <li v-for="file of currentAttachments">

                <div class="ext">
                    {{ file.ext }}
                </div>

                <a :href="file.url" target="_blank" v-if="file.path">
                    {{ decodeURI(file.name) }}
                </a>
                <span v-else>
                    {{ decodeURI(file.name) }}
                </span>

                <div class="file-actions" v-if="file.path">
                    <img src="@/assets/img/icon-edit.svg" width="18" height="18" @click.stop="renameFile(file)" />
                    <img src="@/assets/img/icon-delete.svg" width="18" height="18" @click.stop="deleteFile(file)" />
                </div>
            </li>
        </ul>

        <button class="form-button full-width icon attachment" @click="openUploadArea()">
            Joindre des fichiers
        </button>

        <transition>
            <upload-area
                ref="uploadArea"
                v-if="displayUploadArea"
                :error="error"
                message="Glissez des fichiers ici<br/>ou cliquez pour parcourir"
                @filesSelected="files => uploadFiles(files)"
                @cancel="displayUploadArea = false"
                />
        </transition>
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapActions} from 'vuex';
    import UploadArea from '@/components/Lib/Misc/UploadArea.vue';
    import attachmentsMixin from '@/mixins/attachmentsMixin.js';
    export default {
        name: 'FormFilesField',
        components: {UploadArea},
        mixins: [attachmentsMixin],

        props: {
            label: {},
            value: {}
        },

        data () {
            return {
                error: "",
                displayUploadArea: false
            };
        },

        computed: {
            ...mapGetters('attachments', ['attachmentsById']),

            // Conversion de this.value (qui est un array de fileId)
            // en objets file {id, name, path}
            currentAttachments () {
                return this.hydrateAttachments(this.value, this.attachmentsById);
            }
        },

        methods: {
            ...mapMutations('confirm', {confirmPopup: 'confirm'}),
            ...mapMutations('prompt', {promptPopup: 'prompt'}),
            ...mapActions('attachments', {
                uploadFilesAction: 'uploadFiles',
                renameFileAction: 'renameFile',
                deleteFileAction: 'deleteFile'
            }),

            // Reset la zone d'upload
            // Attention : le composant doit être rendu visible au préalable
            resetUploadArea () {
                this.error = "";
                if (this.$refs.uploadArea) {
                    this.$refs.uploadArea.resetField();
                }
            },

            // Ouvre la zone de drag-and-drop UPLOADS
            openUploadArea () {
                this.displayUploadArea = true;
                setTimeout( () => {
                    this.resetUploadArea();
                    this.$refs.uploadArea.$refs.filefield.focus();
                }, 100);
            },

            // Téléchargement effectif de fichiers sur le serveur
            uploadFiles (files) {
                this.error = "";
                this.uploadFilesAction(files)
                .then(( filesIds ) => {

                    // Fermeture zone d'upload
                    this.resetUploadArea();
                    this.displayUploadArea = false;

                    // Ajout des fichiers à la liste : mise à jour du modèle
                    if (filesIds) {
                        this.$emit('input', [ ...this.value, ...filesIds]);
                    }
                })
                .catch(error => {
                    this.error = error;
                });
            },

            renameFile (file) {
                this.promptPopup({
                    question: "Intitulé de ce fichier ?",
                    width:400,
                    value:file.name,
                    callback: (newName) => {
                        const updatedFile = { ...file };
                        updatedFile.name = newName
                        this.renameFileAction(updatedFile);
                    }
                });
            },

            deleteFile (file) {
                this.confirmPopup({
                    question : "Supprimer le fichier <a href='" + file.url + "' class='orange'><strong>" + file.name + "</strong></a> ?",
                    width: 480,
                    btnYesLabel: "OUI, Supprimer",
                    btnYesClasses: 'orange icon delete',
                    btnYesCallback: () => {
                        this.deleteFileAction(file.id)
                        .then( () => {
                            const index = this.value.findIndex( fileId => fileId == file.id);
                            if (index >= 0) {
                                this.value.splice(index, 1);
                            }
                        });
                    }
                });
            }
        }
    };
</script>
