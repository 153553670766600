<template>
    <div class="crm-table-filter-input"
         :class="{enabled}"
         @click="focus()">

        <div v-if=" !enabled">
            {{ label }}
        </div>

        <form-input-field
            v-else
            v-model="query"
            :label="label"
            :hasReset="true"
            :placeholder="searchPlaceholder"
            class="theme-light theme-white"
            @focus="hasFocus = true"
            @blur="hasFocus = false"
            @submit="$refs.field.blur()"
            ref="field"
            />
    </div>
</template>

<script>
import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
export default {
    name: 'CrmTableFilterInput',
    components: {FormInputField},

    props: {
        label:{},
        value:{},
        searchPlaceholder:{default:'Rechercher...'}
    },

    data () {
        return {
            hasFocus:false,
            query: ''
        };
    },

    computed: {
        active () {
            return this.query.length > 0;
        },
        enabled () {
            return this.hasFocus || this.active;
        }
    },

    created () {
        this.init_or_reload(this.value);
    },

    watch: {
        query () {
            this.$emit('input', this.query);
        },
        active () {
            this.emitActiveBoolean();
        }
    },

    methods: {
        init_or_reload (value) {
            this.query = value;
            this.emitActiveBoolean();
        },
        focus () {
            this.hasFocus = true;
            setTimeout(() => {
                this.$refs.field.focus();
            }, 250);
        },
        emitActiveBoolean () {
            this.$emit('setActive', this.active);
        }
    }
};
</script>
