<template>

    <div class="form-input-field" :class="{'has-reset':hasReset}">

        <label v-if="label && label.length" v-html="label">
        </label>

        <div class="field-wrapper">

            <input ref="field"
                   class="field"
                   autocomplete="nope"
                   :type="inputType"
                   :placeholder="placeholder"
                   :value="value"
                   @input="e => $emit('input', uppercase ? e.target.value.toUpperCase() : e.target.value)"
                   @keyup.enter="onKeyupEnter"
                   @keyup.escape="blur()"
                   @click="onClick"
                   @focus="onFocus"
                   @blur="onBlur"
                   />

            <img v-if="type == 'password'"
                 class="eye"
                 src="@/assets/img/icon-login-eye.svg"
                 title="Afficher le mot de passe"
                 width="18" height="18"
                 @mousedown="showPassword = true"
                 @mouseup="showPassword = false"
                 @mouseleave="showPassword = false"
                 />

            <div class="reset" v-if="hasReset && ( !hideResetIfEmpty || value != '')" @click.stop="reset()">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18px" height="18px" viewBox="0 0 18 18" enable-background="new 0 0 18 18" xml:space="preserve">
                    <path fill="#101010" d="M13.721,5.23L12.77,4.279L9,8.049l-3.77-3.77L4.279,5.23L8.049,9l-3.77,3.77l0.951,0.951L9,9.951l3.77,3.77l0.951-0.951L9.951,9L13.721,5.23z"/>
                </svg>
            </div>

            <transition>
                <div class="suggestions" v-if="displaySuggestions">
                    <div v-for="suggestion in activeSuggestions" @click="selectSuggestion(suggestion)">
                        {{ suggestion }}
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import {callAPI, getActiveSuggestions} from '@/helpers.js';
    export default {
        name: 'FormInputField',

        data () {
            return {
                showPassword: false,
                hasFocus: false,
                loosingFocus: false,
                suggestions: []
            };
        },

        props: {
            value: {},
            label: {},
            placeholder: {},
            hasReset: {default: false},
            hideResetIfEmpty: {default: false},
            uppercase: {default: false},
            type: {default: 'text'},
            suggestionsEndPoint: {default: false},
            alwaysShowAllSuggestions: {default: false},
            selectContentOnClick: {default: false}
        },

        computed: {

            // Type du champ : celui fourni, sauf dans le cas d'un PASSWORD lorsque le picto EYE est cliqué
            inputType () {
                if (this.type == 'password' && this.showPassword) {
                    return 'text';
                } else {
                    return this.type;
                }
            },

            // Suggestions éventuelles à afficher
            activeSuggestions () {
                if (this.alwaysShowAllSuggestions) {
                    return this.suggestions;
                } else {
                    return getActiveSuggestions(this.suggestions, this.value);
                }
            },

            // Les suggestions sont-elles visibles ?
            displaySuggestions () {
                return (
                    (this.hasFocus || this.loosingFocus) &&
                    this.suggestions.length > 0 &&
                    this.activeSuggestions.length > 0
                );
            }
        },

        watch: {
            // La perte du focus est mémorisée pendant 100ms par loosingFocus
            loosingFocus () {
                setTimeout(() => {
                    this.loosingFocus = false;
                }, 300);
            }
        },

        methods: {
            onClick () {
                if (this.selectContentOnClick && this.$refs.field !== undefined) {
                    this.$refs.field.select();
                }
            },
            focus () {
                if (this.$refs.field !== undefined) {
                    this.$refs.field.focus();
                }
            },
            onFocus () {
                this.hasFocus = true;
                this.$emit('focus');
            },
            blur () {
                if (this.$refs.field !== undefined) {
                    this.$refs.field.blur();
                }
            },
            onBlur () {
                this.hasFocus = false;
                this.loosingFocus = true;
                this.$emit('blur');
            },
            reset () {
                this.$emit('input', '');
            },
            selectSuggestion (suggestion) {
                this.$emit('input', suggestion);
            },
            onKeyupEnter () {
                if (this.displaySuggestions) {
                    const [firstSuggestion] = this.activeSuggestions;
                    this.selectSuggestion(firstSuggestion);
                } else {
                    this.$emit('submit');
                }
            }
        },

        created () {
            // Chargement des suggestions pour le champ
            if (this.suggestionsEndPoint) {
                callAPI(this.$store, this.suggestionsEndPoint, {}, {noOverlay: true})
                .then(response => {
                    if (response.status === true) {
                        this.suggestions = [ ...response.suggestions ];
                    }
                });
            }
        }
    };
</script>
