<template>
    <div class="page customers-page">

        <customers-toolbar
            :pageIndex = "pageIndex"
            :items = "items"
            :filteredAndSortedItems = "filteredAndSortedItems"
            :realCheckedItems = "realCheckedItems"
            @generateCSV="generateCSV()"
            />

        <div class="page-body">

            <div class="crm-table page-overflow-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th class="check-all" @click="switchCheckAll()">
                                <img src="@/assets/img/checkbox-all.svg" width="22" height="22" />
                            </th>
                            <th style="width:112px;">
                                <crm-table-filter type='select' label="Statut" filterKey="statuses" :pageIndex="pageIndex" :options="statusesOptions" />
                            </th>
                            <th style="width:118px;">
                                <crm-table-filter type='select' label="Atelier" filterKey="workshops" :pageIndex="pageIndex" :options="workshopsOptions" />
                            </th>
                            <th>
                                <crm-table-filter label="Nom du client / prospect" filterKey="name" :pageIndex="pageIndex" ref="customerFilter" />
                            </th>
                            <th>
                                <crm-table-filter label="Code postal - ville" filterKey="city" :pageIndex="pageIndex" />
                            </th>
                            <th style="width:192px;">
                                Détails
                            </th>
                            <th style="width:70px; text-align:center;">
                                Effectif
                            </th>
                            <th style="width:200px;">
                                <crm-table-filter type='select' label="Typologies" filterKey="typologies" :pageIndex="pageIndex" :options="typologiesOptions" />
                            </th>
                            <th>
                                <crm-table-filter label="Commentaires" filterKey="comments" :pageIndex="pageIndex" />
                            </th>
                            <th style="width:130px;">
                                <crm-table-filter label="Prest. d'origine" filterKey="provider" :pageIndex="pageIndex" />
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="customer in filteredAndSortedItems"
                            :class="{highlight: customer.id == highlightedId}"
                            @click="$refs.customer_popup.addOrEditCustomer(customer.id)"
                            >
                            <td class="check" @click.stop="switchCheck(customer.id)">
                                <img v-if="checkedIds.includes(customer.id)" src="@/assets/img/checkbox-checked.svg" width="24" height="24" />
                                <img v-else src="@/assets/img/checkbox-unchecked.svg" width="24" height="24" />
                            </td>
                            <td>
                                {{ customerStatusTxtById[ customer.status ] }}
                            </td>
                            <td>
                                {{ workshopNameById[ customer.workshopId ] }}
                            </td>
                            <td class="main allow-overflow relative icon-on-both"
                                :class="customer.children.length > 0 ? 'has-children' : ''"
                                >

                                <div class="list-of-details on-left">
                                    <tip-icon :active="customer.parentId > 0" @click="filterByParent(customer)">
                                        <template v-slot:icon>
                                            <img src="@/assets/img/menu-icon-customers.svg" alt="" width="18" height="18" />
                                            <span class="chevron right">
                                                >
                                            </span>
                                        </template>
                                        <template v-slot:tip>
                                            <div class="info">
                                                {{ 'PARENT : "' + getParentName(customer) + '"' }}
                                            </div>
                                        </template>
                                    </tip-icon>
                                </div>

                                <div style="min-width:190px;" class="name">
                                    {{ customer.name }}
                                </div>

                                <div class="list-of-details on-right">
                                    <tip-icon :active="customer.children.length > 0"
                                              :counter="customer.children.length"
                                              v-show="customer.children.length > 0"
                                              @click="filterByParent(customer.children[0])"
                                              >
                                        <template v-slot:icon>
                                            <span class="chevron left">
                                                >
                                            </span>
                                            <img src="@/assets/img/menu-icon-customers.svg" alt="" width="18" height="18" />
                                        </template>
                                        <template v-slot:tip>
                                            <div v-for="child in customer.children">
                                                <div class="no-wrap" v-text="child.name">
                                                </div>
                                            </div>
                                            <div class="info">
                                                clic : voir les ENFANTS
                                            </div>
                                        </template>
                                    </tip-icon>
                                </div>
                            </td>
                            <td>
                                {{ customer.city }}
                            </td>
                            <td class="allow-overflow">
                                <customer-details :customer="customer" />
                            </td>
                            <td style="text-align:center;">
                                {{ customer.effectifs }}
                            </td>
                            <td class="allow-overflow">
                                <customer-typologies :customer="customer" />
                            </td>
                            <td>
                                {{ customer.comments }}
                            </td>
                            <td style="width:100px">
                                {{ customer.provider }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <customer-popup ref="customer_popup" />
        <contact-popup ref="contact_popup" />
        <etab-popup ref="etab_popup" />
        <event-popup ref="event_popup" />
        <task-popup ref="task_popup" />
    </div>
</template>

<script>
    import {mapState, mapGetters, mapMutations} from 'vuex';
    import {filterItems, strcmp, scrollToDOMelement, generateCSV} from '@/helpers.js';
    import CrmTableCheckboxesMixin from '@/mixins/crmTableCheckboxesMixin.js';
    import CrmTableFilter from '@/components/Global/CrmTableFilter.vue';
    import CustomerDetails from '@/components/Pages/Customers/CustomerDetails.vue';
    import TipIcon from '@/components/Global/TipIcon.vue';
    import CustomerTypologies from '@/components/Pages/Customers/CustomerTypologies.vue';
    import CustomerPopup from '@/components/Pages/Customers/CustomerPopup.vue';
    import CustomersToolbar from '@/components/Pages/Customers/CustomersToolbar.vue';
    import ContactPopup from '@/components/Pages/Contacts/ContactPopup.vue';
    import EtabPopup from '@/components/Pages/Etabs/EtabPopup.vue';
    import EventPopup from '@/components/Pages/Events/EventPopup.vue';
    import TaskPopup from '@/components/Pages/Tasks/TaskPopup.vue';
    export default {
        name: 'Customers',
        components: {
            CustomersToolbar, CustomerTypologies, CustomerPopup, CrmTableFilter, CustomerDetails, TipIcon, ContactPopup, EtabPopup, EventPopup, TaskPopup
        },
        mixins: [CrmTableCheckboxesMixin],

        props: ['pageIndex'],

        computed:{
            ...mapState('pages', ['pages']),
            ...mapState('settings', {typologiesOptions:'typologiesOptions', statusesOptions: 'customersStatusesOptions'}),
            ...mapState('customers', {items: 'customers', highlightedId:'highlightedId'}),
            ...mapState('etabs', ['etabs']),
            ...mapState('contacts', ['contacts']),
            ...mapState('events', ['events']),
            ...mapState('tasks', ['tasks']),
            ...mapGetters('customers', ['customerById', 'customersByParentId']),
            ...mapGetters('pages', ['isActiveFilter']),
            ...mapGetters('settings', ['workshopsOptions', 'workshopNameById', 'customerStatusTxtById']),

            settings () {
                return this.pages[this.pageIndex].settings;
            },

            // Liste des clients triés et filtrés, affichés dans le tableau
            filteredAndSortedItems () {
                // Pour forcer mise à jour de la valeur computed
                this.pages;

                // Enrichissement des données liées
                let items = this.items.map(c => {
                    const customer = { ...c };

                    // Nom pour filtrage : le nom + le nom du parent
                    customer.nameForFiltering = customer.name;
                    if (customer.parentId > 0) {
                        const parent = this.customerById[customer.parentId];
                        if (parent !== undefined) {
                            customer.nameForFiltering += ' § ' + parent.name;
                        }
                    }

                    // Liste des enfants
                    const children = this.customersByParentId[ customer.id ];
                    customer.children = children !== undefined ? [ ...children ] : [];

                    // Les points de livraison du client
                    customer.etabs = this.etabs.filter(e => e.customerId == customer.id);

                    // Les contacts du client
                    customer.contacts = this.contacts.filter(c => c.customerId == customer.id);

                    // Les events du client
                    customer.events = this.events.filter(e => e.customerId == customer.id);

                    // Les tâches du client
                    customer.tasks = this.tasks.filter(t => t.customerId == customer.id);

                    // Les emails du client
                    customer.mails = [];

                    // Les typologies, par fusion de celles des étabs
                    const distinctTypologies = {};
                    customer.etabs.forEach(e => {
                        if (e.typologies.constructor === Array) {
                            e.typologies.forEach(t => {distinctTypologies[t] = true});
                        }
                    });
                    customer.typologies = Object.keys(distinctTypologies);

                    return customer;
                });

                // Filtrage multi-critères
                const filtersToApply = [
                    {filterKey: 'statuses', itemKey: 'status'},
                    {filterKey: 'workshops', itemKey: 'workshopId'},
                    {filterKey: 'name', itemKey: 'nameForFiltering'},
                    {filterKey: 'city', itemKey: 'city'},
                    {filterKey: 'comments', itemKey: 'comments'},
                    {filterKey: 'provider', itemKey: 'provider'}
                ];
                if (this.isActiveFilter(this.pageIndex, 'typologies')) {
                    filtersToApply.push({filterKey: 'typologies', itemKey: 'typologies'});
                }
                items = filterItems({
                    items: items,
                    filtersValues: this.settings.filters,
                    filtersToApply: filtersToApply
                });

                // Tri par nom
                if (this.settings.sortBy == 'name') {
                    items.sort( (c1, c2) => strcmp(c1.name, c2.name) );
                }
                // Tri par effectif, puis nom
                else if (this.settings.sortBy == 'effectif') {
                    items.sort( (c1, c2) => {
                        const compByName = strcmp(c1.name, c2.name);
                        const [e1, e2] = [c1.effectifs, c2.effectifs].map( e => parseInt(e));
                        return e1 < e2 ? 1 : (e1 > e2 ? -1 : compByName);
                    });
                }

                return items;
            }
        },

        methods: {
            ...mapMutations('pages', ['updatePageSubSetting']),

            getParentName (customer) {
                const parent = this.customerById[customer.parentId];
                return parent !== undefined ? parent.name : 'Inconnu';
            },

            // Applique un filtre sur la colonne "nom du client" uniquement, d'après le nom du client parent
            filterByParent (customer) {
                const parentName = this.getParentName(customer);
                this.updatePageSubSetting({pageIndex: this.pageIndex, key:'filters', subkey:'name', value:parentName});
                setTimeout(() => {
                    this.$refs.customerFilter.init_or_reload(parentName);
                }, 100);
            },

            // Extraction Excel
            generateCSV () {
                let columns = [
                    {label:'N°', itemKey:'id'},
                    {label:'Statut', itemKey:'statusTxt'},
                    {label:'Atelier', itemKey:'workshopTxt'},
                    {label:'Nom', itemKey:'name'},
                    {label:'CP - Ville', itemKey:'city'},
                    {label:'Effectif', itemKey:'effectifs'},
                    {label:'Prest. origine', itemKey:'provider'},
                    {label:'Commentaires', itemKey:'comments'},
                    ...this.typologiesOptions.map( typo => {
                        return {label:typo.label, itemKey:typo.value};
                    }),
                    {label:'Contacts', itemKey:'nbContacts'},
                    {label:'Point livr.', itemKey:'nbEtabs'},
                    {label:'Évènements', itemKey:'nbEvents'},
                    {label:'Tâches', itemKey:'nbTasks'},
                    {label:'E-mails', itemKey:'nbMails'}
                ];

                const items = [ ...this.realCheckedItems ].map( item => {
                    item.statusTxt = this.customerStatusTxtById[ item.status ];
                    item.workshopTxt = this.workshopNameById[ item.workshopId ];
                    item.nbContacts = item.contacts.length;
                    item.nbEtabs = item.etabs.length;
                    item.nbEvents = item.events.length;
                    item.nbTasks = item.tasks.length;
                    item.nbMails = item.mails.length;
                    this.typologiesOptions.forEach( typo => {
                        item[typo.value] = item.typologies.includes(typo.value) ? typo.value : '';
                    });
                    return item;
                });

                generateCSV(this.$store, columns, items, 'CRM_clients');
            }
        },

        watch: {
            highlightedId (newHighlightedId) {
                scrollToDOMelement(this.$el, 'tr.highlight');
            }
        }
    };
</script>
