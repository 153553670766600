<template>
    <div>
        <popup v-for="(popup, index) in alertPopups"
			   class="alert-popup"
			   :width="popup.width"
			   @close="close(index)"
			   >
			<div class="message" v-html="popup.message">
			</div>
		</popup>
    </div>
</template>


<script>
    import {mapState, mapMutations} from 'vuex';
    import Popup from '@/components/Lib/Misc/Popup.vue';
    export default {
        name: 'AlertPopups',
        components: {Popup},

        computed: {
            ...mapState('alert', {alertPopups: 'popups'})
        },

        methods: {
			...mapMutations('alert', {closeAlertPopup: 'close'}),

            // Callback à la fermeture
			close (popupIndex) {
				this.alertPopups[popupIndex].callback();
				this.closeAlertPopup(popupIndex);
			}
        }
    };
</script>
