
/*
 * Liste intégrale des clients et prospects
 * utiliser ce store pour lire / modifier / supprimer tout client
 */

import {callAPI, strcmp} from '@/helpers.js';

export default {

    namespaced: true,

    state: {
        customers: [],
        highlightedId:-1
    },

    getters: {
        customerById (state) {
            const customerById = {};
            state.customers.forEach( customer => {
                customerById[ customer.id ] = customer;
            });
            return customerById;
        },

        customersByParentId (state) {
            const customersByParentId = {};
            state.customers.forEach( customer => {
                if (customer.parentId > 0) {
                    if (customersByParentId[ customer.parentId ] === undefined) {
                        customersByParentId[ customer.parentId ] = [];
                    }
                    customersByParentId[ customer.parentId ].push(customer);
                }
            });
            return customersByParentId;
        },

        customersOptions (state, getters, rootGetters) {

            // Tri alphabétique
            const customers = [ ...state.customers ];
            customers.sort( (c1, c2) => strcmp(c1.name, c2.name) );

            // Enrichissement du statut
            const statusesOptions = rootGetters.settings.customersStatusesOptions;
            return customers.map( customer => {
                const statusOption = statusesOptions.find( o => o.value == customer.status);

                return {
                    value: customer.id,
                    label: customer.name + '<em>' + statusOption.label + '</em>'
                };
            });
        }
    },

    mutations: {
        setCustomers (state, customers) {
            state.customers = [ ...customers ];
        },

        // Met à jour ou ajoute un client au store
        // A appeler après toute mise à jour sur le serveur
        setCustomer (state, customer) {
            const index = state.customers.findIndex(c => c.id == customer.id);
            if (index >= 0) {
                state.customers.splice(index, 1, { ...customer });
            } else {
                state.customers.push({ ...customer });
            }
        },

        // Retire un client du store
        unsetCustomer (state, customerId) {
            const index = state.customers.findIndex(c => c.id == customerId);
            if (index >= 0) {
                state.customers.splice(index, 1);
            }
        },

        // Met en surbrillance une ligne
        setHighlightedId (state, id) {
            state.highlightedId = id;
        },
        // Désactive la surbrillance
        resetHighlightedId (state) {
            state.highlightedId = -1;
        }
    },

    actions: {
        // Hydration complète, à appeler au chargement de l'app
        loadFromDatabase (context) {
            callAPI(context, 'Customers/getAll', {}, {noOverlay: true})
            .then( response => {
                if (response.status === true) {
                    context.commit('setCustomers', response.customers);
                }
            });
        },

        // Retourne un client hydraté ou un client vierge si n'existe pas
        get (context, customerId) {
            const index = context.state.customers.findIndex(c => c.id == customerId);
            let customer;
            if (index >= 0) {
                customer = context.state.customers[index];
            } else {
                let status = context.rootState.settings.customersStatusesOptions[0].value;
                if (customerId == -1) status = 'ACTIVE_CLIENT';
                else if (customerId == -2) status = 'ACTIVE_PROSPECT';
                customer = {
                    id: -1,
                    parentId: 0,
                    status: status,
                    name: '',
                    workshopId: context.rootGetters['settings/workshopsOptions'][0].value,
                    effectifs: 0,
                    provider: '',
                    city: '',
                    comments: ''
                };
            }
            return { ...customer };
        },

        // Création ou mise à jour d'un client
        // Sauvegarde en DB puis mise à jour du store
        save (context, customer) {
            return new Promise( (resolve, reject) => {
                callAPI(context, 'Customers/save', {customer: { ...customer }}, {})
                .then( response => {
                    if (response.status === true) {
                        context.commit('setCustomer', response.customer);

                        // Mise en surbrillance pendant quelques secondes
                        context.commit('setHighlightedId', response.customer.id);
                        setTimeout(() => {
                            context.commit('resetHighlightedId');
                        }, 500);

                        resolve();
                    } else {
                        reject(response.errors);
                    }
                });
            });
        },

        // Vérifie si un client est supprimable ou non
        isDeletable (context, customerId) {
            return new Promise( (resolve, reject) => {
                callAPI(context, 'Customers/isDeletable', {customerId}, {})
                .then( response => {
                    response.status === true ? resolve() : reject();
                });
            });
        },

        // Suppression d'un client sur le serveur puis dans le store
        // Attention : vérifier au préalable qu'il n'y a aucun contact ni étab lié
        delete (context, customerId) {
            return new Promise( (resolve, reject) => {
                callAPI(context, 'Customers/delete', {customerId}, {})
                .then( response => {
                    if (response.status === true) {
                        context.commit('unsetCustomer', customerId);
                        resolve();
                    }
                });
            });
        }
    }
};
