<template>
    <div class="contact-popup-tags sidebox w-260">
        <label class="form-style">
            Etiquettes
        </label>
        <div class="box">
            <div class="tags noselect">

                <div v-for="tag in tags"
                     class="d-table fixed v-middle tag-line"
                     :class="{checked: value.includes(tag.id) }"
                     @click="switchTag(tag.id)"
                     >

                    <div class="tag-check">
                        <img class="checked" src="@/assets/img/checkbox-checked.svg" width="18" height="18" />
                        <img class="unchecked" src="@/assets/img/checkbox-unchecked.svg" width="18" height="18" />
                    </div>

                    <div class="tag-name" :title="tag.tag">
                        {{ tag.tag }}
                    </div>

                    <div class="tag-actions">
                        <img src="@/assets/img/icon-edit.svg" width="18" height="18" @click.stop="editTag(tag)" />
                        <img src="@/assets/img/icon-delete.svg" width="18" height="18" @click.stop="deleteTag(tag)" />
                    </div>
                </div>

            </div>
            <form class="add-tag d-table" @submit.prevent="addContactsTag()">
                <div>
                    <form-input-field v-model="newTag" placeholder="Nouvelle étiquette" />
                </div>
                <div class="btn-wrapper">
                    <button class="form-button narrow full-width">OK</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
    export default {
        name: 'ContactPopupTags',
        components: {FormInputField},

        props: {
            value: {} // = Array tagsIds
        },

        data () {
            return {
                newTag: ''
            };
        },

        computed: {
            ...mapState('contacts', ['tags'])
        },

        methods: {
            ...mapMutations('prompt', {promptPopup: 'prompt'}),
            ...mapMutations('confirm', {confirmPopup: 'confirm'}),
            ...mapActions('contacts', {
                addContactsTagAction: 'addTag', renameContactTagAction: 'renameTag', deleteContactTagAction: 'deleteTag'
            }),

            // Création d'un nouveau tag
            addContactsTag() {
                this.addContactsTagAction( this.newTag ).then( () => {
                    this.newTag = '';
                });
            },

            // Coche / décoche un tag pour le contact
            switchTag(tagId) {
                const index = this.value.findIndex( id => id == tagId );
                let newValue = [ ...this.value ];
                if (index >= 0) {
                    newValue.splice(index, 1);
                } else {
                    newValue.push(tagId);
                }
                this.$emit('input', newValue);
            },

            // Renomme une étiquette en DB
            editTag(tag) {
                this.promptPopup({
                    question: "Intitulé de cette étiquette ?",
                    width:400,
                    value:tag.tag,
                    callback: (newValue) => {
                        const updatedTag = {id:tag.id, tag:newValue};
                        this.renameContactTagAction(updatedTag);
                    }
                });
            },

            // Supprime une étiiquette en DB, ainsi que le lien avec tous les contacts
            deleteTag(tag) {
                this.confirmPopup({
                    question : "Supprimer l'étiquette <strong class='orange'>" + tag.tag + "</strong> <br/> pour l'ensemble des contacts ?",
                    width: 480,
                    btnYesLabel: "OUI, Supprimer",
                    btnYesClasses: 'orange icon delete',
                    btnYesCallback: () => {

                        // Décochage préalable, si besoin
                        const index = this.value.findIndex( id => id == tag.id );
                        if (index >= 0) {
                            this.switchTag(tag.id);
                        }

                        // Suppression en DB, puis mise à jour de tous les contacts
                        this.deleteContactTagAction(tag.id);
                    }
                });
            }
        }
    };
</script>
