<template>
    <div @keyup.esc.stop="$emit('close')" tabindex="0" ref="popup">

        <transition appear>

            <div class="popup-overlay">
                <div class="popup-table">
                    <div class="popup-table-cell">

                        <div class="popup" :class="{'has-close-button': closeButton}">

                            <div class="close"
                                 v-if="closeButton"
                                 @click="$emit('close')"
                                 >
                                <img src="@/assets/img/popup-close.svg" alt="close" width="20" height="20" />
                            </div>

                            <div class="inner" :style="{'max-width': width + 'px'}">

                                <div class="content">
                                    <slot></slot>
                                </div>

                                <div class="actions">
                                    <div>
                                        <button v-for="(button, index) in buttonsOnLeft" :key="'buttonOnLeft' + index"
                                            class="form-button"
                                            :class="button.classes ? button.classes : ''"
                                            @click.prevent="$emit(button.event)"
                                            >
                                            {{ button.label }}
                                        </button>
                                    </div>
                                    <div>
                                        <button v-for="(button, index) in buttonsOnRight" :key="'buttonOnRight' + index"
                                            class="form-button"
                                            :class="button.classes ? button.classes : ''"
                                            @click.prevent="$emit(button.event)"
                                            >
                                            {{ button.label }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'Popup',
        props: {
            closeButton: {required: false, default: true},
            buttonsOnLeft: {type:Array, required: false, default: () => []},
            buttonsOnRight: {type:Array, required: false, default: () => []},
            width: {type:Number, required: false, default:390}
        },

        mounted () {
            this.$refs.popup.focus();
        }
    };
</script>
