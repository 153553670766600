<template>

    <div class="contact-popup">

        <popup v-if="opened"
               :width="1400"
               :buttonsOnLeft="fields.id > 0 ? [{label:'Supprimer', event:'delete', classes:'icon orange delete'}] : []"
               :buttonsOnRight="[{label:'Annuler', event:'close', classes:'icon cancel'}, {label:'Enregistrer', event:'save', classes:'green icon ok'}]"
               @close="opened = false"
               @save="saveContact()"
               @delete="deleteContact(true)"
               >

            <div class="d-table fixed break-640">

                <!-- COLONNE PRINCIPALE -->
                <div>

                    <!-- LIGNE 1 DU FORMULAIRE-->
                    <div class="flex-fields break-1024">

                        <div class="flex-4">
                            <form-select-field
                               label="Type de contact"
                               :fullWidthDropdown="true"
                               :options="contactTypeOptions"
                               :value="isLinkedToCustomer"
                               @input="(v) => {setIsLinkedToCustomer(v);}"
                               />
                        </div>

                        <div class="flex-4 group-of-fields">

                            <form-select-field
                               v-show="isLinkedToCustomer == 1"
                               v-model="fields.customerId"
                               label="Client ou Prospect"
                               :fullWidthDropdown="true"
                               :options="customersOptions"
                               :searchable="true"
                               :hasReset="true"
                               :resetValue="-1"
                               ref="customerSelector"
                               />

                            <form-select-field
                                  v-show="isLinkedToCustomer == 1 && customerEtabsOptions.length > 1"
                                  v-model="fields.etabId"
                                  label="Point de livraison"
                                  :fullWidthDropdown="true"
                                  :options="customerEtabsOptions"
                                  :hasReset="true"
                                  :resetValue="0"
                                  ref="etabSelector"
                                  />
                        </div>

                        <div class="flex-2">
                            <form-select-field
                               v-show="isLinkedToCustomer == 1"
                               v-model="fields.displayInTrokool"
                               label="Visible dans Trokool ?"
                               :fullWidthDropdown="true"
                               :options="displayInTrokoolOptions"
                               />
                        </div>

                    </div>

                    <!-- LIGNE 2 DU FORMULAIRE-->
                    <div class="flex-fields break-1024">

                        <div class="flex-2">
                            <form-input-field
                               label="Civilité"
                               v-model="fields.civility"
                               suggestionsEndPoint="Main/getCivilitiesSuggestions"
                               :alwaysShowAllSuggestions="true"
                               :hasReset="true"
                               :hideResetIfEmpty="true"
                               />
                        </div>

                        <div class="flex-3">
                            <form-input-field
                               label="Nom"
                               v-model="fields.lastname"
                               :uppercase="true"
                               :hasReset="true"
                               :hideResetIfEmpty="true"
                               />
                            <div class="field-warning" v-if="duplicateContacts.length > 0">
                                Contact<span v-if="duplicateContacts.length > 1">s</span> déjà existant
                                avec un nom similaire :<br/>
                                <div v-html="duplicateContacts.join('<br/>')">
                                </div>
                            </div>
                        </div>

                        <div class="flex-3">
                            <form-input-field
                               label="Prénom"
                               v-model="fields.firstname"
                               :hasReset="true"
                               :hideResetIfEmpty="true"
                               />
                        </div>
                    </div>

                    <!-- LIGNE 3 DU FORMULAIRE-->
                    <div class="flex-fields break-1024">

                        <div class="flex-1">
                            <form-input-field
                               label="Pour correspondances : titre ou complément de nom"
                               v-model="fields.title"
                               suggestionsEndPoint="Main/getContactsTitlesSuggestions"
                               :hasReset="true"
                               :hideResetIfEmpty="true"
                               />
                        </div>

                        <div class="flex-1">
                            <form-input-field
                               label="Société, Association, Site, ..."
                               v-model="fields.company"
                               suggestionsEndPoint="Main/getCompaniesSuggestions"
                               :hasReset="true"
                               :hideResetIfEmpty="true"
                               />
                        </div>

                        <div class="flex-1">
                            <form-input-field
                               label="Fonction"
                               v-model="fields.job"
                               suggestionsEndPoint="Main/getJobsSuggestions"
                               :alwaysShowAllSuggestions="true"
                               :hasReset="true"
                               :hideResetIfEmpty="true"
                               />
                        </div>
                    </div>

                    <!-- 2 COLONNES DU FORMULAIRE : MAIL+TEL à gauche, ADRESSE à droite -->
                    <div class="flex-fields break-1024">

                        <div class="flex-1 group-of-fields">

                            <form-input-field
                                v-model="fields.email"
                                label="Adresse e-mail"
                                type="email"
                                :hasReset="true"
                                :hideResetIfEmpty="true"
                                />

                            <form-tel-field
                                v-model="fields.tel1"
                                label="Téléphones"
                                />

                            <form-tel-field
                                v-model="fields.tel2"
                                />

                            <form-tel-field
                                v-model="fields.tel3"
                                />
                        </div>

                        <div class="flex-1 group-of-fields">

                            <form-input-field
                                v-model="fields.address1"
                                label="Adresse"
                                placeholder="Ligne 1"
                                :hasReset="true"
                                :hideResetIfEmpty="true"
                                />

                            <form-input-field
                                v-model="fields.address2"
                                placeholder="Ligne 2"
                                :hasReset="true"
                                :hideResetIfEmpty="true"
                                />

                            <form-input-field
                                v-model="fields.address3"
                                placeholder="Ligne 3"
                                :hasReset="true"
                                :hideResetIfEmpty="true"
                                />

                            <form-input-field
                                v-model="fields.city"
                                label="Code postal + Ville"
                                :uppercase="true"
                                suggestionsEndPoint="Main/getCitySuggestions"
                                :hasReset="true"
                                :hideResetIfEmpty="true"
                                />
                        </div>
                    </div>

                    <!-- LIGNE 5 DU FORMULAIRE-->
                    <div class="flex-fields">
                        <form-textarea-field v-model="fields.comments" label="Commentaires" rows="2" />
                    </div>

                </div>


                <div class="gutter"></div>


                <!-- SIDEBAR -->
                <contact-popup-tags v-model="fields.tagsIds" />
            </div>


            <div class="errors" v-if="errors.length > 0">
                <ul>
                    <li v-for="error in errors">
                        <span v-html="error"></span>
                    </li>
                </ul>
            </div>

        </popup>
    </div>
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
    import {forceSettings, strcmp} from '@/helpers.js';
    import {getPrettyNameHtml} from '@/helpersForCrm.js';
    import Popup from '@/components/Lib/Misc/Popup.vue';
    import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
    import FormTelField from '@/components/Lib/Forms/FormTelField.vue';
    import FormSelectField from '@/components/Lib/Forms/FormSelectField.vue';
    import FormTextareaField from '@/components/Lib/Forms/FormTextareaField.vue';
    import ContactPopupTags from '@/components/Pages/Contacts/ContactPopupTags.vue';
    export default {
        name: 'ContactPopup',
        components: {Popup, FormInputField, FormTelField, FormSelectField, FormTextareaField, ContactPopupTags},

        data () {
            return {
                opened: false,
                fields: {},
                isLinkedToCustomer: true,
                contactTypeOptions: [
                    {value:1, label:"Lié à un client ou un prospect"},
                    {value:0, label:"Autre"}
                ],
                displayInTrokoolOptions: [
                    {value:0, label:"NON"},
                    {value:1, label:"OUI"}
                ],
                errors: []
            };
        },

        computed:{
            ...mapState('contacts', ['contacts']),
            ...mapState('etabs', ['etabs']),
            ...mapGetters('customers', ['customersOptions']),

            // Liste des points de livraison du contact, étendue si besoin au point de livraison actuellement lié
            customerEtabsOptions () {
                const currentCustomerId = this.fields.customerId;

                const customerEtabsOptions =
                    this.etabs
                    .filter(e => e.customerId == currentCustomerId || e.id == this.fields.etabId)
                    .map(e => {
                        return {
                            value: e.id,
                            label: e.name + '<em>' + e.city + '</em>'
                        };
                    });

                // Tri alphabétique
                customerEtabsOptions.sort( (eo1, eo2) => strcmp(eo1.label, eo2.label) );

                return [
                    {value:0, label:'Tous les points de livraison'},
                    ...customerEtabsOptions
                ];
            },

            // Détection préventive des doublons
            duplicateContacts () {
                const lastname = this.fields.lastname.trim();
                if (this.fields.id > 0 || lastname.length < 4) {
                    return '';
                }

                const similarContacts =
                    this.contacts
                    .filter(c => c.lastname.indexOf(lastname) === 0)
                    .map(c => getPrettyNameHtml(c));

                return similarContacts;
            }
        },

        methods: {
            ...mapMutations('confirm', ['confirm']),
            ...mapMutations('alert', ['alert']),
            ...mapActions('contacts', {getContact: 'get', saveContactAction: 'save', deleteContactAction: 'delete'}),

            // Si création, presetFields permet de forcer l'état initial de certains champs
            addOrEditContact (contactId, presetFields = {}) {

                // Hydratation avec le contact ou un contact vierge
                this.getContact(contactId).then( contact => {
                    this.fields = contact;

                    const isNewContact = !(this.fields.id > 0);

                    // Si nouveau contact, force certains champs
                    if (isNewContact && presetFields) {
                        this.fields = forceSettings(presetFields, this.fields);
                    }

                    // Type de contact : "Lié à un client ou prospect" si customerId existe, ou si nouveau contact
                    this.isLinkedToCustomer = isNewContact ? 1 : (this.fields.customerId > 0 ? 1 : 0);

                    // Cas particulier : création d'un nouveau contact, explicitement de type autre
                    if (isNewContact && presetFields.customerId && !(presetFields.customerId > 0)) {
                        this.isLinkedToCustomer = 0;
                    }

                    // Ouverture popup
                    this.errors = [];
                    this.opened = true;
                });
            },

            // Event : changement du type de contact (lié à un client ou prospect ?)
            setIsLinkedToCustomer (newValue) {

                // Mise à jour du sélecteur lui-même
                this.isLinkedToCustomer = newValue;

                // Si "autre type de contact" : reset du lien avec le client ou prospect et pas visible dans Trokool
                if (newValue == 0) {
                    this.fields.customerId = -1;
                    this.fields.etabId = 0;
                    this.fields.displayInTrokool = 0;
                }
                // Si "Lié à client ou prospect" : ouverture du sélecteur en question
                else {
                    setTimeout( () => {
                        this.$refs.customerSelector.openSelector();
                    }, 100);
                }
            },

            saveContact () {

                if (this.isLinkedToCustomer && this.fields.customerId == -1) {
                    this.errors = ["Sélectionnez le client/prospect lié à ce contact <u>OU</u> type de contact = \"Autre\"."];
                    return;
                }

                this.errors = [];
                this.saveContactAction(this.fields)
                .then( () => {this.opened = false;})
                .catch( errors => {this.errors = [ ...errors ];});
            },

            // Suppression d'un contact
            deleteContact(checkBefore) {

                // Si demandé, confirmation préalable
                if (checkBefore) {
                    this.confirm({
                        question: "Supprimer ce contact ?",
                        btnYesLabel: "OUI, supprimer",
                        btnYesClasses: 'icon orange delete',
                        btnYesCallback: () => {
                            this.deleteContact(false);
                        }
                    });
                } else {
                    // Suppression effective
                    this.deleteContactAction(this.fields.id).then( () => {
                        this.opened = false;
                    });
                }
            }
        }
    };
</script>
