<template>
    <div class="page tasks-page">

        <tasks-toolbar
            :pageIndex = "pageIndex"
            :items = "items"
            :filteredAndSortedItems = "filteredAndSortedItems"
            :realCheckedItems = "realCheckedItems"
            @generateCSV="generateCSV()"
            />

        <div class="page-body">
            <div class="crm-table page-overflow-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th class="check-all" @click="switchCheckAll()">
                                <img src="@/assets/img/checkbox-all.svg" width="22" height="22" />
                            </th>
                            <th style="width:132px;">
                                <crm-table-filter type='select' label="Atelier" filterKey="workshops" :pageIndex="pageIndex" :options="workshopsOptions" />
                            </th>
                            <th style="width:120px;">
                                <crm-table-filter type='select' label="Statut" filterKey="statuses" :pageIndex="pageIndex" :options="statusesOptions" />
                            </th>
                            <th style="width:220px;">
                                <crm-table-filter label="Client / Prospect" filterKey="customer" :pageIndex="pageIndex" />
                            </th>
                            <th style="width:205px;">
                                <crm-table-filter label="Date limite" filterKey="date" :pageIndex="pageIndex" searchPlaceholder="aaaa ou mm/aaaa" />
                            </th>
                            <th style="width:190px;">
                                <crm-table-filter type='select' label="Type de tâche" filterKey="type" :pageIndex="pageIndex" :options="tasksTypesOptions" />
                            </th>
                            <th>
                                <crm-table-filter label="Description" filterKey="description" :pageIndex="pageIndex" />
                            </th>
                            <th style="width:120px;">
                                <crm-table-filter type='select' label="Collaborateurs" filterKey="users" :pageIndex="pageIndex" :options="usersOptions" />
                            </th>
                            <th style="width:140px;">
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="task in filteredAndSortedItems"
                            :class="{highlight: task.id == highlightedId}"
                            @click="$refs.task_popup.addOrEditTask(task.id)"
                            >
                            <td class="check" @click.stop="switchCheck(task.id)">
                                <img v-if="checkedIds.includes(task.id)" src="@/assets/img/checkbox-checked.svg" width="24" height="24" />
                                <img v-else src="@/assets/img/checkbox-unchecked.svg" width="24" height="24" />
                            </td>
                            <td>
                                {{ task.customerId > 0 ? workshopNameById[ task.customer.workshopId ] : '' }}
                            </td>
                            <td>
                                {{ task.customerId > 0 ? customerStatusTxtById[ task.customer.status ] : '' }}
                            </td>
                            <td class="strong allow-overflow relative icon-on-right">

                                {{ task.customerId > 0 ? task.customer.name : '' }}

                                <div class="list-of-details" v-if="task.customerId > 0">
                                    <tip-icon :active="true" @click="openCustomer(task.customer)">
                                        <template v-slot:icon>
                                            <img src="@/assets/img/icon-external-link.svg" alt="" width="14" height="14" />
                                        </template>
                                        <template v-slot:tip>
                                            <div class="info">
                                                clic : OUVRIR {{ task.customer.status.substr(-6) == 'CLIENT' ? 'client' : 'prospect'}}
                                            </div>
                                        </template>
                                    </tip-icon>
                                </div>
                            </td>
                            <td class="main no-wrap">
                                <span class="light">
                                    {{ task.date | formatDate('FULL') }}
                                </span>
                                <span class="days-before" :class="{emergency:task.date <= warningDate}">
                                    {{ task.date | daysBefore }}
                                </span>
                            </td>
                            <td class="main">
                                {{ task.type }}
                            </td>
                            <td>
                                {{ task.description }}
                            </td>
                            <td class="allow-overflow tip-content-on-right">
                                <div class="list-of-details justify-content-center">
                                    <tip-icon :active="true" :counter="task.usersIds.length" @click="$refs.task_popup.addOrEditTask(task.id)">
                                        <template v-slot:icon>
                                            <img src="@/assets/img/icon-users.svg" alt="" width="18" height="18" />
                                        </template>
                                        <template v-slot:tip>
                                            <div v-for="userId of task.usersIds">
                                                <div class="no-wrap">
                                                    {{ usersById[ userId ].name }}
                                                </div>
                                            </div>
                                        </template>
                                    </tip-icon>
                                </div>
                            </td>
                            <td>
                                <button class="form-button green small icon ok" @click.stop="taskDone(task)">
                                    C'est fait !
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <task-popup ref="task_popup" />
        <event-popup ref="event_popup" />
    </div>
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
    import {filterItems, strcmp, scrollToDOMelement, formatDate, daysBefore, generateCSV} from '@/helpers.js';
    import CrmTableCheckboxesMixin from '@/mixins/crmTableCheckboxesMixin.js';
    import TipIcon from '@/components/Global/TipIcon.vue';
    import CrmTableFilter from '@/components/Global/CrmTableFilter.vue';
    import EventPopup from '@/components/Pages/Events/EventPopup.vue';
    import TaskPopup from '@/components/Pages/Tasks/TaskPopup.vue';
    import TasksToolbar from '@/components/Pages/Tasks/TasksToolbar.vue';
    export default {
        name: 'Tasks',
        components: {EventPopup, TaskPopup, TasksToolbar, CrmTableFilter, TipIcon},
        mixins: [CrmTableCheckboxesMixin],
        filters: {formatDate, daysBefore},

        props: ['pageIndex'],

        computed: {
            ...mapState('settings', {statusesOptions: 'customersStatusesOptions'}),
            ...mapState('pages', ['pages']),
            ...mapState('tasks', {items: 'tasks', highlightedId:'highlightedId'}),
            ...mapGetters('settings', [
                'trokoolEtabById', 'workshopsOptions', 'workshopNameById', 'customerStatusTxtById', 'warningDate'
            ]),
            ...mapGetters('pages', ['isActiveFilter']),
            ...mapGetters('customers', ['customerById']),
            ...mapGetters('users', ['usersOptions', 'usersById']),
            ...mapGetters('tasks', {tasksTypesOptions:'typesOptions'}),

            settings () {
                return this.pages[this.pageIndex].settings;
            },

            filteredAndSortedItems () {
                // Pour forcer mise à jour de la valeur computed
                this.pages;

                // Enrichissement de champs croisés
                let items = this.items.map(e => {
                    const task = { ...e };

                    // client associé
                    task.customer = this.customerById[task.customerId];
                    if (task.customer === undefined) {
                        task.customer = {
                            status: false,
                            workshopId: false,
                            name: ''
                        };
                    }

                    return task;
                });

                // Filtrage multi-critères
                const filtersToApply = [
                    {filterKey: 'date', itemKey: 'date', isDate:true},
                    {filterKey: 'users', itemKey: 'usersIds'},
                    {filterKey: 'type', itemKey: 'type'},
                    {filterKey: 'description', itemKey: 'description'}
                ];
                if (this.isActiveFilter(this.pageIndex, 'statuses')) {
                    filtersToApply.push({filterKey: 'statuses', itemKey: 'customer', itemSubKey: 'status'});
                }
                if (this.isActiveFilter(this.pageIndex, 'workshops')) {
                    filtersToApply.push({filterKey: 'workshops', itemKey: 'customer', itemSubKey: 'workshopId'});
                }
                if (this.isActiveFilter(this.pageIndex, 'customer')) {
                    filtersToApply.push({filterKey: 'customer', itemKey: 'customer', itemSubKey: 'name'});
                }
                items = filterItems({
                    items: items,
                    filtersValues: this.settings.filters,
                    filtersToApply: filtersToApply
                });

                // Tri par date ASC, puis id ASC
                if (this.settings.sortBy == 'date') {
                    items.sort( (e1, e2) => {
                        const cmpByDate = strcmp(e1.date, e2.date);
                        return cmpByDate != 0 ? cmpByDate : (e1.id < e2.id ? -1 : 1);
                    });
                }
                // Tri par client ASC, puis date ASC, puis id ASC
                else if (this.settings.sortBy == 'customer') {
                    items.sort( (e1, e2) => {
                        const cmpByCustomer = strcmp(e1.customer.name, e2.customer.name);
                        const cmpByDate = strcmp(e1.date, e2.date);
                        return cmpByCustomer != 0 ? cmpByCustomer : (cmpByDate != 0 ? cmpByDate : (e1.id < e2.id ? -1 : 1));
                    });
                }

                return items;
            }
        },

        methods: {
            ...mapMutations('confirm', ['confirm']),
            ...mapActions('pages', ['addPageCustomers']),
            ...mapActions('tasks', {deleteAction: 'delete'}),

            openCustomer (customer) {
                this.addPageCustomers({
                    filters: {
                        statuses: [customer.status],
                        name: customer.name
                    }
                });
            },

            // Tâche terminée : simplement la supprimer ou la transformer en event
            taskDone (task) {
                this.confirm({
                    question: task.type + '<br/>' + (task.customerId > 0 ? task.customer.name : ''),
                    width:520,
                    closeButton: true,
                    btnYesLabel: "Transformer en évènement",
                    btnYesClasses: 'icon green event',
                    btnYesCallback: () => {

                        // Conversion en event : suppression tache puis ouverture event

                        this.deleteAction(task.id);

                        setTimeout(() => {
                            const presetFields = {
                                description: task.description,
                                usersIds: task.usersIds
                            };
                            if (task.customerId > 0) {
                                presetFields.customerId = task.customerId;
                            }
                            this.$refs.event_popup.addOrEditEvent(0, presetFields);
                        }, 600);
                    },
                    btnNoLabel: "Supprimer",
                    btnNoClasses: 'icon orange delete',
                    btnNoCallback: () => {
                        this.deleteAction(task.id);
                    }
                });
            },

            // Extraction Excel
            generateCSV () {
                let columns = [
                    {label:'N°', itemKey:'id'},
                    {label:'Statut client', itemKey:'statusTxt'},
                    {label:'Atelier client', itemKey:'workshopTxt'},
                    {label:'Nom du client', itemKey:'customerName'},
                    {label:'Date limite', itemKey:'date'},
                    {label:'Type de tâche', itemKey:'type'},
                    {label:'Description', itemKey:'description'},
                    ...this.usersOptions.map(u => {return {label:u.label, itemKey:'user_' + u.value};})
                ];

                const items = [ ...this.realCheckedItems ].map( item => {
                    item.statusTxt = item.customerId > 0 ? this.customerStatusTxtById[ item.customer.status ] : '';
                    item.workshopTxt = item.customerId > 0 ? this.workshopNameById[ item.customer.workshopId ] : '';
                    item.customerName = item.customerId > 0 ? item.customer.name : '';
                    this.usersOptions.map(u => {
                        item['user_' + u.value] = item.usersIds.includes(u.value) ? 'X' : '';
                    });
                    return item;
                });

                generateCSV(this.$store, columns, items, 'CRM_taches');
            }
        },

        watch: {
            highlightedId (newHighlightedId) {
                scrollToDOMelement(this.$el, 'tr.highlight');
            }
        }
    };
</script>
