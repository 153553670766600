<template>
    <div class="crm-table-filter">

        <crm-table-filter-input
            v-if="type == 'input'"
            :value="settings.filters[ filterKey ]"
            @input="(newValue) => updatePageSubSetting({pageIndex, key:'filters', subkey:filterKey, value:newValue})"
            @setActive="(active) => updatePageActiveFilter({pageIndex, filterKey, active})"
            :label="label"
            :searchPlaceholder="searchPlaceholder"
            ref="inputFilter"
            />

        <crm-table-filter-select
            v-else
            :value="settings.filters[ filterKey ]"
            @input="(newValue) => updatePageSubSetting({pageIndex, key:'filters', subkey:filterKey, value:newValue})"
            @setActive="(active) => updatePageActiveFilter({pageIndex, filterKey, active})"
            :options="options"
            :position="position"
            :label="label"
            :placeholderGenerator="hasPlaceholderGenerator ? () => getFilterTxtGenerator(filterKey) : false"
            ref="selectFilter"
            />
    </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex';
import CrmTableFilterInput from '@/components/Global/CrmTableFilterInput.vue';
import CrmTableFilterSelect from '@/components/Global/CrmTableFilterSelect.vue';
export default {
    name: 'CrmTableFilter',
    components: {CrmTableFilterInput, CrmTableFilterSelect},

    props: {
        type: {default: 'input'},
        label: {},
        filterKey: {},
        pageIndex: {},
        position: {default:'LEFT'},
        options: {default: () => []},
        searchPlaceholder: {default: 'Rechercher...'},
        hasPlaceholderGenerator: {default: true}
    },

    computed:{
        ...mapState('pages', ['pages']),
        ...mapGetters('pages', ['getPageFilterTxt']),

        settings () {
            return this.pages[this.pageIndex].settings;
        }
    },

    methods: {
        ...mapMutations('pages', ['updatePageSubSetting', 'updatePageActiveFilter']),

        init_or_reload (value) {
            if (this.type == 'input' && this.$refs.inputFilter !== undefined) {
                this.$refs.inputFilter.init_or_reload(value);
            }
            if (this.type == 'select' && this.$refs.selectFilter !== undefined) {
                this.$refs.selectFilter.init_or_reload(value);
            }
        },

        getFilterTxtGenerator (filterKey) {
            return this.getPageFilterTxt(this.pageIndex, filterKey);
        }
    }
};
</script>
