
export default {

    namespaced: true,

    state: {
        popups: []
    },

    mutations: {

        // Ajout d'une popup de confirmation
        confirm (state, args = {}) {
            args = Object.assign({
                question : "Êtes-vous sûr ?",
                width: 450,
                closeButton: false,
                btnYesLabel: "OUI",
                btnYesClasses: 'orange',
                btnYesCallback: () => {},
                btnNoLabel: "Non",
                btnNoClasses: '',
                btnNoCallback: () => {}
            }, args);

            state.popups.push({ ...args });
        },

        // Supression d'une popup de confirmation
        close (state, index) {
            state.popups.splice(index, 1);
        }
    }
};
