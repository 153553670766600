<template>

    <div class="form-multi-select-field">

        <label v-if="label && label.length" v-html="label">
        </label>

        <micro-popup
            :btnClass="'field noselect ' + (hasReset ? 'has-reset' : 'has-dropdown')"
            :hasCheckAll="true"
            ref="micropopup"
            :orientation="orientation"
            :position="position"
            @open="$emit('open')"
            @close="$emit('close')"
            @switchCheckAll="switchCheckAll()"
            >

            <template v-slot:btn>

                {{ btnLabel() }}

                <div class="reset" v-if="hasReset" @click.stop="reset()">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18px" height="18px" viewBox="0 0 18 18" enable-background="new 0 0 18 18" xml:space="preserve">
                        <path fill="#101010" d="M13.721,5.23L12.77,4.279L9,8.049l-3.77-3.77L4.279,5.23L8.049,9l-3.77,3.77l0.951,0.951L9,9.951l3.77,3.77l0.951-0.951L9.951,9L13.721,5.23z"/>
                    </svg>
                </div>

            </template>

            <template v-slot:content="scope">
                <div class="list-of-options" :class="{horizontal: orientation == 'HORIZONTAL'}">

                    <div v-for="option in options" @click.stop="switchSelect(option.value)">

                        <img v-if="value.includes( option.value )" src="@/assets/img/checkbox-checked.svg" width="20" height="20" />
                        <img v-else src="@/assets/img/checkbox-unchecked.svg" width="20" height="20" />

                        {{ option.label }}
                    </div>

                </div>
            </template>
        </micro-popup>
    </div>
</template>

<script>
    import MicroPopup from '@/components/Lib/Misc/MicroPopup.vue';
    export default {
        name: 'FormMultiSelectField',
        components: {MicroPopup},

        props: {
            label: {default: ''},
            placeholderIfNone: {default: 'Aucun'},
            placeholderIfAll: {default: 'Tous'},
            placeholderGenerator: {default:false},
            orientation: {default:'VERTICAL'},
            position: {default:'RIGHT'},
            hasReset: {default:false},
            selectSingleIfUnselectFirst: {default:false},
            options: {default: () => []},
            value: {default: () => []} // v-model : la liste des valeurs des items cochés
        },

        computed: {
            // La liste des items cochés
            checkedOptions () {
                return this.options.filter( option => this.value.includes(option.value) );
            }
        },

        methods: {
            // Génération de la valeur textuelle du dropdown
            btnLabel () {
                let label;

                // Si une fonction génératrice est fournie...
                if (typeof this.placeholderGenerator === 'function') {
                    label = this.placeholderGenerator()( this.checkedOptions );
                }
                // Sinon génération par défaut
                else {
                    if (this.checkedOptions.length === 0) {
                        label = this.placeholderIfNone;
                    } else if (this.checkedOptions.length === this.options.length) {
                        label = this.placeholderIfAll;
                    } else {
                        label = this.checkedOptions.map( option => option.label ).join(' + ');
                    }
                }
                return label;
            },

            // Sélection / désélection d'un item
            switchSelect (valueToSwitch) {

                let newValue = [ ...this.value ];
                const index = newValue.findIndex( v => v == valueToSwitch);

                // Cas particulier : si tout est coché et qu'on clic sur un item
                // Si demandé, on ne garde que lui au liue de le décocher
                if (
                    this.selectSingleIfUnselectFirst &&
                    this.options.length > 1 &&
                    this.value.length === this.options.length
                ){
                    newValue = [valueToSwitch];
                }
                else if (index === -1) {
                    newValue.push(valueToSwitch);
                }
                else {
                    newValue.splice(index, 1);
                }
                this.$emit('input', newValue);
            },

            // Coche toutes les cases
            reset () {
                this.$emit('input', this.options.map( o => o.value) );
                this.$refs.micropopup.close();
            },

            switchCheckAll () {
                if (this.options.length === this.value.length) {
                    this.$emit('input', [] );
                } else {
                    this.$emit('input', this.options.map( o => o.value) );
                }
            }
        }
    };
</script>
