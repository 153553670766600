<template>
    <div class="customer-typologies list-of-details">
        <tip-icon v-for="typoOption in typologiesOptions"
                  :active="customer.typologies.includes(typoOption.value)"
                  @click="openEtabsWithTypo(customer, typoOption.value)"
                  @clickIfNotActive="addEtabWithTypo(customer, typoOption.value)"
                  >
            <template v-slot:icon>
                <span class="text-icon">
                    {{ typoOption.value }}
                </span>
            </template>
            <template v-slot:tip>
                <div v-for="etab in customer.etabs" class="no-wrap" v-if="etab.typologies.includes(typoOption.value)">
                    {{ etab.name }}
                    <span class="light" v-if="etab.typologies">
                        {{
                            typologiesOptions.map(typoOption => typoOption.value)
                                             .filter( typo => etab.typologies.includes(typo) )
                                             .join('+')
                        }}
                    </span>
                </div>
                <div class="info">
                    clic : OUVRIR points de livraison {{ typoOption.label.toUpperCase() }}
                </div>
            </template>
            <template v-slot:tipIfNotActive>
                <div class="info">
                    clic : AJOUTER point de livraison {{ typoOption.label.toUpperCase() }}
                </div>
            </template>
        </tip-icon>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import {customerToEtabFields, customerToEtabPageFilters} from '@/helpersForCrm.js';
    import TipIcon from '@/components/Global/TipIcon.vue';
    export default {
        name: 'CustomerTypologies',
        components: {TipIcon},

        props: ['customer'],

        computed: {
            ...mapState('customers', ['customers']),
            ...mapState('settings', ['typologiesOptions'])
        },

        methods: {
            ...mapActions('pages', ['addPageEtabs']),

            openEtabsWithTypo(customer, typo) {
                const filters = customerToEtabPageFilters(customer);
                filters.typologies = [typo];
                this.addPageEtabs({filters});
            },

            addEtabWithTypo(customer, typo) {
                const presetFields = customerToEtabFields(customer, this.customers, this.typologiesOptions.map(to => to.value));
                presetFields.typologies = [typo];
                this.$parent.$refs.etab_popup.addOrEditEtab(-1, presetFields);
            }
        }
    };
</script>
