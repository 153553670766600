<template>
    <div>
        <popup v-for="(popup, index) in confirmPopups"
			   class="confirm-popup"
			   :buttonsOnLeft="[{label:popup.btnNoLabel, event:'no', classes:popup.btnNoClasses}]"
			   :buttonsOnRight="[{label:popup.btnYesLabel, event:'yes', classes:popup.btnYesClasses}]"
			   :width="popup.width"
			   :closeButton="popup.closeButton"
			   @no="popupConfirm(index, false)"
			   @yes="popupConfirm(index, true)"
               @close="popup.closeButton ? closeConfirmPopup(index) : ()=>{}"
			   >
			<div class="question" v-html="popup.question">
			</div>
		</popup>
    </div>
</template>


<script>
    import {mapState, mapMutations} from 'vuex';
    import Popup from '@/components/Lib/Misc/Popup.vue';
    export default {
        name: 'ConfirmPopups',
        components: {Popup},

        computed: {
            ...mapState('confirm', {confirmPopups: 'popups'})
        },

        methods: {
			...mapMutations('confirm', {closeConfirmPopup: 'close'}),

            // Réponse oui ou non à une popup de confirmation
			popupConfirm (popupIndex, confirmed) {
				this.confirmPopups[popupIndex]['btn' + (confirmed ? 'Yes' : 'No') + 'Callback']();
				this.closeConfirmPopup(popupIndex);
			}
        }
    };
</script>
