
export default {

    namespaced: true,

    state: {
        popups: []
    },

    mutations: {

        // Ajout d'une prompt-box
        prompt (state, args = {}) {

            args = Object.assign({
                width: 450,
                type: 'text',
                question: 'Nouvelle valeur ?',
                value: '',
                callback: () => {}
            }, args);

            state.popups.push({ ...args });
        },

        // Met à jour le champ texte d'une popup
        setValue (state, {index, newValue}) {
            state.popups[ index ].value = newValue;
        },

        // Supression d'une prompt-box
        close (state, index) {
            state.popups.splice(index, 1);
        }
    }
};
