<template>

    <div class="form-date-field">

        <label v-if="label && label.length" v-html="label">
        </label>

        <div class="field-wrapper d-flex">

            <div class="day-name" v-text="dayName" v-if="dayName">
            </div>

            <form-input-field
                class="day"
                type="number"
                placeholder="Jour"
                :value="day"
                @input="(v) => {this.dayFieldValue = v; updateModel();}"
                />

            <form-select-field
                class="month"
                placeholder="Mois"
                :value="month"
                :options="monthsOptions"
                :fullWidthDropdown="true"
                @input="(v) => {this.monthFieldValue = v; updateModel();}"
                />

            <form-input-field
                class="year"
                type="number"
                placeholder="Année"
                :value="year"
                @input="(v) => {this.yearFieldValue = v; updateModel();}"
                />

        </div>
    </div>
</template>

<script>
    import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
    import FormSelectField from '@/components/Lib/Forms/FormSelectField.vue';
    import {decodeTel, cleanAndEncodeTel} from '@/helpersForCrm.js';
    export default {
        name: 'FormDateField',
        components: {FormInputField, FormSelectField},

        props: {
            value: {},
            label: {default: ''}
        },

        data () {
            return {
                dayFieldValue: '',
                monthFieldValue: '',
                yearFieldValue: '',

                monthsOptions: [
                    {value:1, label:'Janvier'},
                    {value:2, label:'Février'},
                    {value:3, label:'Mars'},
                    {value:4, label:'Avril'},
                    {value:5, label:'Mai'},
                    {value:6, label:'Juin'},
                    {value:7, label:'Juillet'},
                    {value:8, label:'Août'},
                    {value:9, label:'Septembre'},
                    {value:10, label:'Octobre'},
                    {value:11, label:'Novembre'},
                    {value:12, label:'Décembre'}
                ]
            };
        },

        created () {
            // Etat initial des variables internes
            this.dayFieldValue = this.day;
            this.monthFieldValue = this.month;
            this.yearFieldValue = this.year;
        },

        computed: {
            day () {
                const day = this.value.length === 10 ? new Date(this.value + ' 12:00:00').getDate() : '';
                return isNaN(day) ? '' : day;
            },

            month () {
                const month = this.value.length === 10 ? (parseInt(new Date(this.value + ' 12:00:00').getMonth()) + 1) : '';
                return isNaN(month) ? '' : month;
            },

            year () {
                const year = this.value.length === 10 ? new Date(this.value + ' 12:00:00').getFullYear() : '';
                return isNaN(year) ? '' : year;
            },

            dayName () {
                const days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
                return days[ new Date(this.value + ' 12:00:00').getDay() ];
            }
        },

        methods: {
            updateModel () {
                const d = new Date();
                d.setDate(this.dayFieldValue);
                d.setMonth(this.monthFieldValue - 1);
                d.setFullYear(this.yearFieldValue);

                const dateTxt = d.toISOString().split('T')[0];
                if (dateTxt.substr(0,4) > '2000') {
                    this.$emit('input', dateTxt);
                }
            }
        },

        watch: {
            // Lorsque le modèle est mis à jour, force l'état des variables internes
            value (newDate) {
                this.dayFieldValue = this.day;
                this.monthFieldValue = this.month;
                this.yearFieldValue = this.year;
            }
        }
    };
</script>
