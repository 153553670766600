<template>
    <nav class="main-menu noselect d-flex">

        <draggable :list="pages" v-bind="{disabled: disableDragging}" class="d-flex" @change="draggingFinished">

            <transition-group tag="div" class="tabs d-flex" :class="{'burger-opened':burgerOpened, 'burger-visible':isBurgerVisible}">

                <div v-for="(page, index) in pages"
                     :key="'tab' + index"
                     :class="[{current: index == currentPageIndex}, getPageClass(page)]"
                     @click="selectPage(index)"
                     @click.middle="deletePage(index)"
                     >

                    <span class="icon"></span>

                    <div class="main-column d-flex flex-column justify-content-around">
                        <div class="label" v-html="getTabLabel(page, index)">
                        </div>
                        <div class="details" v-html="getTabDetailsHtml(page, index)">
                        </div>
                    </div>

                    <span class="burger">
                        <img src="@/assets/img/burger-arrow.svg" width="24" height="24" />
                    </span>

                    <span class="close" @click.stop="deletePage(index)">
                        <img src="@/assets/img/menu-icon-close.svg" width="24" height="24" />
                    </span>

                </div>
            </transition-group>
        </draggable>

        <div class="controls d-flex">

            <micro-popup class="add-page">

                <template v-slot:btn>
                    <div class="control black" @click="burgerOpened = false">
                        <img src="@/assets/img/burger.svg" width="24" height="24" />
                    </div>
                </template>

                <template v-slot:content="scope">
                    <div class="list-of-options with-icons">
                        <div class="search" @click="scope.close() && addPageSearch()">
                            <img src="@/assets/img/menu-icon-search.svg" width="24" height="24" />
                            Recherche...
                        </div>
                        <div @click="scope.close() && addPageCustomers()">
                            <img src="@/assets/img/menu-icon-customers.svg" width="24" height="24" />
                            Clients
                        </div>
                        <div @click="scope.close() && addPageProspects()">
                            <img src="@/assets/img/menu-icon-prospects.svg" width="24" height="24" />
                            Prospects
                        </div>
                        <div @click="scope.close() && addPageContacts()">
                            <img src="@/assets/img/menu-icon-contacts.svg" width="24" height="24" />
                            Contacts
                        </div>
                        <div @click="scope.close() && addPageEtabs()">
                            <img src="@/assets/img/menu-icon-etabs.svg" width="24" height="24" />
                            Points de livraisons
                        </div>
                        <div @click="scope.close() && addPageEvents()">
                            <img src="@/assets/img/menu-icon-events.svg" width="24" height="24" />
                            Événements
                        </div>
                        <div @click="scope.close() && addPageTasks()">
                            <img src="@/assets/img/menu-icon-tasks.svg" width="24" height="24" />
                            Tâches
                        </div>
                        <div @click="scope.close() && addPageMails()">
                            <img src="@/assets/img/menu-icon-mails.svg" width="24" height="24" />
                            Emails
                        </div>
                    </div>
                </template>
            </micro-popup>

            <div class="control black search" @click="() => {addPageSearch(); burgerOpened = false;}">
                <img src="@/assets/img/menu-icon-search-white.svg" />
            </div>

            <div class="control desktop-only" @click="addPageTasks()" :class="emergencyTasksCounter > 0 ? 'red' : 'black'">
                <img src="@/assets/img/menu-icon-clock.svg" />
                <div class="counter">
                    {{ emergencyTasksCounter > 0 ? emergencyTasksCounter : '-' }}
                </div>
            </div>

            <div class="control grey desktop-only" @click="addPageSettings()">
                <img src="@/assets/img/menu-icon-settings-white.svg" />
            </div>
        </div>

    </nav>
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
    import jQuery from "jquery";
    import draggable from "vuedraggable";
    import MicroPopup from '@/components/Lib/Misc/MicroPopup.vue';
    export default {
        name: "MainMenu",
        components: {MicroPopup, draggable},

        data () {
            return {
                burgerOpened: false,
                disableDragging: false
            };
        },

        computed: {
            ...mapState('session', ['session']),
            ...mapState('settings', ['customersStatusesOptions', 'typologiesOptions']),
            ...mapState('pages', ['pages', 'currentPageIndex']),
            ...mapState('tasks', ['tasks']),

            ...mapGetters('settings', ['workshopsOptions', 'warningDate']),
            ...mapGetters('contacts', ['jobsOptions', 'tagsOptions']),
            ...mapGetters('events', {eventsTypesOptions: 'typesOptions'}),
            ...mapGetters('tasks', {tasksTypesOptions: 'typesOptions'}),
            ...mapGetters('users', ['usersOptions']),
            ...mapGetters('pages', ['isActiveFilter', 'getPageFilterTxt']),

            isBurgerVisible () {
                return (
                    !this.burgerOpened &&
                    this.pages.length > 1
                );
            },

            emergencyTasksCounter () {
                return this.tasks.filter( (t) => {
                    return (
                        t.date <= this.warningDate && // date limite proche
                        t.usersIds.includes(this.session.userId) // et tache concernant l'utilisateur courant
                    );
                }).length;
            }
        },

        methods: {
            ...mapMutations('pages', ['setCurrentPageIndex', 'deletePage']),
            ...mapActions('pages', [
                'addPageCustomers', 'addPageProspects', 'addPageContacts', 'addPageEtabs', 'addPageEvents', 'addPageTasks', 'addPageMails',
                'addPageSearch', 'addPageSettings'
            ]),

            // Sélection d'un onglet
            selectPage (index) {
                this.setCurrentPageIndex(index);
                if (this.burgerOpened) {
                    this.burgerOpened = false;
                } else if (this.pages.length > 1) {
                    this.burgerOpened = true;
                }
            },

            // Calcule la largeur maximale des onglets
            refreshTabsOverflow () {
                const $ = jQuery;
                const isMobile = $('body').hasClass('w1024');

                // Sur mobile, désactive le drag-and-drop dans le menu
                this.disableDragging = isMobile;

                // Largeur max disponible pour chaque onglet ?
                let maxWidth = $(window).width();
                if ( !isMobile) {
                    maxWidth -= $('#the-header .logo').width();
                }
                maxWidth -= $('#the-header .session-info').width();
                maxWidth -= $('#the-header .main-menu .controls').width();
                const nbTabs = $('#the-header .main-menu .tabs > *').length;
                const maxWidthByTab = nbTabs > 0 ? maxWidth / nbTabs : maxWidth;

                // Différence en pixels entre la largeur d'un onglet et la largeur de son intitulé
                let tabTotalPadding = 0;
                if (nbTabs > 0) {
                    const $firstTab = $('#the-header .main-menu .tabs > *:first');
                    tabTotalPadding = $firstTab.outerWidth() - $firstTab.find('.main-column').width();
                    if (tabTotalPadding < 0) {
                        tabTotalPadding = 0;
                    }
                }

                // Mise à jour restriction de largeur
                $('#the-header .main-menu .tabs .main-column').css({
                    maxWidth: Math.floor(maxWidthByTab - tabTotalPadding) + 'px'
                });
            },

            // Détermine la classe d'un onglet (pour définir son picto)
            getPageClass (page) {
                let pageClass = page.type;
                if (page.type == 'CUSTOMERS') {
                    const statuses = page.settings.filters.statuses;
                    if (statuses.includes('ACTIVE_PROSPECT') || statuses.includes('ARCHIVED_PROSPECT')) {
                        pageClass = 'PROSPECTS';
                    }
                }
                return pageClass;
            },

            // Détermine l'intitulé d'un onglet
            // Correspond au type de la page, sauf dans le cas des clients / prospects
            getTabLabel (page, pageIndex) {
                let tabName = '';

                if (page.type == 'CUSTOMERS') {
                    const statuses = page.settings.filters['statuses'];
                    const statusesOptions = this.customersStatusesOptions.filter(o => statuses.includes(o.value));
                    tabName = this.getPageFilterTxt(pageIndex, 'statuses')( statusesOptions );
                }
                else {
                    const labels = {
                        CONTACTS: 'Contacts',
                        ETABS: 'Points de livraisons',
                        EVENTS: 'Événements',
                        TASKS: 'Tâches',
                        MAILS: 'Emails',
                        SEARCH: 'Recherche',
                        SETTINGS: 'Configuration'
                    }
                    tabName = labels[page.type];
                }

                return tabName;
            },

            getTabDetailsHtml (page, pageIndex) {
                let tabDetailsHtml = '<span>&nbsp;</span>';
                const tabDetailsArray = this.getTabDetailsArray(page, pageIndex);
                if (tabDetailsArray.length > 0) {
                    tabDetailsHtml = '#' + tabDetailsArray.reverse().join('<span class="sep"></span>#');
                }
                return tabDetailsHtml;
            },

            // Détermine le détail d'un onglet : les filtres actifs à utiliser pour nommer l'onglet
            getTabDetailsArray (page, pageIndex) {

                const tabDetailsArray = [];

                // Filtre par atelier
                if (['CUSTOMERS', 'CONTACTS', 'ETABS', 'EVENTS', 'TASKS'].includes(page.type)) {
                    if (this.isActiveFilter(pageIndex, 'workshops')) {
                        tabDetailsArray.push(
                            this.getPageFilterTxt(pageIndex, 'workshops')(
                                this.workshopsOptions.filter(
                                    workshop => page.settings.filters.workshops.includes(workshop.value)
                                )
                            )
                        );
                    }
                }

                // Filtre par statut client
                if (['CONTACTS', 'ETABS', 'EVENTS', 'TASKS'].includes(page.type)) {
                    if (this.isActiveFilter(pageIndex, 'statuses')) {
                        tabDetailsArray.push(
                            this.getPageFilterTxt(pageIndex, 'statuses')(
                                this.customersStatusesOptions.filter(
                                    status => page.settings.filters.statuses.includes(status.value)
                                )
                            )
                        );
                    }
                }

                if (['EVENTS', 'TASKS'].includes(page.type)) {

                    // Filtre par collaborateur
                    if (this.isActiveFilter(pageIndex, 'users')) {
                        tabDetailsArray.push(
                            this.getPageFilterTxt(pageIndex, 'users')(
                                this.usersOptions.filter(
                                    userOption => page.settings.filters.users.includes(userOption.value)
                                )
                            )
                        );
                    }

                    // Autres filtres
                    ['customer', 'description', 'date'].forEach( (filterKey) => {
                        if (this.isActiveFilter(pageIndex, filterKey) && page.settings.filters[filterKey].length > 0) {
                            tabDetailsArray.push(
                                page.settings.filters[filterKey]
                            );
                        }
                    });
                }

                switch (page.type){

                    // Onglet de type client ou prospect
                    case 'CUSTOMERS':

                        // Filtre par typo
                        if (this.isActiveFilter(pageIndex, 'typologies')) {
                            tabDetailsArray.push(
                                this.getPageFilterTxt(pageIndex, 'typologies')(
                                    this.typologiesOptions.filter(
                                        typo => page.settings.filters.typologies.includes(typo.value)
                                    )
                                )
                            );
                        }

                        // Autres filtres
                        ['name', 'city', 'comments', 'provider'].forEach( (filterKey) => {
                            if (this.isActiveFilter(pageIndex, filterKey) && page.settings.filters[filterKey].length > 0) {
                                tabDetailsArray.push(
                                    page.settings.filters[filterKey]
                                );
                            }
                        });
                    break;

                    // Onglet de type Contacts
                    case 'CONTACTS':

                        // Filtre par fonction
                        if (this.isActiveFilter(pageIndex, 'jobs')) {
                            tabDetailsArray.push(
                                this.getPageFilterTxt(pageIndex, 'jobs')(
                                    this.jobsOptions.filter(
                                        jobOption => page.settings.filters.jobs.includes(jobOption.value)
                                    )
                                )
                            );
                        }

                        // Filtre par étiquette
                        if (this.isActiveFilter(pageIndex, 'tags')) {
                            tabDetailsArray.push(
                                this.getPageFilterTxt(pageIndex, 'tags')(
                                    this.tagsOptions.filter(
                                        tagOption => page.settings.filters.tags.includes(tagOption.value)
                                    )
                                )
                            );
                        }

                        // Filtre par visibilité dans Trokool
                        if (this.isActiveFilter(pageIndex, 'displayInTrokool')) {

                            tabDetailsArray.push(
                                this.getPageFilterTxt(pageIndex, 'displayInTrokool')(
                                    page.settings.filters.displayInTrokool.map( d => {
                                        return {value:d, label:''};
                                    })
                                )
                            );
                        }

                        // Autres filtres
                        ['customer', 'company', 'name', 'contactDatas', 'comments'].forEach( (filterKey) => {
                            if (this.isActiveFilter(pageIndex, filterKey) && page.settings.filters[filterKey].length > 0) {
                                tabDetailsArray.push(
                                    page.settings.filters[filterKey]
                                );
                            }
                        });
                    break;

                    // Onglet de type Point de livraison
                    case 'ETABS':

                        // Filtre par typo
                        if (this.isActiveFilter(pageIndex, 'typologies')) {
                            tabDetailsArray.push(
                                this.getPageFilterTxt(pageIndex, 'typologies')(
                                    this.typologiesOptions.filter(
                                        typo => page.settings.filters.typologies.includes(typo.value)
                                    )
                                )
                            );
                        }

                        // Autres filtres
                        ['customer', 'name', 'address', 'trokoolEtab'].forEach( (filterKey) => {
                            if (this.isActiveFilter(pageIndex, filterKey) && page.settings.filters[filterKey].length > 0) {
                                tabDetailsArray.push(
                                    page.settings.filters[filterKey]
                                );
                            }
                        });
                    break;

                    // Onglet de event
                    case 'EVENTS':

                        // Filtre par type d'event
                        if (this.isActiveFilter(pageIndex, 'type')) {
                            tabDetailsArray.push(
                                this.getPageFilterTxt(pageIndex, 'type')(
                                    this.eventsTypesOptions.filter(
                                        typeOption => page.settings.filters.type.includes(typeOption.value)
                                    )
                                )
                            );
                        }

                        // Autres filtres
                        ['attachment'].forEach( (filterKey) => {
                            if (this.isActiveFilter(pageIndex, filterKey) && page.settings.filters[filterKey].length > 0) {
                                tabDetailsArray.push(
                                    page.settings.filters[filterKey]
                                );
                            }
                        });
                    break;

                    // Onglet de tache
                    case 'TASKS':

                        // Filtre par type de tâche
                        if (this.isActiveFilter(pageIndex, 'type')) {
                            tabDetailsArray.push(
                                this.getPageFilterTxt(pageIndex, 'type')(
                                    this.tasksTypesOptions.filter(
                                        typeOption => page.settings.filters.type.includes(typeOption.value)
                                    )
                                )
                            );
                        }
                    break;

                    case 'SEARCH':
                        if (page.settings.query) {
                            tabDetailsArray.push(page.settings.query);
                        }
                    break;
                }

                return tabDetailsArray;
            },

            // Corrige l'onglet courant en cas de drag and drop
            draggingFinished (event) {
                if (event.moved.oldIndex == this.currentPageIndex) {
                    this.setCurrentPageIndex(event.moved.newIndex);
                }
                else if (event.moved.oldIndex < this.currentPageIndex && event.moved.newIndex >= this.currentPageIndex) {
                    this.setCurrentPageIndex(this.currentPageIndex - 1);
                }
                else if (event.moved.oldIndex > this.currentPageIndex && event.moved.newIndex <= this.currentPageIndex) {
                    this.setCurrentPageIndex(this.currentPageIndex + 1);
                }
            }
        },

        mounted () {
            // Vérifie l'overflow des onglets régulièrement car les animations CSS empêchent de se baser uniquement sur les datas
            this.refreshTabsOverflow();
            setInterval(this.refreshTabsOverflow, 333);
        },

        watch: {
            // Fermeture du burger menu si moins de 2 pages ouvertes
            pages (newPages) {
                if (newPages.length <= 1 && this.burgerOpened) {
                    this.burgerOpened = false;
                }
            }
        }
    };
</script>
