<template>
    <div class="crm-table-filter-select"
         :class="{enabled}"
         @click.stop="hasFocus ? unsetFocus() : setFocus()"
         >

        <div v-if=" !enabled">
            {{ label }}
        </div>

        <form-multi-select-field
            v-else
            v-model="values"
            :options="options"
            :label="label"
            :position="position"
            :hasReset="true"
            :selectSingleIfUnselectFirst="true"
            :placeholderIfNone="placeholderIfNone"
            :placeholderIfAll="placeholderIfAll"
            :placeholderGenerator="placeholderGenerator"
            class="theme-light theme-white"
            ref="field"
            @open="hasFocus = true"
            @close="hasFocus = false"
            />
    </div>
</template>

<script>
import FormMultiSelectField from '@/components/Lib/Forms/FormMultiSelectField.vue';
export default {
    name: 'CrmTableFilterSelect',
    components: {FormMultiSelectField},

    props: [
        'label', 'position',
        'placeholderIfNone', 'placeholderIfAll', 'placeholderGenerator',
        'value', 'options'
    ],

    data () {
        return {
            hasFocus: false,
            values: []
        };
    },

    computed: {
        active () {
            return this.values.length < this.options.length;
        },
        enabled () {
            return this.hasFocus || this.active;
        }
    },

    created () {
        this.init_or_reload(this.value);
    },

    watch: {
        values () {
            this.$emit('input', this.values);
        },
        active () {
            this.emitActiveBoolean();
        }
    },

    methods: {
        init_or_reload (value) {
            this.values = [ ...value ];
            this.emitActiveBoolean();
        },
        unsetFocus () {
            this.hasFocus = false;
            setTimeout( () => {
                this.$refs.field.$refs.micropopup.close();
            }, 250);
        },
        setFocus () {
            this.hasFocus = true;
            setTimeout( () => {
                this.$refs.field.$refs.micropopup.open();
            }, 250);
        },
        emitActiveBoolean () {
            this.$emit('setActive', this.active);
        }
    }
};
</script>
