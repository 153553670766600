<template>
    <div class="page contacts-page">

        <contacts-toolbar
            :pageIndex = "pageIndex"
            :items = "items"
            :filteredAndSortedItems = "filteredAndSortedItems"
            :realCheckedItems = "realCheckedItems"
            @generateCSV="generateCSV()"
            />

        <div class="page-body">
            <div class="crm-table page-overflow-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th class="check-all" @click="switchCheckAll()">
                                <img src="@/assets/img/checkbox-all.svg" width="22" height="22" />
                            </th>
                            <th style="width:132px;">
                                <crm-table-filter type='select' label="Atelier" filterKey="workshops" :pageIndex="pageIndex" :options="workshopsOptions" />
                            </th>
                            <th style="width:120px;">
                                <crm-table-filter type='select' label="Statut" filterKey="statuses" :pageIndex="pageIndex" :options="statusesOptions" />
                            </th>
                            <th>
                                <crm-table-filter label="Client / Prospect" filterKey="customer" :pageIndex="pageIndex" />
                            </th>
                            <th style="width:105px;">
                                <crm-table-filter label="Point de livr." filterKey="etab" :pageIndex="pageIndex" />
                            </th>
                            <th style="width:250px;">
                                <crm-table-filter label="Nom du contact" filterKey="name" :pageIndex="pageIndex" />
                            </th>
                            <th>
                                <crm-table-filter type='select' label="Fonction" filterKey="jobs" :pageIndex="pageIndex" :options="jobsOptions" />
                            </th>
                            <th style="width:100px;">
                                <crm-table-filter type='select' label="Etiquettes" filterKey="tags" :pageIndex="pageIndex" :options="tagsOptions" />
                            </th>
                            <th style="width:110px;">
                                <crm-table-filter label="Coordonnées" filterKey="contactDatas" :pageIndex="pageIndex" />
                            </th>
                            <th>
                                <crm-table-filter label="Commentaires" filterKey="comments" :pageIndex="pageIndex" />
                            </th>
                            <th style="width:92px;">
                                <crm-table-filter type='select' label="Trokool" filterKey="displayInTrokool" :pageIndex="pageIndex" position="RIGHT" :options="[{value:0, label:'Pas dans Trokool'}, {value:1, label:'Dans Trokool'}]" />
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="contact in filteredAndSortedItems"
                            :class="{highlight: contact.id == highlightedId}"
                            @click="$refs.contact_popup.addOrEditContact(contact.id)"
                            >
                            <td class="check" @click.stop="switchCheck(contact.id)">
                                <img v-if="checkedIds.includes(contact.id)" src="@/assets/img/checkbox-checked.svg" width="24" height="24" />
                                <img v-else src="@/assets/img/checkbox-unchecked.svg" width="24" height="24" />
                            </td>
                            <td>
                                {{ contact.customerId > 0 ? workshopNameById[ contact.customer.workshopId ] : '' }}
                            </td>
                            <td>
                                {{ contact.customerId > 0 ? customerStatusTxtById[ contact.customer.status ] : '' }}
                            </td>
                            <td class="strong allow-overflow relative icon-on-right">

                                {{ contact.customerId > 0 ? contact.customer.name : '' }}

                                <div class="list-of-details" v-if="contact.customerId > 0">
                                    <tip-icon :active="true" @click="openCustomer(contact.customer)">
                                        <template v-slot:icon>
                                            <img src="@/assets/img/icon-external-link.svg" alt="" width="14" height="14" />
                                        </template>
                                        <template v-slot:tip>
                                            <div class="info">
                                                clic : OUVRIR {{ contact.customer.status.substr(-6) == 'CLIENT' ? 'client' : 'prospect'}}
                                            </div>
                                        </template>
                                    </tip-icon>
                                </div>
                            </td>
                            <td class="allow-overflow">
                                <contact-etabs :etabs="contact.etabs" class="justify-content-center" />
                            </td>
                            <td class="main allow-overflow relative icon-on-right">
                                <div class="pretty-name" v-html="contact.prettyNameHtml">
                                </div>
                                <div class="list-of-details">
                                    <tip-icon :active="true" @click="copy(contact)">
                                        <template v-slot:icon>
                                            <img src="@/assets/img/icon-copy.svg" alt="" width="14" height="14" />
                                        </template>
                                        <template v-slot:tip>
                                            <div class="info">
                                                clic : DUPLIQUER ce contact
                                            </div>
                                        </template>
                                    </tip-icon>
                                </div>
                            </td>
                            <td>
                                {{ contact.job }}
                            </td>
                            <td class="allow-overflow">
                                <contact-tags-preview :contact="contact" />
                            </td>
                            <td class="allow-overflow">
                                <contact-details :contact="contact" />
                            </td>
                            <td>
                                {{ contact.comments }}
                            </td>
                            <td class="center">
                                <img v-if="contact.displayInTrokool == 1" src="@/assets/img/icon-checked.svg" alt="" width="18" height="18" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <contact-popup ref="contact_popup" />
    </div>
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
    import {filterItems, strcmp, scrollToDOMelement, generateCSV} from '@/helpers.js';
    import {decodeTel, getPrettyNameHtml, getPrettyName, getFullAddressHtml} from '@/helpersForCrm.js';
    import CrmTableCheckboxesMixin from '@/mixins/crmTableCheckboxesMixin.js';
    import CrmTableFilter from '@/components/Global/CrmTableFilter.vue';
    import TipIcon from '@/components/Global/TipIcon.vue';
    import ContactEtabs from '@/components/Pages/Contacts/ContactEtabs.vue';
    import ContactDetails from '@/components/Pages/Contacts/ContactDetails.vue';
    import ContactTagsPreview from '@/components/Pages/Contacts/ContactTagsPreview.vue';
    import ContactsToolbar from '@/components/Pages/Contacts/ContactsToolbar.vue';
    import ContactPopup from '@/components/Pages/Contacts/ContactPopup.vue';
    export default {
        name: 'Contacts',
        components: {ContactsToolbar, ContactPopup, ContactEtabs, ContactDetails, ContactTagsPreview, CrmTableFilter, TipIcon},
        mixins: [CrmTableCheckboxesMixin],

        props: ['pageIndex'],

        computed: {
            ...mapState('contacts', {items: 'contacts', highlightedId:'highlightedId'}),
            ...mapState('pages', ['pages']),
            ...mapState('etabs', ['etabs']),
            ...mapState('settings', {statusesOptions: 'customersStatusesOptions'}),
            ...mapGetters('settings', ['workshopsOptions', 'workshopNameById', 'customerStatusTxtById']),
            ...mapGetters('contacts', ['jobsOptions', 'tagsOptions']),
            ...mapGetters('customers', ['customerById']),
            ...mapGetters('etabs', ['etabById', 'etabsByCustomerId']),
            ...mapGetters('pages', ['isActiveFilter']),

            settings () {
                return this.pages[this.pageIndex].settings;
            },

            filteredAndSortedItems () {
                // Pour forcer mise à jour de la valeur computed
                this.pages;

                // Enrichissement de champs croisés
                let items = this.items.map(e => {
                    const contact = { ...e };

                    // Client lié
                    contact.customer = this.customerById[contact.customerId];
                    if (contact.customer === undefined) {
                        contact.customer = {
                            status: false,
                            workshopId: false,
                            name: ''
                        };
                    }

                    // Civilité, nom, prénom, titre
                    contact.prettyNameHtml = getPrettyNameHtml(contact);

                    // Points de livraison liés à ce contact : soit tous, soit un seul si spécifié dans la fiche
                    const singleEtab = this.etabById[contact.etabId];
                    if (singleEtab === undefined) {
                        contact.etabs = this.etabsByCustomerId[contact.customerId];
                        if (contact.etabs === undefined) {
                            contact.etabs = [];
                        }
                    } else {
                        contact.etabs = [ {...singleEtab} ];
                    }
                    contact.etabForFiltering = contact.etabs.map(e => e.name + ' ' + e.city).join(' ');

                    // Adresse complète
                    contact.fullAddress = getFullAddressHtml(contact);

                    // Champs spécifiquement pour le filtrage des contacts :
                    contact.nameForFiltering = getPrettyName(contact);

                    contact.contactDatasForFiltering = [
                        contact.tel1, contact.tel2, contact.tel3
                    ].map(t => Object.values(decodeTel(t)).join('|') )
                    .join('|');
                    contact.contactDatasForFiltering += [
                        contact.email, contact.address1, contact.address2, contact.address3, contact.city
                    ].join('|');

                    // Champ pour le tri par nom
                    contact.nameForSorting = [contact.lastname, contact.firstname, contact.title].filter(i => i != '').join(' ').toLowerCase();

                    return contact;
                });

                // Filtrage multi-critères
                const filtersToApply = [
                    {filterKey: 'etab', itemKey: 'etabForFiltering'},
                    {filterKey: 'name', itemKey: 'nameForFiltering'},
                    {filterKey: 'contactDatas', itemKey: 'contactDatasForFiltering', searchInsideWordsAndIgnoreSpaces: true},
                    {filterKey: 'comments', itemKey: 'comments'},
                    {filterKey: 'displayInTrokool', itemKey: 'displayInTrokool'}
                ];
                if (this.isActiveFilter(this.pageIndex, 'statuses')) {
                    filtersToApply.push({filterKey: 'statuses', itemKey: 'customer', itemSubKey: 'status'});
                }
                if (this.isActiveFilter(this.pageIndex, 'workshops')) {
                    filtersToApply.push({filterKey: 'workshops', itemKey: 'customer', itemSubKey: 'workshopId'});
                }
                if (this.isActiveFilter(this.pageIndex, 'customer')) {
                    filtersToApply.push({filterKey: 'customer', itemKey: 'customer', itemSubKey: 'name'});
                }
                if (this.isActiveFilter(this.pageIndex, 'jobs')) {
                    filtersToApply.push({filterKey: 'jobs', itemKey: 'job'});
                }
                if (this.isActiveFilter(this.pageIndex, 'tags')) {
                    filtersToApply.push({filterKey: 'tags', itemKey: 'tagsIds'});
                }
                items = filterItems({
                    items: items,
                    filtersValues: this.settings.filters,
                    filtersToApply: filtersToApply
                });

                // Tri par nom
                if (this.settings.sortBy == 'name') {
                    items.sort( (c1, c2) => strcmp(c1.nameForSorting, c2.nameForSorting) );
                }
                // Tri par client, puis nom
                if (this.settings.sortBy == 'customer') {
                    items.sort( (c1, c2) => {
                        const cmpByCustomerName = strcmp(c1.customer.name, c2.customer.name);
                        return cmpByCustomerName != 0 ? cmpByCustomerName : strcmp(c1.nameForSorting, c2.nameForSorting);
                    });
                }
                // Tri par société, puis nom
                if (this.settings.sortBy == 'company') {
                    items.sort( (c1, c2) => {
                        const cmpByCompany = strcmp(c1.company, c2.company);
                        return cmpByCompany != 0 ? cmpByCompany : strcmp(c1.nameForSorting, c2.nameForSorting);
                    });
                }

                return items;
            }
        },

        methods: {
            ...mapMutations('confirm', ['confirm']),
            ...mapActions('pages', ['addPageCustomers']),

            openCustomer (customer) {
                this.addPageCustomers({
                    filters: {
                        statuses: [customer.status],
                        name: customer.name
                    }
                });
            },

            copy (contact) {
                const newContact = {
                    customerId: contact.customerId,
                    company: contact.company,
                    address1: contact.address1,
                    address2: contact.address2,
                    address3: contact.address3,
                    city: contact.city
                };

                // Copie des tel fixes uniquement
                if (decodeTel(contact.tel1).prettyType == 'FIXE') {
                    newContact.tel1 = contact.tel1;
                }
                if (decodeTel(contact.tel2).prettyType == 'FIXE') {
                    newContact.tel2 = contact.tel2;
                }
                if (decodeTel(contact.tel3).prettyType == 'FIXE') {
                    newContact.tel3 = contact.tel3;
                }

                this.confirm({
                    question: "Créer un nouveau contact<br/>avec les mêmes coordonnées ?",
                    btnYesLabel: "OUI, dupliquer",
                    btnYesClasses: 'icon copy green',
                    btnYesCallback: () => {
                        this.$refs.contact_popup.addOrEditContact(0, { ...newContact });
                    }
                });
            },

            // Extraction Excel
            generateCSV () {
                let columns = [
                    {label:'N°', itemKey:'id'},
                    {label:'Statut client', itemKey:'statusTxt'},
                    {label:'Atelier client', itemKey:'workshopTxt'},
                    {label:'Nom du client', itemKey:'customerName'},
                    {label:'Société / Asso.', itemKey:'company'},
                    {label:'Civilité', itemKey:'civility'},
                    {label:'Nom', itemKey:'lastname'},
                    {label:'Prénom', itemKey:'firstname'},
                    {label:'Titre', itemKey:'title'},
                    {label:'Fonction', itemKey:'job'},
                    {label:'Adresse 1', itemKey:'address1'},
                    {label:'Adresse 2', itemKey:'address2'},
                    {label:'Adresse 3', itemKey:'address3'},
                    {label:'CP - Ville', itemKey:'city'},
                    {label:'E-mail', itemKey:'email'},
                    {label:'Tél. 1', itemKey:'tel1Txt'},
                    {label:'Tél. 2', itemKey:'tel2Txt'},
                    {label:'Tél. 3', itemKey:'tel3Txt'},
                    {label:'Commentaires', itemKey:'comments'},
                    {label:'Trokool', itemKey:'trokoolTxt'},
                ];

                const items = [ ...this.realCheckedItems ].map( item => {
                    item.statusTxt = item.customerId > 0 ? this.customerStatusTxtById[ item.customer.status ] : '';
                    item.workshopTxt = item.customerId > 0 ? this.workshopNameById[ item.customer.workshopId ] : '';
                    item.customerName = item.customerId > 0 ? item.customer.name : '';
                    item.tel1Txt = decodeTel(item.tel1).prettyTel;
                    item.tel2Txt = decodeTel(item.tel2).prettyTel;
                    item.tel3Txt = decodeTel(item.tel3).prettyTel;
                    item.trokoolTxt = item.displayInTrokool == 1 ? 'X' : '';
                    return item;
                });

                generateCSV(this.$store, columns, items, 'CRM_contacts');
            }
        },

        watch: {
            highlightedId (newHighlightedId) {
                scrollToDOMelement(this.$el, 'tr.highlight');
            }
        }
    };
</script>
