<template>
    <div class="etab-typologies">
        <div v-for="typologie in typologiesOptions"
             :class="{selected: selectedTypologies.includes(typologie.value) }"
             :title="typologie.label">
            {{ typologie.value }}
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: 'EtabTypologies',
    props: {
        selectedTypologies: {default: () => []}
    },
    computed: {
        ...mapState('settings', ['typologiesOptions'])
    }
};
</script>
