import {callAPI} from '@/helpers.js';


const emptySession = {
    isLogged: false,
    userId: 0,
    userName: null
};


export default {

    namespaced: true,

    state: {
        // Session utilisateur : connecté ? Nom de l'utilisateur ?
        session: {...emptySession},
    },

    mutations: {
        // Mise à jour de la session utilisateur
        set (state, newSession) {
            state.session = { ...newSession };
        }
    },

    actions: {
        // Vérification d'un login/password et mise à jour automatique de la session
        // Retourne une promesse pour traiter les éventuelles erreurs avec catch
        login: (context, loginAndPassword) => new Promise((resolve, reject) => {
            callAPI(context, 'Main/login', loginAndPassword)
            .then(response => {
                response.status == true ? resolve() : reject(response.error);
            });
        }),

        // Déconnecte l'utilisateur courant
        logout (context) {
            callAPI(context, 'Main/logout');
        },

        // Vérifie et met à jour la session utilisateur, en arrière-plan (pas d'overlay)
        refresh (context) {
            callAPI(context, 'Main/checkSession', {}, {noOverlay: true});
        }
    }
};
