import Vue from 'vue';
import Vuex from 'vuex';
import settings from './settings.store.js';
import users from './users.store.js';
import attachments from './attachments.store.js';
import customers from './customers.store.js';
import contacts from './contacts.store.js';
import etabs from './etabs.store.js';
import events from './events.store.js';
import tasks from './tasks.store.js';
import session from './session.store.js';
import overlay from './overlay.store.js';
import confirm from './confirm.store.js';
import alert from './alert.store.js';
import prompt from './prompt.store.js';
import pages from './pages.store.js';

Vue.use(Vuex);

export default new Vuex.Store({

    state: {
        isLocal: (
			location.host.substr(0, 9) === 'localhost' ||
			location.host == 'leztroycrm.local'
		)
    },

    getters: {
        apiBaseUrl (state) {
            return state.isLocal ? 'https://leztroycrm.local/' : 'https://crm.leztroy.fr/';
        }
    },

    modules: {
        settings,
        users,
        attachments,
        customers,
        contacts,
        etabs,
        events,
        tasks,
        session,
        overlay,
        confirm,
        alert,
        prompt,
        pages
    }
});
