<template>
    <div class="page search-page">

        <search-toolbar
            :value="query"
            @input="(newQuery) => {setQuery(newQuery)}"
            />

        <div class="page-body">

            <div class="page-overflow-wrapper">

                <div class="d-table fixed break-1140">

                    <div :class="{'no-results': filteredCustomers.length === 0}">
                        <h3>
                            <img src="@/assets/img/menu-icon-customers.svg" alt="" width="40" height="40" />
                            <div class="title">
                                Clients & Prospects
                                <span class="counter">
                                    {{ getCounter(filteredCustomers) }}
                                </span>
                            </div>
                        </h3>
                        <ul class="cards" v-if="filteredCustomers.length">
                            <li v-for="customer in filteredCustomers">
                                <div class="actions-spacer">
                                </div>
                                <div class="actions">
                                    <div @click="openCustomer(customer)">
                                        <img src="@/assets/img/icon-external-link.svg" alt="" width="17" height="17" />
                                    </div>
                                    <div @click="editCustomer(customer.id)">
                                        <img src="@/assets/img/icon-edit.svg" alt="" width="17" height="17" />
                                    </div>
                                </div>
                                <div>
                                    <strong>
                                        {{ customer.name }}
                                    </strong>
                                </div>
                                <div>
                                    {{ customerStatusTxtById[ customer.status ] + ' / ' +  workshopNameById[ customer.workshopId ] }}
                                </div>
                                <div>
                                    {{ customer.city }}
                                </div>
                                <customer-details :customer="customer" />
                            </li>
                        </ul>
                        <div class="new-item">
                            <button class="form-button small green" @click="editCustomer(-1)">
                                Nouveau client / prospect
                            </button>
                        </div>
                    </div>

                    <div class="mini-gutter"></div>

                    <div :class="{'no-results': filteredContacts.length === 0}">
                        <h3>
                            <img src="@/assets/img/menu-icon-contacts.svg" alt="" width="40" height="40" />
                            <div class="title">
                                Contacts
                                <span class="counter">
                                    {{ getCounter(filteredContacts) }}
                                </span>
                            </div>
                        </h3>
                        <ul class="cards" v-if="filteredContacts.length">
                            <li class="contact-card" v-for="contact in filteredContacts">
                                <div class="actions-spacer">
                                </div>
                                <div class="actions">
                                    <div @click="openContact(contact)">
                                        <img src="@/assets/img/icon-external-link.svg" alt="" width="17" height="17" />
                                    </div>
                                    <div @click="editContact(contact.id)">
                                        <img src="@/assets/img/icon-edit.svg" alt="" width="17" height="17" />
                                    </div>
                                </div>
                                <div v-if="contact.company">
                                    <strong>
                                        {{ contact.company }}
                                    </strong>
                                </div>
                                <div v-html="contact.nameHtml" v-if="contact.nameHtml">
                                </div>
                                <div v-text="contact.job" v-if="contact.job">
                                </div>
                                <div v-text="contact.city" v-if="contact.city">
                                </div>
                                <div v-if="contact.customerId > 0">
                                    {{ customerStatusTxtById[ customerById[ contact.customerId ].status ] + ' : ' + customerById[ contact.customerId ].name }}
                                </div>
                                <div>
                                    <contact-details :contact="contact" />
                                </div>
                            </li>
                        </ul>
                        <div class="new-item">
                            <button class="form-button small green" @click="editContact(-1)">
                                Nouveau contact
                            </button>
                        </div>
                    </div>

                    <div class="mini-gutter"></div>

                    <div :class="{'no-results': filteredEtabs.length === 0}">
                        <h3>
                            <img src="@/assets/img/menu-icon-etabs.svg" alt="" width="40" height="40" />
                            <div class="title">
                                Points de livraison
                                <span class="counter">
                                    {{ getCounter(filteredEtabs) }}
                                </span>
                            </div>
                        </h3>
                        <ul class="cards" v-if="filteredEtabs.length">
                            <li v-for="etab in filteredEtabs">
                                <div class="actions-spacer">
                                </div>
                                <div class="actions">
                                    <div @click="openEtab(etab)">
                                        <img src="@/assets/img/icon-external-link.svg" alt="" width="17" height="17" />
                                    </div>
                                    <div @click="editEtab(etab.id)">
                                        <img src="@/assets/img/icon-edit.svg" alt="" width="17" height="17" />
                                    </div>
                                </div>
                                <div>
                                    <strong>
                                        {{ etab.name }}
                                    </strong>
                                </div>
                                <div>
                                    {{ etab.city }}
                                </div>
                                <div>
                                    {{ customerStatusTxtById[ customerById[ etab.customerId ].status ] + ' : ' + customerById[ etab.customerId ].name }}
                                </div>
                                <etab-typologies :selectedTypologies="etab.typologies" />
                            </li>
                        </ul>
                        <div class="new-item">
                            <button class="form-button small green" @click="editEtab(-1)">
                                Nouveau point de livraison
                            </button>
                        </div>
                    </div>

                    <div class="mini-gutter"></div>

                    <div :class="{'no-results': filteredEvents.length === 0}">
                        <h3>
                            <img src="@/assets/img/menu-icon-events.svg" alt="" width="40" height="40" />
                            <div class="title">
                                Évènements
                                <span class="counter">
                                    {{ getCounter(filteredEvents) }}
                                </span>
                            </div>
                        </h3>
                        <ul class="cards" v-if="filteredEvents.length">
                            <li v-for="event in filteredEvents">
                                <div class="actions-spacer">
                                </div>
                                <div class="actions">
                                    <div @click="openEvent(event)">
                                        <img src="@/assets/img/icon-external-link.svg" alt="" width="17" height="17" />
                                    </div>
                                    <div @click="editEvent(event.id)">
                                        <img src="@/assets/img/icon-edit.svg" alt="" width="17" height="17" />
                                    </div>
                                </div>
                                <div>
                                    <strong>
                                        {{ event.type }}
                                    </strong>
                                </div>
                                <div class="date-line">
                                    <strong>
                                        {{ event.date | formatDate('FULL') }}
                                    </strong>
                                </div>
                                <div>
                                    {{ customerStatusTxtById[ customerById[ event.customerId ].status ] + ' : ' + customerById[ event.customerId ].name }}
                                </div>
                                <div class="description">
                                    {{ event.description }}
                                </div>
                                <div class="list-of-details" v-if="event.attachmentsIds.length > 0">
                                    <tip-icon :active="true"
                                              :counter="event.attachmentsIds.length"
                                              @click="openEvent(event)"
                                              @clickIfNotActive="openEvent(event)"
                                              >
                                        <template v-slot:icon>
                                            <img src="@/assets/img/menu-icon-attachment.svg" alt="" width="18" height="18" />
                                        </template>
                                        <template v-slot:tip>
                                            <div v-for="attachmentId of event.attachmentsIds">
                                                <div class="no-wrap">
                                                    {{ attachmentsById[attachmentId].ext }} : {{ attachmentsById[attachmentId].name }}
                                                </div>
                                            </div>
                                        </template>
                                    </tip-icon>
                                </div>
                            </li>
                        </ul>
                        <div class="new-item">
                            <button class="form-button small green" @click="editEvent(-1)">
                                Nouvel évènement
                            </button>
                        </div>
                    </div>

                    <div class="mini-gutter"></div>

                    <div :class="{'no-results': filteredTasks.length === 0}">
                        <h3>
                            <img src="@/assets/img/menu-icon-tasks.svg" alt="" width="40" height="40" />
                            <div class="title">
                                Tâches
                                <span class="counter">
                                    {{ getCounter(filteredTasks) }}
                                </span>
                            </div>
                        </h3>
                        <ul class="cards" v-if="filteredTasks.length">
                            <li v-for="task in filteredTasks">
                                <div class="actions-spacer">
                                </div>
                                <div class="actions">
                                    <div @click="openTask(task)">
                                        <img src="@/assets/img/icon-external-link.svg" alt="" width="17" height="17" />
                                    </div>
                                    <div @click="editTask(task.id)">
                                        <img src="@/assets/img/icon-edit.svg" alt="" width="17" height="17" />
                                    </div>
                                </div>
                                <div>
                                    <strong>
                                        {{ task.type }}
                                    </strong>
                                </div>
                                <div class="date-line">
                                    <strong>
                                        {{ task.date | formatDate('FULL') }}
                                    </strong>
                                    <span class="days-before" :class="{emergency:task.date <= warningDate}">
                                        {{ task.date | daysBefore }}
                                    </span>
                                </div>
                                <div v-if="task.customerId > 0">
                                    {{ customerStatusTxtById[ customerById[ task.customerId ].status ] + ' : ' + customerById[ task.customerId ].name }}
                                </div>
                                <div class="description">
                                    {{ task.description }}
                                </div>
                            </li>
                        </ul>
                        <div class="new-item">
                            <button class="form-button small green" @click="editTask(-1)">
                                Nouvelle tâche
                            </button>
                        </div>
                    </div>

                    <div class="mini-gutter"></div>

                    <div :class="{'no-results': filteredEmails.length === 0}">
                        <h3>
                            <img src="@/assets/img/menu-icon-mails.svg" alt="" width="40" height="40" />
                            <div class="title">
                                Emails
                                <span class="counter">
                                    {{ getCounter(filteredEmails) }}
                                </span>
                            </div>
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <customer-popup ref="customer_popup" />
        <contact-popup ref="contact_popup" />
        <etab-popup ref="etab_popup" />
        <event-popup ref="event_popup" />
        <task-popup ref="task_popup" />
    </div>
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
    import {isMatchingQuery, strcmp, formatDate, daysBefore} from '@/helpers.js';
    import {customerToEtabPageFilters, customerToContactPageFilters, customerToTaskPageFilters, customerToEventPageFilters, getPrettyName, getPrettyNameHtml, getFullAddressHtml, decodeTel} from '@/helpersForCrm.js';
    import TipIcon from '@/components/Global/TipIcon.vue';
    import CustomerPopup from '@/components/Pages/Customers/CustomerPopup.vue';
    import ContactPopup from '@/components/Pages/Contacts/ContactPopup.vue';
    import EtabPopup from '@/components/Pages/Etabs/EtabPopup.vue';
    import EventPopup from '@/components/Pages/Events/EventPopup.vue';
    import TaskPopup from '@/components/Pages/Tasks/TaskPopup.vue';
    import SearchToolbar from '@/components/Pages/Search/SearchToolbar.vue';
    import ContactDetails from '@/components/Pages/Contacts/ContactDetails.vue';
    import CustomerDetails from '@/components/Pages/Customers/CustomerDetails.vue';
    import attachmentsMixin from '@/mixins/attachmentsMixin.js';
    import EtabTypologies from '@/components/Pages/Etabs/EtabTypologies.vue';
    export default {
        name: 'Search',
        components: {TipIcon, SearchToolbar, ContactDetails, CustomerDetails, CustomerPopup, ContactPopup, EtabPopup, EventPopup, TaskPopup, EtabTypologies},
        filters: {formatDate, daysBefore},
        mixins: [attachmentsMixin],

        props: ['pageIndex'],

        data () {
            return {
                maxItemsByColumn: 15
            };
        },

        computed: {
            ...mapState('pages', ['pages']),

            ...mapState('customers', ['customers']),
            ...mapState('etabs', ['etabs']),
            ...mapState('contacts', ['contacts']),
            ...mapState('events', ['events']),
            ...mapState('tasks', ['tasks']),

            ...mapGetters('customers', ['customerById']),
            ...mapGetters('settings', ['workshopNameById', 'customerStatusTxtById', 'warningDate']),
            ...mapGetters('attachments', ['attachmentsById']),

            // La requête brute
            query () {
                return this.pages[this.pageIndex].settings.query;
            },

            // Les mots clés significatifs issus de la requête brute
            keywords () {
                const keywords = [];
                this.query.split(' ').forEach((keyword) => {
                    keyword = keyword.trim();
                    if (keyword.length >= 2) {
                        keywords.push(
                            keyword.toLowerCase()
                        );
                    }
                });
                return keywords;
            },

            // Les valeurs numériques contenues dans la requête brute
            // Nécessaire à la recherche téléphonique
            mergedNumericQuery () {
                return this.query.replace(/\D/g, '');
            },

            // Recherche des clients / prospects
            filteredCustomers () {
                return this.filterItems({
                    items: this.customers,
                    mapItemFunction: item => {},
                    getFieldsToCheck: item => [item.name, item.comments],
                    getNumericFieldToCheck: item => false,
                    sortFunction: (c1, c2) => strcmp(c1.name, c2.name),
                    mapToResultsFunction: item => {
                        item.etabs = this.etabs.filter(e => e.customerId == item.id);
                        item.contacts = this.contacts.filter(c => c.customerId == item.id);
                        item.events = this.events.filter(e => e.customerId == item.id);
                        item.tasks = this.tasks.filter(t => t.customerId == item.id);
                    },
                    keywords: this.keywords,
                    mergedNumericQuery: this.mergedNumericQuery,
                    maxItemsByColumn: this.maxItemsByColumn
                });
            },

            // Recherche des contacts
            filteredContacts () {
                return this.filterItems({
                    items: this.contacts,
                    mapItemFunction: item => {
                        item.nameForFiltering = getPrettyName(item);
                        item.nameHtml = getPrettyNameHtml(item, 'div');
                        item.jobForFiltering = item.job.replace(/[,\/]/g, ' ');
                        item.addressForFiltering = [item.address1, item.address2, item.address3, item.city].join(' ');
                        item.phonesForFiltering = [item.tel1, item.tel2, item.tel3].map(t => Object.values(decodeTel(t)).join('|') ).join('|').replace(/\s/g, '');
                        item.fullAddress = getFullAddressHtml(item);
                    },
                    getFieldsToCheck: item => [item.nameForFiltering, item.company, item.jobForFiltering, item.email, item.addressForFiltering, item.comments],
                    getNumericFieldToCheck: item => item.phonesForFiltering,
                    sortFunction: (c1, c2) => strcmp(c1.company + c1.lastname + c1.firstname, c2.company + c2.lastname + c2.firstname),
                    mapToResultsFunction: false,
                    keywords: this.keywords,
                    mergedNumericQuery: this.mergedNumericQuery,
                    maxItemsByColumn: this.maxItemsByColumn
                });
            },

            // Recherche des points de livraisons
            filteredEtabs () {
                return this.filterItems({
                    items: this.etabs,
                    mapItemFunction: item => {},
                    getFieldsToCheck: item => [item.name, item.address, item.city],
                    getNumericFieldToCheck: item => false,
                    sortFunction: (c1, c2) => strcmp(c1.name, c2.name),
                    mapToResultsFunction: false,
                    keywords: this.keywords,
                    mergedNumericQuery: this.mergedNumericQuery,
                    maxItemsByColumn: this.maxItemsByColumn
                });
            },

            // Recherche des events
            filteredEvents () {
                return this.filterItems({
                    items: this.events,
                    mapItemFunction: item => {
                        item.attachments = this.hydrateAttachments(item.attachmentsIds, this.attachmentsById);
                        item.attachmentsNames = item.attachments.map(a => a.name).join(' ');
                    },
                    getFieldsToCheck: item => [item.type, item.description, item.attachmentsNames],
                    getNumericFieldToCheck: item => false,
                    sortFunction: (c1, c2) => -strcmp(c1.date, c2.date),
                    mapToResultsFunction: false,
                    keywords: this.keywords,
                    mergedNumericQuery: this.mergedNumericQuery,
                    maxItemsByColumn: this.maxItemsByColumn
                });
            },

            // Recherche des tâches
            filteredTasks () {
                return this.filterItems({
                    items: this.tasks,
                    mapItemFunction: item => {},
                    getFieldsToCheck: item => [item.type, item.description],
                    getNumericFieldToCheck: item => false,
                    sortFunction: (c1, c2) => strcmp(c1.date, c2.date),
                    mapToResultsFunction: false,
                    keywords: this.keywords,
                    mergedNumericQuery: this.mergedNumericQuery,
                    maxItemsByColumn: this.maxItemsByColumn
                });
            },

            // Recherche des emails
            filteredEmails () {
                return [];
            }
        },

        methods: {
            ...mapMutations('pages', ['updatePageSetting']),
            ...mapActions('pages', ['addPageCustomers', 'addPageContacts', 'addPageEtabs', 'addPageEvents', 'addPageTasks']),

            setQuery (newQuery) {
                this.updatePageSetting({
                    pageIndex:this.pageIndex,
                    key:'query',
                    value:newQuery
                });
            },

            getCounter (items) {
                return ' : ' + (items.length <= this.maxItemsByColumn ? items.length : this.maxItemsByColumn + '+');
            },

            openCustomer (customer) {
                this.addPageCustomers({
                    filters: {
                        statuses: [customer.status],
                        name: customer.name
                    }
                });
            },

            editCustomer (customerId) {
                this.$refs.customer_popup.addOrEditCustomer(customerId);
            },

            // Ouvre tous les étabs du client
            openEtab (etab) {
                const customer = this.customerById[ etab.customerId ];
                this.addPageEtabs({
                    filters: customerToEtabPageFilters(customer)
                });
            },

            editEtab (etabId) {
                this.$refs.etab_popup.addOrEditEtab(etabId);
            },

            // Ouvre tous les contacts du client, ou le contact seul si pas de client
            openContact (contact) {
                let filters = {};

                // S'il est lié à un client...
                if (contact.customerId > 0) {
                    const customer = this.customerById[ contact.customerId ];
                    filters = customerToContactPageFilters(customer);
                }
                // Si pas lié à un client
                else {
                    filters.statuses = [];
                    if (contact.company) {
                        filters.company = contact.company;
                    }
                    if (contact.firstname || contact.lastname) {
                        filters.name = (contact.firstname + ' ' + contact.lastname).trim();
                    }
                }
                this.addPageContacts({filters});
            },

            editContact (contactId) {
                this.$refs.contact_popup.addOrEditContact(contactId);
            },

            openTask (task) {
                let filters = {};

                // Si elle est liée à un client...
                if (task.customerId > 0) {
                    const customer = this.customerById[ task.customerId ];
                    filters = customerToTaskPageFilters(customer);
                }
                // Si pas liée à un client
                else {
                    filters.statuses = [];
                }
                filters.users = [ ...task.usersIds ];
                filters.date = task.date.split('-').slice(0,2).reverse().join('/');
                this.addPageTasks({filters});
            },

            editTask (taskId) {
                this.$refs.task_popup.addOrEditTask(taskId);
            },

            openEvent (event) {
                const customer = this.customerById[ event.customerId ];
                let filters = customerToEventPageFilters(customer);
                filters.date = event.date.split('-').slice(0,2).reverse().join('/');
                this.addPageEvents({filters});
            },

            editEvent (eventId) {
                this.$refs.event_popup.addOrEditEvent(eventId);
            },

            // Méthode de filtrage générique des données
            filterItems (args) {
                const filteredItems = [];
                let limit = args.maxItemsByColumn + 1;
                for (let loopItem of args.items) {
                    let item = { ...loopItem };

                    args.mapItemFunction(item);
                    const fieldsToCheck = args.getFieldsToCheck(item);
                    const numericFieldToCheck = args.getNumericFieldToCheck(item);

                    let matching = false;
                    if ( !matching && fieldsToCheck.length) {
                        matching = isMatchingQuery(fieldsToCheck, args.keywords);
                    }
                    if ( !matching && args.mergedNumericQuery.length > 0 && numericFieldToCheck !== false) {
                        matching = (numericFieldToCheck.indexOf(args.mergedNumericQuery) >= 0);
                    }

                    if (matching) {
                        if (args.mapToResultsFunction !== false) {
                            args.mapToResultsFunction(item);
                        }
                        filteredItems.push(item);
                        limit--;
                        if (limit === 0) {
                            break;
                        }
                    }
                }
                filteredItems.sort(args.sortFunction);
                return filteredItems;
            }
        }
    };
</script>
