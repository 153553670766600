<template>
    <div class="upload-area" @keyup.esc.stop="$emit('cancel')">

        <form enctype="multipart/form-data" novalidate
             class="dropbox-area"
             :class="{dragging}"
             @dragover="dragging=true"
             @dragleave="dragging=false"
             >

            <input ref="filefield" type="file" @change="drop($event.target)" accept="" title="" multiple />

            <div class="inner-area d-flex flex-column justify-content-center align-items-center">

                <p v-html="message">
                </p>

                <div class="error" v-if="error">
                    {{ error }}
                </div>
            </div>

            <button class="form-button icon red cancel" @click.prevent.stop="$emit('cancel')">
                Annuler
            </button>
        </form>

    </div>
</template>

<script>
    export default {
        name: 'UploadArea',

        props: ['error', 'message'],

        data () {
            return {
                dragging: false
            }
        },

        methods: {

            // Vide le fichier sélectionné dans le champ INPUT FILE
            resetField () {
                this.$refs.filefield.value = '';
            },

            // Unou plusieurs fichiers sont déposés dans la zone ou sélectionnés
            drop (target) {

                this.dragging = false;

                if ( !target.files) {
                    return;
                }

                this.$emit('filesSelected', target.files);
            }
        }
    };
</script>
