<template>
    <div class="search-toolbar page-toolbar">

        <div>
            <form-input-field
                :value="value"
                placeholder="Mots-clés..."
                :hasReset="true"
                @input="(newValue) => updateQuery(newValue)"
                class="full-toolbar-height"
                ref="search_field"
                />
        </div>

    </div>
</template>

<script>
    import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
    export default {
        name: 'SearchToolbar',
        components: {FormInputField},
        props:['value'],

        mounted () {
            this.$refs.search_field.focus();
        },

        methods: {
            updateQuery (newValue) {
                this.$emit('input', newValue);
            }
        }
    };
</script>
