<template>
    <form class="d-flex justify-content-center align-items-center" id="login" @submit.prevent="submitForm">

        <div class="inner">

            <div class="logo">
                <img src="@/assets/img/leztroy-crm-logo.png" alt="" width="320" height="140" />
            </div>

            <div class="fields">
                <form-input-field v-model="fields.login" placeholder="Nom d'utilisateur" class="icon login" />
                <form-input-field v-model="fields.password" type="password" placeholder="Mot de passe" class="icon password" />
            </div>

            <transition>
                <div class="error" v-if="error.length > 0">
                    {{ error }}
                </div>
            </transition>

            <button>
                Connexion
            </button>
        </div>
    </form>
</template>

<script>
    import {mapActions} from 'vuex';
    import FormInputField from '@/components/Lib/Forms/FormInputField.vue';
    export default {
        name: 'Login',
        components: {FormInputField},

        data () {
            return {
                fields: {login: '', password: ''},
                error: ''
            };
        },

        methods: {
            ...mapActions('session', ['login']),

            submitForm () {
                this.error = '';
                this.login({...this.fields}).catch(error => {
                    this.error = error;
                });
            }
        }
    };
</script>
